import React, { useState, useMemo, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Typography } from '@material-ui/core';
import styled from '@emotion/styled';

import useYearDocuments from 'utils/useYearDocuments';
import { Period } from '@agoy/api-sdk-core';
import { MAX_NUM_DOCUMENTS } from 'contants';
import {
  PreviewModal,
  DocumentUpload,
} from '_shared/components/UploadDocument';
import LoadingPlaceholder from '_shared/components/LoadingPlaceholder';
import DocumentPreviewItemYear from './DocumentPreviewItemYear';

const Wrapper = styled.div`
  display: flex;
  flex-flow: row;
`;

const LoadingWrapper = styled.div`
  margin: 70px auto;
`;

const UploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

interface DocumentClippyProps {
  period: Period;
  category?: string;
}

const DocumentClippyYear = ({
  period,
  category,
}: DocumentClippyProps): JSX.Element => {
  const { formatMessage } = useIntl();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [displayedDocumentId, setDisplayedDocumentId] = useState<number | null>(
    null
  );

  const { documents, loading, uploading, uploadFile, refetchDocuments } =
    useYearDocuments(category);

  useEffect(() => {
    refetchDocuments();
  }, [period]);

  const handleSelectDocument = async (file: File) => {
    await uploadFile(file, category);
  };

  const handleOpenPreviewModal = (index) => {
    setIsModalOpen(true);
    setDisplayedDocumentId(index);
  };

  const handleClosePreviewModal = () => {
    setIsModalOpen(false);
    setDisplayedDocumentId(null);
  };

  const sortedFiles = useMemo(() => {
    return [...(documents?.listDocuments || [])].sort((a, b) =>
      a.lastModified.localeCompare(b.lastModified)
    );
  }, [documents]);

  return (
    <Wrapper>
      {loading ? (
        <LoadingWrapper>
          <LoadingPlaceholder />
        </LoadingWrapper>
      ) : (
        <>
          {sortedFiles.length < MAX_NUM_DOCUMENTS && (
            <UploadContainer>
              <DocumentUpload
                message={formatMessage({ id: 'records.uploadNewRecord' })}
                isUploading={uploading}
                onUpload={handleSelectDocument}
              />
            </UploadContainer>
          )}

          {sortedFiles.map((document, index) => (
            <DocumentPreviewItemYear
              id={index}
              period={period}
              document={document}
              onOpenPreviewModal={handleOpenPreviewModal}
              key={document.name}
              onDeleteComplete={refetchDocuments}
            />
          ))}
        </>
      )}

      {displayedDocumentId !== null && (
        <PreviewModal
          documentSrc={sortedFiles[displayedDocumentId].url || ''}
          documentName={sortedFiles[displayedDocumentId].name || ''}
          documentId={sortedFiles[displayedDocumentId].name || ''}
          information={
            <>
              <Typography variant="body1" color="textSecondary">
                {formatMessage({ id: 'base' })}
              </Typography>
              <Typography variant="body1">
                {`${displayedDocumentId + 1}/${sortedFiles.length}`}
              </Typography>
            </>
          }
          isOpen={isModalOpen && !!sortedFiles[displayedDocumentId]}
          onStepBackwards={() =>
            displayedDocumentId - 1 >= 0
              ? setDisplayedDocumentId(displayedDocumentId - 1)
              : setDisplayedDocumentId(sortedFiles.length - 1)
          }
          onStepForward={() =>
            displayedDocumentId + 1 < sortedFiles.length
              ? setDisplayedDocumentId(displayedDocumentId + 1)
              : setDisplayedDocumentId(0)
          }
          onClose={handleClosePreviewModal}
          refreshDownloadUrl={refetchDocuments}
        />
      )}
    </Wrapper>
  );
};

export default DocumentClippyYear;
