import React, { useState, useEffect, memo } from 'react';
import styled from '@emotion/styled';
import { Print as PrintIcon, Close as CloseIcon } from '@material-ui/icons';
import {
  Dialog as MuiDialog,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Paper,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { useIntl } from 'react-intl';
import isPropValid from '@emotion/is-prop-valid';
import DisplayImage from './DisplayImage';
import LoadingLogo from '../LoadingLogo';

const isImageFile = (fileContent: string): boolean => {
  return fileContent.startsWith('data:image');
};

const isPdfFile = (fileContent: string): boolean => {
  return fileContent.startsWith('data:application/pdf');
};

const PrintWrapper = styled.section<{ orientation?: string }>`
  @media print {
    @page {
      size: ${(props) => props.orientation || 'portrait'};
    }
  }
`;

const InformationContainer = styled.div`
  @media print {
    display: none;
  }
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  column-gap: ${(props) => props.theme.spacing(2)}px;
`;

const Column = styled.div`
  width: 25%;
  height: 100%;
`;

const LeftColumn = styled(Column)`
  @media print {
    display: none;
  }
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: ${(props) => props.theme.spacing(4)}px;
`;

const MiddleColumn = styled(Column)`
  display: flex;
  flex-direction: column;
  width: max-content;
`;

const RightColumn = styled(Column)`
  @media print {
    display: none;
  }
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: ${(props) => props.theme.spacing(4)}px;
`;

const StepRecordArrow = styled(IconButton)`
  @media print {
    display: none;
  }
  background: ${(props) => props.theme.palette.common.white};
  font-size: 2.5rem;
  :hover {
    background: ${(props) => props.theme.palette.common.white};
    color: ${(props) => props.theme.palette.secondary.main};
  }
`;

const CloseModal = styled.div`
  @media print {
    display: none;
  }
`;

const Dialog = styled(MuiDialog)`
  .MuiDialog-paper {
    background-color: rgba(0, 0, 0, 0);
    box-shadow: none;
    margin: 0;
  }
  .MuiPaper-root {
    border: none;
  }
  @media print {
    display: block;
    position: relative;
    .MuiDialog-paper {
      display: block;
      position: relative;
      margin: 0;
      border: none;
      border-radius: 0;
      width: auto;
      height: 100%;
    }
    .MuiDialog-scrollBody {
      display: block;
      position: relative;
      width: max-content;
      height: initial !important;
      overflow: initial !important;
    }
  }
  backdrop-filter: blur(5px);
`;

const DialogActionsBar = styled(DialogActions)`
  padding-right: 0;
  padding-left: 0;
`;

const PrintButton = styled(IconButton)`
  @media print {
    display: none;
  }
  margin-right: ${(props) => props.theme.spacing(1)}px;
`;

const Print = styled(PrintIcon, { shouldForwardProp: isPropValid })<{
  disabled?: boolean;
}>`
  color: ${(props) =>
    props.disabled
      ? props.theme.palette.grey[500]
      : props.theme.palette.common.white};
`;

const LoadingContainer = styled.div`
  @media print {
    display: none;
  }
  display: flex;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  max-width: max-content;
  padding: 32px;
  background-color: white;
  //!: need to test that all prints in other places work well with removing this field;
  /* @media print {
    page-break-inside: avoid;
  } */
`;

const Close = styled(CloseIcon)`
  color: ${(props) => props.theme.palette.common.white};
  margin-left: ${(props) => props.theme.spacing(1)}px;
`;

const CloseTitle = styled(Typography)`
  font-weight: bold;
  color: ${(props) => props.theme.palette.common.white};
`;

const DialogContentWrapper = styled(DialogContent)`
  display: flex;
  align-items: center;
`;

type CommonPreviewModalProps = {
  isOpen: boolean;
  fileContent?: string;
  handleShow: (value: boolean) => void;
  children?: React.ReactNode;
  onStepBackwards?: () => void;
  onStepForward?: () => void;
  onPrint: () => void;
  loading?: boolean;
  printOrientation?: 'landscape' | 'portrait';
};

const CommonPreviewModal = ({
  isOpen,
  fileContent,
  handleShow,
  children,
  onStepBackwards,
  onStepForward,
  onPrint,
  loading,
  printOrientation,
}: CommonPreviewModalProps): JSX.Element | null => {
  const { formatMessage } = useIntl();

  const [fileSrc, setFileSrc] = useState('');
  const [isImage, setIsImage] = useState(false);
  const [isPdf, setIsPdf] = useState(false);
  const [orientation, setOrientation] = useState<
    'landscape' | 'portrait' | undefined
  >();

  useEffect(() => {
    setOrientation(printOrientation);

    return () => setOrientation(undefined);
  }, [printOrientation]);

  useEffect(() => {
    if (fileContent) {
      const getFile = async () => {
        try {
          const res = await fetch(fileContent);
          if (res.ok) {
            const blob = await res.blob();
            const url = window.URL.createObjectURL(blob);
            setFileSrc(url);
          }
        } catch (e) {}
      };

      setIsImage(isImageFile(fileContent));
      setIsPdf(isPdfFile(fileContent));
      getFile();
    }
  }, [fileContent]);

  if (!isOpen) {
    return null;
  }

  return (
    <Dialog
      open={isOpen}
      onClose={() => handleShow(false)}
      maxWidth="lg"
      scroll="body"
    >
      <DialogActions>
        <InformationContainer>
          <CloseModal>
            <IconButton disableRipple onClick={() => handleShow(false)}>
              <CloseTitle>
                {formatMessage({
                  id: 'CLOSE',
                })}
              </CloseTitle>
              <Close />
            </IconButton>
          </CloseModal>
        </InformationContainer>
      </DialogActions>
      <DialogContentWrapper>
        {onStepBackwards && (
          <LeftColumn>
            <StepRecordArrow disableRipple onClick={onStepBackwards}>
              <ArrowBackIcon fontSize="large" />
            </StepRecordArrow>
          </LeftColumn>
        )}
        <MiddleColumn>
          <DialogActionsBar>
            <LoadingContainer>
              <PrintButton
                aria-label="print"
                onClick={onPrint}
                disabled={loading}
              >
                {loading && <LoadingLogo size="tiny" onDark />}
                <Print disabled={loading} />
              </PrintButton>
            </LoadingContainer>
          </DialogActionsBar>
          <Content>
            <PrintWrapper orientation={orientation}>{children}</PrintWrapper>
            {fileSrc && (
              <Paper elevation={2}>
                {isImage && <DisplayImage src={fileSrc} />}
                {isPdf && (
                  <iframe
                    title="pdf"
                    style={{ height: '80vh', width: '100%' }}
                    src={fileSrc}
                  />
                )}
              </Paper>
            )}
          </Content>
        </MiddleColumn>
        {onStepForward && (
          <RightColumn>
            <StepRecordArrow onClick={onStepForward}>
              <ArrowForwardIcon fontSize="large" />
            </StepRecordArrow>
          </RightColumn>
        )}
      </DialogContentWrapper>
    </Dialog>
  );
};

export default memo(CommonPreviewModal);
