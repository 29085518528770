import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import shouldForwardProp from '@emotion/is-prop-valid';
import { getClasses } from '@agoy/common';
// @ts-ignore
import { ReactComponent as Check } from 'assets/check.svg';
import Typography from '../Typography/Typography';
import When from '../When/When';
import { Variant } from '../Typography/Typography.types';

const Container = styled('div', { shouldForwardProp })<{ gap: 1 | 2 }>`
  display: inline-flex;
  align-items: center;
  column-gap: ${({ theme, gap }) => theme.spacing(gap)}px;

  label:not(.disabled) {
    cursor: pointer;
  }
`;

const baseStyles = css`
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0; // prevent shrink of checkbox if label is too long

  input {
    position: absolute;
    border: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    cursor: inherit;
    opacity: 0;
    z-index: 1;
  }

  svg {
    fill: #fff;
    opacity: 0;
  }
`;

const CheckIcon = styled(Check)`
  &.small {
    width: ${({ theme }) => theme.spacing(1.5)}px;
    height: ${({ theme }) => theme.spacing(1.5)}px;
  }
`;

const DefaultControl = styled.span`
  ${baseStyles}

  &:focus-within {
    box-shadow: ${({ theme }) => theme.palette.focusBorder};
  }

  border: 1px solid ${({ theme }) => theme.palette.border.dark};

  &.medium {
    width: ${({ theme }) => theme.spacing(3)}px;
    height: ${({ theme }) => theme.spacing(3)}px;
    border-radius: ${({ theme }) => theme.spacing(0.5)}px;
  }

  &.small {
    width: ${({ theme }) => theme.spacing(2)}px;
    height: ${({ theme }) => theme.spacing(2)}px;
    border-radius: ${({ theme }) => theme.spacing(0.25)}px;
  }

  :not(.checked):hover {
    border-color: ${({ theme }) => theme.palette.border.darkGreen};
  }

  &.checked {
    background-color: ${({ theme }) => theme.palette.button.primary.main};
    border-color: ${({ theme }) => theme.palette.button.primary.main};
    transition: background-color 300ms cubic-bezier(0.2, 0, 0, 1);

    svg {
      opacity: 1;
      transition: opacity 300ms cubic-bezier(0.2, 0, 0, 1);
    }

    :hover {
      background-color: ${({ theme }) => theme.palette.button.primary.hover};
    }
  }

  &.disabled {
    border-color: ${({ theme }) => theme.palette.button.disabled};

    &.checked {
      background-color: ${({ theme }) => theme.palette.button.disabled};
    }
  }
`;

const TaxControl = styled.span`
  ${baseStyles}

  &:focus-within {
    box-shadow: ${({ theme }) => theme.palette.focusBorder};
  }

  width: 18px;
  height: 18px;
  border: 1px solid #000;
  background-color: #f2f4ff;

  svg {
    width: 13px;
    height: 13px;
    margin-top: 1px;
  }

  :not(.checked):hover {
    border-color: ${({ theme }) => theme.palette.border.darkGreen};
  }

  :hover {
    background-color: color-mix(in srgb, #000 5%, #f2f4ff 100%);
  }

  &.checked {
    transition: background-color 300ms cubic-bezier(0.2, 0, 0, 1);

    svg {
      opacity: 1;
      fill: #000;
      transition: opacity 300ms cubic-bezier(0.2, 0, 0, 1);
    }
  }

  &.disabled {
    border-color: ${({ theme }) => theme.palette.button.disabled};

    &.checked {
      background-color: ${({ theme }) => theme.palette.button.disabled};
    }
  }
`;

interface CheckboxProps {
  checked: boolean;
  disabled?: boolean;
  type?: 'default' | 'tax';
  size?: 'medium' | 'small';
  label?: string;
  labelVariant?: Variant;
  children?: React.ReactNode;
  onChange?: (value: boolean) => void;
  onKeyPress?: (event: React.KeyboardEvent) => void;
  gap?: 1 | 2;
}

const Checkbox = ({
  checked,
  disabled = false,
  type = 'default',
  size = 'medium',
  label = undefined,
  labelVariant = 'body1',
  onChange,
  onKeyPress,
  children,
  gap = 1,
}: CheckboxProps) => {
  const Control = type === 'tax' ? TaxControl : DefaultControl;
  const uniqueId = label
    ? `${label}-${Math.random().toString(36).slice(0, 10)}`
    : undefined;

  return (
    <Container gap={gap}>
      <Control className={`${getClasses({ checked, disabled })} ${size}`}>
        <input
          type="checkbox"
          disabled={disabled}
          onChange={(event) => onChange?.(event.target.checked)}
          checked={checked}
          onKeyPress={onKeyPress}
          id={uniqueId}
        />
        <CheckIcon className={`${size}`} />
      </Control>
      <When isTrue={!!label || !!children}>
        <label htmlFor={uniqueId}>
          <Typography
            as="label"
            margin="none"
            variant={labelVariant}
            color={disabled ? 'disabled' : 'onLight'}
            htmlFor={uniqueId}
          >
            {label}
            {React.Children.map(children, (child) =>
              React.isValidElement<{
                disabled: boolean;
              }>(child)
                ? React.cloneElement(child, {
                    disabled,
                  })
                : child
            )}
          </Typography>
        </label>
      </When>
    </Container>
  );
};

export default Checkbox;
