import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';
import { Field as FormField } from '@agoy/tax-declaration-document';

const TaxInputContainerStyle = styled('div', {
  shouldForwardProp: (prop) => (prop === 'field' ? false : isPropValid(prop)),
})<{
  field?: FormField;
}>`
  position: relative;

  &.absolute {
    position: absolute;
    // legacy input is lower, so we need to adjust height
    top: calc(${({ field }) => field?.y} * 100% - 7.6px);
    left: calc(${({ field }) => field?.x} * 100%);
    width: calc(${({ field }) => field?.w} * 100%);
    height: ${({ field }) => (field?.h ? `calc(${field.h} * 100%)` : 'auto')};
  }

  :not(.absolute) {
    width: 100%;
  }

  border-radius: ${({ theme }) => theme.spacing(0.25)}px;
  background: ${({ theme }) =>
    theme.palette.taxDeclarationView.field.background};
  background-clip: border-box;
  mix-blend-mode: multiply;
  isolation: isolate;

  color: ${({ theme }) => theme.palette.fortnoxText.onLight};
  font-variant-numeric: lining-nums tabular-nums;

  font-family: 'Exo 2';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;

  &.title {
    font-weight: 700;
  }

  :focus-within {
    outline: 1px solid
      ${({ theme }) => theme.palette.taxDeclarationView.field.accent};
    background: #fff;
  }

  input:not([type='checkbox']),
  textarea {
    // 24px + padding of 4 to avoid extra containers
    min-height: ${({ theme }) => theme.spacing(3.5)}px;
    padding: ${({ theme }) => theme.spacing(0.25)}px;
    border-radius: ${({ theme }) => theme.spacing(0.25)}px;

    :hover:not(:focus) {
      background: ${({ theme }) =>
        theme.palette.taxDeclarationView.field.hoverBackground};
      mix-blend-mode: multiply;
    }
  }

  &.number {
    text-align: right;
  }

  &.reference {
    ::before {
      display: inline-block;
      position: absolute;
      content: url("data:image/svg+xml;charset=UTF-8,  <svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'><path d='M3 0.5H1.79289L2.64645 1.35355L10.6464 9.35355L11.5 10.2071V9V1V0.5H11H3Z' fill='%237779FF' stroke='%237779FF'/></svg>");
      background-size: 12px 12px;
      height: ${({ theme }) => theme.spacing(1.5)}px;
      width: ${({ theme }) => theme.spacing(1.5)}px;
      line-height: 8px;
      top: -1px;
      right: -1px;
    }
  }

  &.hideBackground {
    mix-blend-mode: normal;
  }

  @media print {
    background: none;
  }
`;

const ChangedBackdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  pointer-events: none;

  background: repeating-linear-gradient(
    -45deg,
    transparent 0px,
    transparent 5px,
    ${({ theme }) => theme.palette.taxDeclarationView.field.changedBackdrop} 5px,
    ${({ theme }) => theme.palette.taxDeclarationView.field.changedBackdrop}
      10px
  );
  mix-blend-mode: color-burn;
  opacity: 0.65;
`;

const TaxCheckboxInputContainerStyle = styled('div', {
  shouldForwardProp: (prop) => (prop === 'field' ? false : isPropValid(prop)),
})<{ field?: FormField }>`
  &.absolute {
    position: absolute;
    top: calc(${({ field }) => field?.y} * 100% - 1px);
    left: calc(${({ field }) => field?.x} * 100% - 1px);
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export {
  InputContainer,
  TaxInputContainerStyle,
  ChangedBackdrop,
  TaxCheckboxInputContainerStyle,
};
