import React, { createContext, useCallback, useMemo, useState } from 'react';
import { VoucherPrintData } from 'types/Voucher';

type AdjustmentsPrintContextType = {
  updateVouchersData: (voucherKey: string, data: VoucherPrintData) => void;
  vouchersData: Record<string, VoucherPrintData>;
};

const AdjustmentsPrintContext = createContext<AdjustmentsPrintContextType>({
  updateVouchersData: () => {},
  vouchersData: {},
});

type AdjustmentsPrintProviderProps = {
  children: React.ReactNode;
};

export const AdjustmentsPrintProvider = ({
  children,
}: AdjustmentsPrintProviderProps): JSX.Element => {
  const [vouchersData, setVouchersData] = useState<
    Record<string, VoucherPrintData>
  >({});

  const updateVouchersData = useCallback(
    (voucherKey: string, voucherData: VoucherPrintData) => {
      setVouchersData((currentValue) => ({
        ...currentValue,
        [voucherKey]: { ...voucherData },
      }));
    },
    []
  );

  const value = useMemo(
    () => ({ vouchersData, updateVouchersData }),
    [vouchersData, updateVouchersData]
  );

  return (
    <AdjustmentsPrintContext.Provider value={value}>
      {children}
    </AdjustmentsPrintContext.Provider>
  );
};

export default AdjustmentsPrintContext;
