import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Print as PrintIcon, Close as CloseIcon } from '@material-ui/icons';
import {
  Dialog as MuiDialog,
  DialogContent,
  IconButton,
} from '@material-ui/core';

import { Period } from '@agoy/api-sdk-core';
import { ReportData } from '_reconciliation/types';
import LoadingLogo from '_shared/components/LoadingLogo';
import InvoicesPreview from './InvoicesPreview';

const Container = styled.section`
  @media print {
    page-break-inside: avoid;
  }
`;

const Dialog = styled(MuiDialog)`
  @media print {
    .MuiDialog-paper {
      margin: 0;
      border: none;
      border-radius: 0;
    }
  }
`;

const TopBarActions = styled.div`
  display: flex;
  justify-content: space-between;
  @media print {
    display: none;
  }
`;

const PrintButton = styled(IconButton)`
  @media print {
    display: none;
  }
  background-color: white;
`;

const CloseButton = styled(IconButton)`
  @media print {
    display: none;
  }
  background-color: white;
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Content = styled.div`
  width: 100%;
`;

type InvoicesPreviewProps = {
  data: ReportData;
  period: Period;
  open: boolean;
  onPrint: () => Promise<void>;
  onClose: () => void;
};

const InvoicesPreviewModal = ({
  data,
  period,
  open,
  onPrint,
  onClose,
}: InvoicesPreviewProps): JSX.Element => {
  const [isPrintLoading, setIsPrintLoading] = useState(false);

  const handlePrint = async () => {
    setIsPrintLoading(true);
    await onPrint();
    setIsPrintLoading(false);
  };

  return (
    <Container>
      <Dialog open={open} onClose={onClose} maxWidth="lg">
        <DialogContent>
          <TopBarActions>
            <LoadingContainer>
              <PrintButton
                aria-label="print"
                disabled={isPrintLoading}
                onClick={handlePrint}
              >
                {isPrintLoading ? <LoadingLogo size="tiny" /> : <PrintIcon />}
              </PrintButton>
            </LoadingContainer>
            <CloseButton aria-label="close" onClick={onClose}>
              <CloseIcon />
            </CloseButton>
          </TopBarActions>
          <Content>
            <InvoicesPreview data={data} period={period} />
          </Content>
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default InvoicesPreviewModal;
