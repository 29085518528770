import { Buffer } from 'buffer';

window.Buffer = Buffer;

function arrayBufferToBufferAsArgument(ab) {
  return Buffer.from(ab);
}

const arrayBufferToBuffer = arrayBufferToBufferAsArgument;

export default arrayBufferToBuffer;
