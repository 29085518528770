import React, { useMemo } from 'react';
import { Typography } from '@material-ui/core';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import { parseFormat } from '@agoy/dates';

import { Checklist, ChecklistStatuses } from '_shared/redux/checklist/types';
import { AccountingPeriod } from '@agoy/api-sdk-core';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const GroupHeader = styled(Row)`
  background-color: #f5f5f5;
  padding: 8px 16px;
`;

const Items = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

const ItemContainer = styled.div`
  display: grid;
  grid-template-columns: 6fr 2fr 1fr;
  padding: 8px 16px;
  border-bottom: 1px solid #ebebeb;
`;

const Title = styled(Typography)`
  font-size: 20px;
  margin-bottom: 8px;
`;

const GroupHeaderText = styled(Typography)`
  font-size: 18px;
  font-weight: 700;
`;

const GreyText = styled(Typography)`
  color: #686868;
`;

const ProgramText = styled(GreyText)`
  text-transform: capitalize;
  font-size: 18px;
`;

type ChecklistProgramProps = {
  checklist: Checklist;
  statuses: ChecklistStatuses;
  period: AccountingPeriod;
};

const ChecklistProgram = ({
  checklist,
  statuses,
  period,
}: ChecklistProgramProps): JSX.Element | null => {
  const { formatMessage } = useIntl();

  const { periods, program, name } = checklist;
  const { groups } = periods[period.id];
  const checklistStatuses = statuses[period.id];

  const isEmpty = useMemo(() => {
    return !groups.find((group) =>
      group.questions.find((question) => checklistStatuses?.[question.id!])
    );
  }, [checklistStatuses, groups]);

  if (isEmpty || !checklist.includeInPrint) {
    return null;
  }

  return (
    <>
      <Title>{name}</Title>

      <Row>
        <ProgramText>
          {formatMessage({ id: `checklist.title.${program}` })}
        </ProgramText>
        <ProgramText>{parseFormat(period.start, 'MMM-yy')}</ProgramText>
      </Row>

      {groups.map((group) => (
        <React.Fragment key={group.id}>
          <GroupHeader>
            <GroupHeaderText>{group.label}</GroupHeaderText>
            {group.fromAccount && group.toAccount && (
              <GroupHeaderText>
                {formatMessage({ id: 'account' })}
                {': '}
                {group.fromAccount}
                {' - '}
                {group.toAccount}
              </GroupHeaderText>
            )}
          </GroupHeader>

          <Items>
            <ItemContainer>
              <GreyText>
                {formatMessage({ id: `checklist.create.item` })}
              </GreyText>
              <GreyText>{formatMessage({ id: `checklist.period` })}</GreyText>
              <GreyText>{formatMessage({ id: `checklist.status` })}</GreyText>
            </ItemContainer>

            {group.questions.map((question) => (
              <ItemContainer key={question.id}>
                <Typography>{question.label}</Typography>
                <Typography>
                  {formatMessage({
                    id: `checklist.create.periodicity.${question.periodicity}`,
                  })}
                </Typography>
                <Typography>
                  {checklistStatuses?.[question.id!]
                    ? formatMessage({
                        id: `checklist.status.${
                          checklistStatuses?.[question.id!]
                        }`,
                      })
                    : ''}
                </Typography>
              </ItemContainer>
            ))}
          </Items>
        </React.Fragment>
      ))}
    </>
  );
};

export default ChecklistProgram;
