import React, { useState, useCallback, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { ccyFormat, ccyParse } from '@agoy/common';
import { TextField } from '@material-ui/core';
import InfoTooltip from '../InfoTooltip/InfoTooltip';

const ExternalField = styled(TextField)`
  .MuiInputBase-input {
    height: 0.5em;
  }
`;

export interface CurrencyFieldProps<T = any> {
  className?: string;
  displayDecimals?: number;
  editingDecimals?: number;
  onValueChange?: (value: number | undefined) => void;
  value?: number | undefined;
  defaultValue?: number | undefined;
  minValue?: number;
  maxValue?: number;
  parser?: (value: string) => number | undefined;
  formatter?: (value: number | undefined, decimals: number) => string;
  InputProps?: T;
  Input?: React.ComponentType<T>;
  warning?: string;
  externalField?: boolean;
  placeholder?: string;
  part?: string;
  fieldId?: string;
  unit?: string;
  disabled?: boolean;
}

const CurrencyField = React.forwardRef(
  (
    {
      className,
      displayDecimals = 0,
      editingDecimals = 2,
      value,
      defaultValue,
      onValueChange,
      minValue,
      maxValue,
      Input = TextField,
      InputProps = {},
      formatter = ccyFormat,
      parser = ccyParse,
      warning,
      externalField,
      placeholder,
      unit,
      part,
      fieldId,
      disabled = false,
    }: CurrencyFieldProps,
    ref
  ) => {
    const [text, setText] = useState<string>('');
    const [decimals, setDecimals] = useState<number>(displayDecimals);
    const [hasError, setHasError] = useState<boolean>(false);
    const [editing, setEditing] = useState<boolean>(false);
    const [displayText, setDisplayText] = useState<string>('');
    const inputRef = useRef<HTMLInputElement>(null);

    const { readOnly = false } = InputProps;

    useEffect(() => {
      const formattedValue = formatter(defaultValue || value, decimals) || '';

      setText(formattedValue);
      setDisplayText(
        formattedValue ? formattedValue + (unit ? ` ${unit}` : '') : ''
      );
    }, [value, formatter, decimals, editing, defaultValue, unit]);

    useEffect(() => {
      const parsedValue = parser(text);
      // Validate
      if (
        parsedValue !== undefined &&
        minValue !== undefined &&
        parsedValue < minValue
      ) {
        setHasError(true);
      } else if (
        parsedValue !== undefined &&
        maxValue !== undefined &&
        parsedValue > maxValue
      ) {
        setHasError(true);
      } else {
        setHasError(false);
      }
    }, [text, parser, minValue, maxValue]);

    const handleChange = useCallback((event) => {
      setText(event.target.value);
    }, []);

    const commitValue = useCallback(
      (newValue: string) => {
        if (onValueChange) {
          const parsedText = parser(newValue);
          const editingValue = parser(formatter(value, editingDecimals));

          if (editingValue !== parsedText) {
            const nextValue =
              parsedText === undefined || Number.isNaN(parsedText)
                ? undefined
                : parsedText;
            onValueChange(nextValue);
          }
        }
      },
      [value, parser, formatter, editingDecimals, onValueChange]
    );

    const handleBlur = useCallback(
      (event) => {
        if (!hasError && !readOnly) {
          commitValue(event.target.value);
          setDecimals(displayDecimals);
        }
        setEditing(false);
      },
      [displayDecimals, commitValue, hasError, readOnly]
    );

    const handleFocus = useCallback(() => {
      if (readOnly) {
        return;
      }
      if (!hasError) {
        setDecimals(editingDecimals);
      }
      setEditing(true);

      requestAnimationFrame(() => {
        try {
          inputRef?.current?.select();
        } catch (e) {
          // Ignore failing select, not applicable for all input types
        }
      });
    }, [editingDecimals, hasError, readOnly, inputRef]);

    const handleEnter = useCallback(
      (event) => {
        if (event.key === 'Enter' && !hasError) {
          commitValue(event.target.value);
        }
      },
      [commitValue, hasError]
    );

    return (
      <>
        {externalField ? (
          <ExternalField
            value={editing ? text : displayText}
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            onKeyPress={handleEnter}
            variant="outlined"
            size="small"
          />
        ) : (
          <InfoTooltip part={part} fieldId={fieldId}>
            <Input
              {...InputProps}
              className={className}
              ref={ref ?? inputRef}
              onChange={handleChange}
              value={editing ? text : displayText}
              onBlur={handleBlur}
              onFocus={handleFocus}
              onKeyPress={handleEnter}
              placeholder={placeholder}
              error={hasError || !!warning}
              disabled={disabled}
            />
          </InfoTooltip>
        )}
      </>
    );
  }
);

export default CurrencyField;
