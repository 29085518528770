import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

import { parseFormat } from '@agoy/dates';
import { isNonZeroValue } from 'utils';
import { useSelector } from 'redux/reducers';
import { addGlobalMessage } from 'redux/actions';
import { numberValue, AgoyTableRow, stringValue } from '@agoy/document';
import { RESET_YEAR_END_PLANNING } from '_tax/redux/tax-view/action-types';
import CreateVoucherView from '_shared/components/VoucherView/CreateVoucherView';
import useVoucherForm from '_shared/components/VoucherView/useVoucherForm';
import {
  AccountPlanList,
  ReferencesTypes,
  VoucherSeries,
  VoucherStatusType,
  VoucherTransaction,
} from 'types/Voucher';
import useClientDataLayer from 'data/client/useClientDataLayer';
import TaxesDataContext from '../../../service/TaxesDataContext';

const rowFilter = (row: AgoyTableRow): boolean => {
  const primaryFilterCheck = row.id.startsWith('@')
    ? row.active
    : isNonZeroValue(numberValue(row.cells?.value)) && row.active;

  const account = numberValue(row.cells?.account);
  const secondaryFilterCheck = account !== 7530 && account !== 2514;

  return primaryFilterCheck && secondaryFilterCheck;
};

export const toTransaction = (row: AgoyTableRow): VoucherTransaction => {
  const value = numberValue(row.cells?.value) || 0;

  return {
    id: row.id,
    sourceInfo: stringValue(row.cells?.label) ?? '',
    kind: 'KS',
    typeAmount: value > 0 ? 'debit' : 'credit',
    amount: Math.abs(value),
    account: numberValue(row.cells?.account) ?? 0,
    debit: value > 0 ? value : 0,
    credit: value < 0 ? Math.abs(value) : 0,
  };
};

type VerificationTableProps = {
  title?: string;
  accountsList?: AccountPlanList[];
  seriesList?: VoucherSeries[];
  hideHeader?: boolean;
  editing?: boolean;
  print?: boolean;
  className?: string;
  onSetVoucherStatus?: (
    reference: ReferencesTypes,
    status: VoucherStatusType
  ) => void;
  onSetVoucherNumbers?: (reference: ReferencesTypes, series: string) => void;
};

const VerificationTable = ({
  title = 'Bokslutsverifikation',
  accountsList = [],
  seriesList = [],
  hideHeader = false,
  editing = false,
  print = false,
  className,
  onSetVoucherNumbers,
  onSetVoucherStatus,
}: VerificationTableProps) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const {
    financialYear,
    taxYearData,
    financialYearObj,
    service,
    isLastPeriod,
    period,
    clientId,
  } = useContext(TaxesDataContext);

  const adjustments = taxYearData.state?.document.accrualFunds.adjustments;

  const {
    voucherNumber,
    description,
    selectedSeries,
    transactions,
    date,
    voucherStatus,
    documents,
    nextVoucherNumber,
    minPeriodDate,
    maxPeriodDate,
    disabledDate,
    setTransactions,
    onChangeSeries,
    onChangeDescription,
    onCreateVoucher,
    onChangeDate,
    onChangeDocuments,
    onAddDocument,
    onDeleteVoucher,
    onReset,
  } = useVoucherForm({
    clientId,
    financialYear: financialYearObj,
    reference: ReferencesTypes.ACCRUAL_FUNDS,
    period,
    defaultDescription: formatMessage({ id: 'tax.accruals' }),
  });

  const clientDataLayer = useClientDataLayer(clientId);

  const [localEditing, setLocalEditing] = useState(false);

  const integrationActive = useSelector(
    (state) => !!state.customers[clientId]?.integrations?.fortnox
  );

  const accountingPeriod = useSelector((state) => {
    const periods =
      state.accountingView.clients[clientId].years[financialYear]
        ?.accountingBalances?.periods;

    return periods?.find(
      (item) => item.start === parseFormat(period, 'yyyy-MM-dd')
    );
  });

  useEffect(() => {
    if (!voucherStatus || !onSetVoucherStatus) return;

    onSetVoucherStatus(ReferencesTypes.ACCRUAL_FUNDS, voucherStatus);
  }, [onSetVoucherStatus, voucherStatus]);

  useEffect(() => {
    if (!selectedSeries || !onSetVoucherNumbers || !voucherNumber) return;
    onSetVoucherNumbers(
      ReferencesTypes.ACCRUAL_FUNDS,
      `${selectedSeries}-${voucherNumber}`
    );
  }, [onSetVoucherNumbers, selectedSeries, voucherNumber]);

  useEffect(() => {
    onChangeSeries(integrationActive ? 'I' : 'Agoy');
  }, [integrationActive, onChangeSeries]);

  useEffect(() => {
    if (!date) {
      onChangeDate(accountingPeriod?.end || '');
    }
  }, [accountingPeriod?.end, date, onChangeDate]);

  useEffect(() => {
    setTransactions(
      adjustments?.rows.filter(rowFilter).map(toTransaction) || []
    );
  }, [adjustments?.rows, setTransactions]);

  const handleChangeTransaction = (
    updatedTransaction: VoucherTransaction,
    field: string
  ) => {
    const rowId = updatedTransaction.id.toString();

    switch (field) {
      case 'account':
        service.updateCellValue(
          `accrualFunds.adjustments.${rowId}.account`,
          updatedTransaction.account
        );
        break;

      case 'sourceInfo':
        service.updateCellValue(
          `accrualFunds.adjustments.${rowId}.label`,
          updatedTransaction.sourceInfo
        );
        break;

      case 'debit':
      case 'credit':
        service.updateCellValue(
          `accrualFunds.adjustments.${rowId}.value`,
          field === 'debit'
            ? updatedTransaction.debit
            : -updatedTransaction.credit
        );
        break;
      default:
        break;
    }
  };

  const handleReset = () => {
    onReset();
    service.resetDocumentPart('accrualFunds.adjustments');
  };

  const handleAddNewTransaction = useCallback(() => {
    service.addRow('accrualFunds.adjustments', `@${Date.now()}`);
  }, [service]);

  const handleDeleteTransaction = useCallback(
    (rowId: string | number) => {
      service.deleteRow(`accrualFunds.adjustments.${rowId}`);
    },
    [service]
  );

  const handleChangeEditing = useCallback(() => {
    setLocalEditing((currentValue) => !currentValue);
  }, []);

  const handleDeleteDocument = (id: number) => {
    const updatedDocuments = documents.filter((item) => item.id !== id);
    onChangeDocuments(updatedDocuments);
  };

  const handleCreateVoucher = async (voucher) => {
    const result = await onCreateVoucher(voucher);

    const message = integrationActive
      ? 'tax.voucher.fortnox.created'
      : 'tax.voucher.agoy.created';

    if (result.ok) {
      dispatch(
        addGlobalMessage(
          'success',
          voucher.preliminary
            ? 'tax.voucher.fortnox.created.preliminary'
            : message
        )
      );

      adjustments?.rows.forEach((row) => {
        if (row.id.startsWith('@')) {
          service.deleteRow(`accrualFunds.adjustments.${row.id}`);
        }
      });

      if (isLastPeriod) {
        service.resetGenericTax(RESET_YEAR_END_PLANNING, [
          'chosenReversalOfAccrualFund',
        ]);
      }
      if (integrationActive) {
        await clientDataLayer.accountingBalances.updateSieFromFortnox(
          financialYear,
          financialYearObj.id
        );
      }
    }
  };

  return (
    <div id="verification">
      <CreateVoucherView
        clientId={clientId}
        voucherStatus={voucherStatus}
        isFinalVoucherEqualCalculations={adjustments?.rows.every(
          (row) => Math.abs(numberValue(row.cells?.value) || 0) < 1
        )}
        financialYear={financialYearObj}
        titleText={title}
        transactions={transactions}
        date={date}
        series={selectedSeries}
        description={description}
        voucherNumber={voucherNumber}
        documents={documents}
        accountsList={accountsList}
        seriesList={seriesList}
        source={ReferencesTypes.ACCRUAL_FUNDS}
        fortnoxActive={integrationActive}
        editing={editing || localEditing}
        print={print}
        showHeader={!hideHeader}
        className={className}
        minDate={minPeriodDate}
        maxDate={maxPeriodDate}
        nextVoucherNumber={nextVoucherNumber}
        disabledDate={disabledDate}
        onSave={handleCreateVoucher}
        onReset={handleReset}
        onAddTransaction={handleAddNewTransaction}
        onUpdateTransaction={handleChangeTransaction}
        onRemoveTransaction={handleDeleteTransaction}
        onChangeSeries={onChangeSeries}
        onChangeEditing={handleChangeEditing}
        onChangeDate={onChangeDate}
        onChangeDescription={onChangeDescription}
        onAddDocument={onAddDocument}
        onDeleteDocument={handleDeleteDocument}
        onDeleteVoucher={onDeleteVoucher}
      />
    </div>
  );
};

export default VerificationTable;
