import React from 'react';
import { useIntl } from 'react-intl';
import { Box, Typography } from '@material-ui/core';
import styled from '@emotion/styled';
import { ccyFormat } from '@agoy/common';
import { InputData } from '_reconciliation/types';
import { AccountingAccount } from 'types/Accounting';

import { PeriodDocument } from 'utils/usePeriodDocuments/Provider';
import SpecificationInfo from '_shared/components/SpecificationInfo';
import DocumentView from '_shared/components/Document';
import { FinancialYear, Period } from '@agoy/api-sdk-core';
import { BalanceAndInput } from '../types';

const Table = styled.div`
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-column-gap: ${(props) => props.theme.spacing(1)}px;
  grid-row-gap: ${(props) => props.theme.spacing(1)}px;
  margin-top: ${(props) => props.theme.spacing(2)}px;
`;

const ContentContainer = styled.div`
  width: 800px;
  background-color: ${(props) => props.theme.palette.grey[50]};
  padding: ${(props) => props.theme.spacing(1)}px;
  margin-top: ${(props) => props.theme.spacing(2)}px;
  @media print {
    background-color: ${(props) => props.theme.palette.common.white};
  }
`;

const Records = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => props.theme.spacing(2)}px;
`;

type ContentProps = {
  userData?: InputData;
  period: Period;
  accountInformation?: AccountingAccount;
  financialYear: FinancialYear;
  balanceAndInput?: BalanceAndInput;
  documents: PeriodDocument[];
  isPrint?: boolean;
  onDocumentLoaded: () => void;
};

const AccountContent = ({
  userData,
  period,
  accountInformation,
  financialYear,
  balanceAndInput,
  documents,
  isPrint = false,
  onDocumentLoaded,
}: ContentProps): JSX.Element => {
  const { formatMessage } = useIntl();

  const { comment, printComment } = balanceAndInput ?? {};

  const { legacySpecifications = [] } = userData ?? {};

  return (
    <ContentContainer>
      {userData && accountInformation && (
        <SpecificationInfo
          period={period}
          financialYear={financialYear}
          inputData={userData}
          accountName={accountInformation.name}
          accountNumber={accountInformation.number}
          yearIB={accountInformation.balances[financialYear.id].in ?? 0}
        />
      )}

      {legacySpecifications.length > 0 && (
        <Table>
          <b>{formatMessage({ id: 'description' })}</b>
          <b>{formatMessage({ id: 'reference' })}</b>
          <b>{formatMessage({ id: 'account' })}</b>
          <b>{formatMessage({ id: 'amount' })}</b>
          {legacySpecifications.map(
            ({ description, reference, account, amount, id }) => (
              <React.Fragment key={id}>
                <div>{description}</div>
                <div>{reference}</div>
                <div>{account}</div>
                <div>{ccyFormat(amount)}</div>
              </React.Fragment>
            )
          )}
        </Table>
      )}

      {comment && printComment && (
        <Box paddingTop={2}>
          <Typography component="div">
            {formatMessage({
              id: 'comment',
            })}
            :
            <Box component="span" paddingLeft={1}>
              {comment}
            </Box>
          </Typography>
        </Box>
      )}

      {documents.length > 0 && (
        <Records>
          <Box fontWeight="fontWeightBold">
            {formatMessage({ id: 'hidden.event.view' })}
          </Box>
          {documents.map((document) => (
            <DocumentView
              document={document}
              isPrint={isPrint}
              onLoad={onDocumentLoaded}
              key={document.id}
            />
          ))}
        </Records>
      )}
    </ContentContainer>
  );
};

export default AccountContent;
