import React from 'react';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { getClasses } from '@agoy/common';
import Typography from '_shared/components/Typography/Typography';
import Checkbox from '_shared/components/Controls/Checkbox';
import { ReactComponent as Pinned } from 'assets/pinned.svg';
import { useColumnDrag, useColumnDrop } from './DndHooks';
import Options from './Options';

const Row = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Container = styled(Row)`
  padding: 4px 4px 4px 0;
  margin-left: 28px;
  justify-content: space-between;
  align-items: center;

  &.draggable {
    cursor: grab;

    :hover {
      .dragIcon {
        visibility: visible;
      }
    }

    &.over {
      padding-top: 2px;
      border-top: 2px solid #ffc200;
    }

    &.isDragging {
      visibility: hidden;
    }
  }
`;

const PinnedContainer = styled(Row)`
  justify-content: center;
  height: 20px;
  border-radius: 12px;
  background-color: #f8f1e8;
  padding: 4px 8px;
  column-gap: 4px;
`;

const PinnedIcon = styled(Pinned)`
  height: ${({ theme }) => theme.spacing(1.5)}px;
  width: ${({ theme }) => theme.spacing(1.5)}px;
`;

const DragIcon = styled(DragIndicatorIcon)`
  visibility: hidden;
  position: absolute;
  left: -24px;
  font-size: 20px;
`;

// for test, will be updated
export type Column = {
  id: number;
  contentType: 'TEXT' | 'AMOUNT' | 'DATE' | 'PERCENTAGE';
  endDate: null;
  name: string;
  startDate: null;
  type: 'DEFAULT' | 'USER';
  checked: boolean;
  pinned: boolean;
  order?: number;
};

type ColumnItemProps = {
  column: Column;
  isDraggable?: boolean;
  onCheck: (id: number, checked: boolean) => void;
  onPin: (id: number) => void;
  onChangeOrder: (id: number, dropId: number) => void;
};

const ColumnItem = ({
  column,
  isDraggable = false,
  onCheck,
  onPin,
  onChangeOrder,
}: ColumnItemProps) => {
  const { formatMessage } = useIntl();
  const handleCheck = (value: boolean) => {
    onCheck(column.id, value);
  };

  const handlePin = () => {
    onPin(column.id);
  };

  const handleDrop = (draggingColumnId: number) => {
    if (isDraggable) {
      onChangeOrder(draggingColumnId, column.id);
    }
  };

  const [{ isDragging }, drag] = useColumnDrag(column, isDraggable);
  const [{ isOver, canDrop }, drop] = useColumnDrop(column, handleDrop);

  return (
    <Container
      className={getClasses({
        draggable: isDraggable,
        over: isOver && canDrop,
        isDragging: !!isDragging,
      })}
      ref={(node) => drag(drop(node))}
    >
      <Row>
        <DragIcon className="dragIcon" />
        <Checkbox
          checked={column.checked}
          label={column.name}
          size="small"
          onChange={handleCheck}
        />
      </Row>

      <Row>
        {column.pinned && (
          <PinnedContainer>
            <PinnedIcon />
            <Typography margin="none" variant="body2">
              {formatMessage({
                id: 'reconciliation.hidden.specifications.pin',
              })}
            </Typography>
          </PinnedContainer>
        )}
        <Options onEdit={() => {}} onPin={handlePin} />
      </Row>
    </Container>
  );
};

export default ColumnItem;
