import {
  DragSourceMonitor,
  DropTargetMonitor,
  useDrag,
  useDrop,
} from 'react-dnd';
import { DraggableItemTypes } from 'contants';
import { Column } from './ColumnItem';

type ColumnCollectedProps = {
  isOver: boolean;
  canDrop: boolean;
  isDragging?: boolean;
  item?: Column;
};

type ColumnDragObject = {
  column: Column;
};

const collect = (monitor: DropTargetMonitor): ColumnCollectedProps => {
  return {
    isOver: !!monitor.isOver(),
    canDrop: !!monitor.canDrop(),
    item: monitor.getItem(),
  };
};

export const useColumnDrop = (column: Column, onDrop: (id: number) => void) => {
  return useDrop<ColumnDragObject, void, ColumnCollectedProps>(
    {
      accept: DraggableItemTypes.RECONCILIATION_ACCOUNT_ROW,
      canDrop: (item) => column.id !== item.column.id,
      drop: (item) => onDrop(item.column.id),
      collect,
    },
    [column, onDrop]
  );
};

export const useColumnDrag = (column: Column, canDrag: boolean) => {
  return useDrag<ColumnDragObject, void, ColumnCollectedProps>(
    {
      type: DraggableItemTypes.RECONCILIATION_ACCOUNT_ROW,
      item: { column },
      canDrag: () => {
        return canDrag;
      },
      collect: (monitor: DragSourceMonitor) => ({
        canDrop: false,
        isOver: false,
        isDragging: monitor.isDragging(),
      }),
    },
    [column, canDrag]
  );
};
