import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { Box } from '@material-ui/core';
import { Table } from '_shared/components/CommonTable';
import { AgoyTableRow, Cell as CellType } from '@agoy/document';
import {
  NumberFormatType,
  PercentageNumberFormatType,
  StandardNumberFormatType,
} from '@agoy/common';
import Typography from '_shared/components/Typography/Typography';
import TaxesDataContext from '../../../service/TaxesDataContext';

const ReversalOfAccrualFund = ({
  print = false,
}: {
  print?: boolean;
}): JSX.Element | null => {
  const { taxYearData } = useContext(TaxesDataContext);

  const reversalAccrualFund =
    taxYearData.state?.document.accrualFunds.specificationReversalOfAccrualFund;

  const { formatMessage } = useIntl();

  const renderPercentageCol = (colId: string): NumberFormatType =>
    colId === 'percentage'
      ? PercentageNumberFormatType
      : StandardNumberFormatType;

  const filterRows = (
    rows: AgoyTableRow<CellType>[]
  ): AgoyTableRow<CellType>[] =>
    rows.filter((row) => {
      // Total reversalAmount & interestAmount row
      if (row.id === 'totalAccrualInterestAmount') {
        return true;
      }
      if (row.cells && row.cells.accountNumber.type === 'ref') {
        if (
          row.cells.reversalAmount.type === 'ref' &&
          row.cells.reversalAmount.value === 0
        ) {
          return false;
        }
        return (
          row.cells.accountNumber.value !== 0 &&
          typeof row.cells.accountNumber.value === 'number'
        );
      }
      return true;
    });

  return reversalAccrualFund ? (
    <Box id="specification">
      <Typography variant="h2">
        {formatMessage({ id: 'accrualFund.reversal' })}
      </Typography>
      <Table
        baseId="financialReport"
        tableId="accrualFund"
        rows={filterRows(reversalAccrualFund.rows)}
        columns={reversalAccrualFund.columns}
        print={print}
        numberFormatColumn={renderPercentageCol}
      />
    </Box>
  ) : null;
};

export default ReversalOfAccrualFund;
