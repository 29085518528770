import React from 'react';
import { useIntl } from 'react-intl';
import { Typography } from '@material-ui/core';
import styled from '@emotion/styled';

import { ccyFormat } from '@agoy/common';
import { isInputCorrect } from 'utils';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: min-content;
  white-space: nowrap;
`;

const Date = styled.div`
  margin-bottom: ${(props) => props.theme.spacing(2)}px;
`;

const NumbersRow = styled.div`
  margin-bottom: ${(props) => props.theme.spacing(1)}px;
`;

const ActualSaldoLabel = styled(Typography)`
  font-weight: 500;
`;

const ActualSaldoSum = styled(({ isGreen, ...props }) => (
  <Typography {...props} />
))<{ isGreen: boolean }>`
  color: ${(props) =>
    props.isGreen ? props.theme.palette.success.main : undefined};
`;

type Props = {
  date: string;
  periodIb: number | undefined;
  periodUb: number | undefined;
  periodSaldo: number | undefined;
  saldo: number;
  yearIB?: number;
};

const PeriodSummary = ({
  date,
  periodIb,
  periodUb,
  periodSaldo,
  saldo,
  yearIB,
}: Props): JSX.Element => {
  const intl = useIntl();

  const isSumGreen = saldo && periodUb && isInputCorrect(saldo, periodUb);

  return (
    <Wrapper>
      <Date>
        <Typography variant="body1" color="textSecondary">
          {date}
        </Typography>
      </Date>

      {(yearIB === 0 || yearIB) && (
        <NumbersRow>
          <Typography variant="body1" color="textSecondary">
            {intl.formatMessage({ id: 'hidden.ib.year' })}
          </Typography>
          <Typography variant="body1">{ccyFormat(yearIB)}</Typography>
        </NumbersRow>
      )}

      <NumbersRow>
        <Typography variant="body1" color="textSecondary">
          {intl.formatMessage({ id: 'hidden.ib' })}
        </Typography>
        <Typography variant="body1">{ccyFormat(periodIb)}</Typography>
      </NumbersRow>

      <NumbersRow>
        <Typography variant="body1" color="textSecondary">
          {intl.formatMessage({ id: 'hidden.change' })}
        </Typography>
        <Typography variant="body1">
          {ccyFormat(Number(periodUb) - Number(periodIb))}
        </Typography>
      </NumbersRow>

      <NumbersRow>
        <Typography variant="body1" color="textSecondary">
          {intl.formatMessage({ id: 'hidden.ub' })}
        </Typography>
        <Typography variant="body1">{ccyFormat(periodUb)}</Typography>
      </NumbersRow>

      <NumbersRow>
        <ActualSaldoLabel variant="body1">
          {intl.formatMessage({ id: 'actual.saldo' })}
        </ActualSaldoLabel>
        <ActualSaldoSum variant="body1" isGreen={!!isSumGreen}>
          {ccyFormat(saldo)}
        </ActualSaldoSum>
      </NumbersRow>

      <NumbersRow>
        <Typography variant="body1" color="textSecondary">
          {intl.formatMessage({ id: 'hidden.diff' })}
        </Typography>

        <Typography variant="body1">
          {periodUb !== undefined ? ccyFormat(Math.abs(saldo - periodUb)) : ''}
        </Typography>
      </NumbersRow>
    </Wrapper>
  );
};

export default PeriodSummary;
