import React from 'react';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { updateTaxDeclarationFormField } from 'redux/actions';
import { getClasses } from '@agoy/common';
import { Field, stringValue } from '@agoy/document';
import Typography from '@agoy/annual-report-view/src/UI/Typography/Typography';
import { Checkbox, Input, Radio, RadioOption } from './Input';

const PaymentTableWrapper = styled.div`
  table {
    margin: ${({ theme }) => theme.spacing(0.5)}px 0;
    max-width: 60%;
    border-collapse: collapse;
    border: 1px solid #000;

    thead {
      text-align: left;
    }

    td,
    th {
      padding: ${({ theme }) => theme.spacing(0.5)}px;
    }

    th {
      padding-bottom: 0;
    }

    td:nth-child(1) {
      width: 60%;
    }
  }
`;

type PaymentProps = {
  paymentToSkatteverketAmount: Field;
  paymentToSkatteverketDate: Field;
};

export const PaymentTable = ({
  paymentToSkatteverketAmount,
  paymentToSkatteverketDate,
}: PaymentProps) => {
  return (
    <PaymentTableWrapper>
      <table>
        <thead>
          <tr>
            <th>
              <Typography margin="none" variant="body2">
                Belopp att betala
              </Typography>
            </th>
            <th>
              <Typography margin="none" variant="body2">
                Senast den
              </Typography>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Input
                field={paymentToSkatteverketAmount}
                keyValue="paymentToSkatteverketAmount"
              />
            </td>
            <td>
              <Input
                field={paymentToSkatteverketDate}
                type="date"
                keyValue="paymentToSkatteverketDate"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </PaymentTableWrapper>
  );
};

const CheckBoxRadioWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  border: 1px solid #000;

  &.both-on {
    border-right: 0;
  }
`;

const InnerWrapper = styled.div`
  flex: 1;
  padding: ${({ theme }) => theme.spacing(1)}px;
  margin-bottom: ${({ theme }) => theme.spacing(0.5)}px;
  margin-top: ${({ theme }) => theme.spacing(0.5)}px;

  div:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  }
`;

type ToSkatteverketProps = {
  checkboxes: Field[];
};

export const ToSkatteverket = ({ checkboxes }: ToSkatteverketProps) => {
  const { formatMessage } = useIntl();
  return (
    <CheckBoxRadioWrapper>
      <InnerWrapper>
        {checkboxes.map((checkbox, index) => (
          <Checkbox
            field={checkbox}
            keyValue={`toSkatteverketCheckbox${index + 1}`}
            key={`toSkatteverketCheckbox${index + 1}`}
            label={formatMessage({
              id: `tax.coverLetter.toSkatteverketCheckbox${index + 1}`,
            })}
          />
        ))}
      </InnerWrapper>
    </CheckBoxRadioWrapper>
  );
};

type SigningOFIncomeDeclarationProps = {
  signatureOfIncomeDeclaration: Field;
  bothOn?: boolean;
};

export const SigningOFIncomeDeclaration = ({
  signatureOfIncomeDeclaration,
  bothOn = true,
}: SigningOFIncomeDeclarationProps) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const classes = getClasses({ 'both-on': bothOn });
  return (
    <CheckBoxRadioWrapper className={classes}>
      <InnerWrapper>
        <Radio
          defaultValue={stringValue(signatureOfIncomeDeclaration)}
          onChange={(value: string) => {
            dispatch(
              updateTaxDeclarationFormField(
                'CL',
                'signatureOfIncomeDeclaration',
                value
              )
            );
          }}
        >
          <RadioOption
            label={formatMessage({
              id: 'tax.coverLetter.switch.1',
            })}
            value="1"
          />
          <RadioOption
            label={formatMessage({
              id: 'tax.coverLetter.switch.2',
            })}
            value="2"
          />
          <RadioOption
            label={formatMessage({
              id: 'tax.coverLetter.switch.3',
            })}
            value="3"
          />
        </Radio>
      </InnerWrapper>
    </CheckBoxRadioWrapper>
  );
};

const TaxTableWrapper = styled.div`
  table {
    margin: ${({ theme }) => theme.spacing(0.5)}px 0;
    width: 100%;
    border-collapse: collapse;

    td {
      border: 1px solid #000;
      padding-top: ${({ theme }) => theme.spacing(1)}px;
      padding-bottom: ${({ theme }) => theme.spacing(0.5)}px;
      padding-left: ${({ theme }) => theme.spacing(1)}px;
    }

    td:nth-child(1) {
      width: 75%;
    }

    td:nth-child(2) {
      padding-left: ${({ theme }) => theme.spacing(0.5)}px;
      padding-right: ${({ theme }) => theme.spacing(0.5)}px;
    }

    input {
      text-align: right;
    }
  }
`;

type RowProps = {
  title: string;
  keyValue: string;
  field: Field;
};

const Row = ({ title, keyValue, field }: RowProps) => {
  return (
    <tr>
      <td>
        <Typography margin="none" variant="body2">
          {title}
        </Typography>
      </td>
      <td>
        <Input field={field} keyValue={keyValue} />
      </td>
    </tr>
  );
};

type TaxTableProps = {
  estimatedFinalTax: Field;
  estimatedTaxRefund: Field;
  fTax: Field;
};

export const TaxTable = ({
  estimatedFinalTax,
  estimatedTaxRefund,
  fTax,
}: TaxTableProps) => {
  return (
    <TaxTableWrapper>
      <table>
        <Row
          title="Beräknad slutlig skatt"
          keyValue="estimatedFinalTax"
          field={estimatedFinalTax}
        />
        <Row
          title="Beräknad skatteåterbäring"
          keyValue="estimatedTaxRefund"
          field={estimatedTaxRefund}
        />
        <Row title="F-skatt" keyValue="fTax" field={fTax} />
      </table>
    </TaxTableWrapper>
  );
};

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const LogoOrName = ({
  orgName,
  city,
  postalCode,
}: {
  orgName: string | undefined;
  city: string | null | undefined;
  postalCode: string | null | undefined;
}) => (
  <LogoWrapper>
    <Typography variant="h2" margin="top">
      {orgName}
    </Typography>
    <Typography variant="body1">
      {city} {postalCode}
    </Typography>
  </LogoWrapper>
);
