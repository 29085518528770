import { booleanValue } from '@agoy/document';
import {
  AnnualReport,
  AnnualReportType,
  getContentDefinition,
} from '@agoy/annual-report-document';

const excludedParts = [
  'settings',
  'annualGeneralMeeting',
  'confirmationCertificate',
  'digitalSubmission',
  'manualSubmission',
];

const extractPartsToPrint = (
  report: AnnualReport | undefined,
  reportType: AnnualReportType
): string[] => {
  if (!report) {
    return [];
  }

  const companyContentDefinition = getContentDefinition(report, reportType);

  const content = Object.keys(companyContentDefinition.children)
    .filter((part) => !excludedParts.includes(part))
    .filter((part) => {
      // cashFlowStatement is not toggled with this path for v2
      if (part === 'cashFlowStatement') return true;
      return Object.keys(companyContentDefinition.children[part].children)
        .filter((section) => report[part][section] !== undefined)
        .some((section) => booleanValue(report[part][section].active, true));
    });
  if (content.includes('signatures')) return content;

  return [...content, 'signatures'];
};

export default extractPartsToPrint;
