import React, { useState, useMemo } from 'react';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';
import { Tooltip, Typography } from '@material-ui/core';

import { FinancialYear } from '@agoy/api-sdk-core';
import { useSelector } from 'redux/reducers';
import {
  VoucherTransaction,
  Voucher,
  AccountPlanList,
  VoucherSeries,
  VoucherStatusType,
  ReferencesTypes,
} from 'types/Voucher';
import LabelField from '_shared/components/Inputs/LabelField';
import { SimplifiedDatePicker } from '_shared/components/Inputs';
import LabelSelectorAutocomplete from '_shared/components/Inputs/LabelSelectorAutocomplete';
import EditingIconButton from '_shared/components/Buttons/EditingIconButton';
import PrintButton from '_shared/components/Buttons/PrintButton';
import ResetContentButton from '_shared/components/ResetContent/ResetContentButton';
import Button from '_shared/components/Buttons/Button';
import { parse, isSameOrAfter, isSameOrBefore, parseFormat } from '@agoy/dates';

import PreviewVoucherContent from './Preview/PreviewVoucherContent';
import { Document } from './Documents';
import TransactionTable from './TransactionTable/TransactionTable';
import DocumentsList from './DocumentsList';
import VoucherStatus from './VoucherStatus';
import VoucherChip from './VoucherChip';
import Alert from '../AlertBanners/Alert';
import { printDocument } from '../PrintedDocument/utils';
import PreviewVoucherModal from './Preview/PreviewVoucherModal';

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: white;
  min-width: 360px;
  width: 100%;
  padding: 24px;
  border: 1px solid ${({ theme }) => theme.palette.grey[500]};
  border-radius: 12px;
`;

const SubmitSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .MuiButtonBase-root {
    letter-spacing: 1px;
  }
`;

const FieldsSection = styled.div`
  display: grid;
  grid-template-columns: minmax(120px, 1.5fr) 1fr 1fr;
  grid-column-gap: 16px;
  width: 100%;
  padding-bottom: 32px;
`;

const Section = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 24px;
`;

const TableSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 10px;
`;

const PrintSection = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

const SectionTitle = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;

const Title = styled(Typography)`
  font-size: 20px;
  font-weight: 700;
`;

const TitleAndStatusSection = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  .MuiSvgIcon-root {
    width: 0.8em;
  }
`;

const SubmitButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
`;

export interface CreateVoucherViewProps {
  clientId: string;
  financialYear: FinancialYear;

  titleText?: string;
  extraTitleContent?: string | React.ReactElement;
  className?: string;

  accountsList?: AccountPlanList[];
  seriesList?: VoucherSeries[];
  source: ReferencesTypes;

  transactions: VoucherTransaction[];
  description: string;
  date: string;
  series: string;
  documents?: Document[];
  voucherNumber?: number;
  nextVoucherNumber?: number;
  isFinalVoucherEqualCalculations?: boolean;

  minDate?: Date;
  maxDate?: Date;

  voucherStatus?: VoucherStatusType;
  fortnoxActive?: boolean;
  editing?: boolean;
  disabled?: boolean;
  disabledDate?: boolean;
  disabledDescription?: boolean;
  disabledSeries?: boolean;
  showHeader?: boolean;
  printOrientation?: 'landscape' | 'portrait';
  viewMode?: boolean;
  print?: boolean;

  onSave?: (voucher: Voucher) => Promise<void>;
  onReset?: () => void;
  onAddTransaction?: (transaction: VoucherTransaction) => void;
  onUpdateTransaction?: (
    transaction: VoucherTransaction,
    field: string,
    oldTransaction?: VoucherTransaction
  ) => void;
  onRemoveTransaction?: (transactionId: number | string) => void;
  onChangeEditing?: () => void;
  onChangeDate?: (date: string) => void;
  onChangeDescription?: (description: string) => void;
  onChangeDocuments?: (documents: Document[]) => void;
  onAddDocument?: (file: File) => Promise<void>;
  onChangeSeries?: (series: string) => void;
  onDeleteDocument?: (id: number) => void;
  onDeleteVoucher?: () => Promise<void>;
}

const CreateVoucherView = ({
  clientId,
  financialYear,
  titleText = '',
  extraTitleContent,
  className,
  series,
  voucherNumber,
  transactions,
  description,
  date,
  nextVoucherNumber = 1,
  documents = [],
  accountsList = [],
  seriesList = [],
  source,
  minDate,
  maxDate,
  editing = true,
  disabled = false,
  disabledDate = false,
  disabledDescription = false,
  disabledSeries = false,
  fortnoxActive = false,
  showHeader = true,
  viewMode = false,
  voucherStatus = VoucherStatusType.SUGGESTION,
  print = false,
  isFinalVoucherEqualCalculations = true,
  onSave = async () => {},
  onReset = () => {},
  onAddTransaction = () => {},
  onUpdateTransaction = () => {},
  onRemoveTransaction = () => {},
  onChangeEditing = () => {},
  onChangeDescription = () => {},
  onChangeDate = () => {},
  onChangeSeries = () => {},
  onAddDocument = async () => {},
  onDeleteDocument = () => {},
  onDeleteVoucher = async () => {},
}: CreateVoucherViewProps) => {
  const { formatMessage } = useIntl();

  const startClientDate = useSelector(
    (state) => state.customers[clientId]?.startDate
  );

  const [loading, setLoading] = useState<
    'voucher' | 'prelVoucher' | 'deleteVoucher' | null
  >(null);
  const [isOpenPreview, setOpenPreview] = useState(false);
  const [printLoading, setPrintLoading] = useState(false);

  const seriesNumber = `${series}-${voucherNumber || nextVoucherNumber}`;

  const isEarlierClientStartDate = useMemo(() => {
    if (!startClientDate) {
      return false;
    }

    if (parse(startClientDate) > parse(date)) {
      return false;
    }

    return false;
  }, [date, startClientDate]);

  const filteredSeriesList = useMemo(() => {
    let result: VoucherSeries[] = [];

    result = seriesList.filter((item) => item.series !== 'PREL');

    return result.map((item) => ({ value: item.series, label: item.label }));
  }, [seriesList]);

  const getText = (textId: string) => {
    return formatMessage({ id: textId });
  };

  const createVoucher = async (preliminary = false) => {
    const voucher: Voucher = {
      transDate: date,
      source: fortnoxActive ? 'fortnox' : 'agoy',
      transactions,
      reference: source,
      attachments: documents.map((item) => ({
        name: item.name,
        id: item.id,
      })),
      series,
      description,
      preliminary,
    };

    setLoading(preliminary ? 'prelVoucher' : 'voucher');

    try {
      await onSave(voucher);
    } finally {
      setLoading(null);
    }
  };

  const handleChangeSeries = (value) => {
    onChangeSeries(value);
  };

  const handleChangeDate = (value) => {
    onChangeDate(value);
  };

  const handleShowPreview = () => {
    setOpenPreview(!isOpenPreview);
  };

  const handlePrint = async () => {
    setPrintLoading(true);

    const financialYearPeriod = `${parseFormat(
      financialYear.start,
      'yyyyMM'
    )}-${parseFormat(financialYear.end, 'yyyyMM')}`;

    try {
      await printDocument(
        clientId,
        financialYearPeriod,
        'voucher',
        titleText || 'voucher',
        [],
        {
          voucherData: {
            selectedDate: date,
            voucherDescription: description,
            transactions,
            accounts: accountsList,
            title: titleText,
            documents,
            status: voucherStatus,
            seriesNumber,
          },
        },
        documents.map((item) => item.url)
      );
    } finally {
      setPrintLoading(false);
    }
  };

  const createPreliminaryVoucher = async () => {
    await createVoucher(true);
  };

  const handleDeleteVoucher = async () => {
    setLoading('deleteVoucher');
    await onDeleteVoucher();
    onReset();
    setLoading(null);
  };

  const isTransactionsEmpty =
    !transactions.length || !!transactions.find((item) => !item.amount);

  const inDateInterval =
    minDate && maxDate
      ? isSameOrAfter(parse(date), minDate) &&
        isSameOrBefore(parse(date), maxDate)
      : true;

  const submitDisabled =
    !!loading ||
    disabled ||
    !description ||
    !series ||
    isTransactionsEmpty ||
    !inDateInterval ||
    disabledDate;

  const updatedEditing =
    editing && voucherStatus === VoucherStatusType.SUGGESTION;

  const canBeDeleted = nextVoucherNumber - 1 === voucherNumber;

  const isPrelButtonDisabled = fortnoxActive && series !== 'I';

  const getPreliminaryTooltip = () => {
    if (isPrelButtonDisabled) {
      return getText('tax.voucher.create.preliminary.disabled.tooltip');
    }

    if (date !== financialYear.end) {
      return getText('tax.voucher.create.preliminary.tooltip');
    }

    return '';
  };

  if (print) {
    return (
      <PreviewVoucherContent
        selectedDate={date}
        voucherDescription={description}
        transactions={transactions}
        accounts={accountsList}
        title={titleText}
        documents={documents}
        status={voucherStatus}
        seriesNumber={seriesNumber}
      />
    );
  }

  return (
    <Container className={className}>
      <PreviewVoucherModal
        selectedDate={date}
        voucherDescription={description}
        transactions={transactions}
        accounts={accountsList}
        onClose={handleShowPreview}
        open={isOpenPreview}
        documents={documents}
        title={titleText}
        status={voucherStatus}
        seriesNumber={seriesNumber}
        reference={source}
      />

      {!viewMode ? (
        showHeader && (
          <Section>
            <SectionTitle>
              <Title>{titleText}</Title>
              <TitleAndStatusSection>
                {extraTitleContent}

                {source !== ReferencesTypes.GLIDER && (
                  <VoucherStatus status={voucherStatus} />
                )}
                {voucherStatus === VoucherStatusType.FINAL && (
                  <VoucherChip voucherSeries={seriesNumber} />
                )}
              </TitleAndStatusSection>
            </SectionTitle>
            <Buttons>
              <EditingIconButton
                value={updatedEditing}
                disabled={disabled || voucherStatus === VoucherStatusType.FINAL}
                onClick={onChangeEditing}
              />
              <PrintButton
                disabled={printLoading}
                loading={printLoading}
                onClick={handlePrint}
              />
              <ResetContentButton
                what={titleText}
                onReset={onReset}
                disabled={voucherStatus === VoucherStatusType.FINAL}
              />
            </Buttons>
          </Section>
        )
      ) : (
        <PrintSection>
          <PrintButton
            disabled={printLoading}
            loading={printLoading}
            onClick={handlePrint}
          />
        </PrintSection>
      )}

      <FieldsSection>
        <LabelField
          onChange={onChangeDescription}
          value={description}
          disabled={disabledDescription || !updatedEditing}
          label={`${getText('hidden.lagerjustering.description')} *`}
        />

        <LabelSelectorAutocomplete
          title={getText('tax.voucher.serie')}
          items={filteredSeriesList}
          onValueChange={handleChangeSeries}
          disabled={disabledSeries || !updatedEditing}
          value={series}
          fullWidth
        />

        <SimplifiedDatePicker
          title={getText('reconciliation.speedDial.voucher.date')}
          value={date}
          onChange={handleChangeDate}
          minDate={minDate}
          maxDate={maxDate}
          minDateMessage={
            isEarlierClientStartDate
              ? getText(
                  'reconciliation.speedDial.voucher.minClientStartDate.error'
                )
              : getText('reconciliation.speedDial.voucher.minDate.error')
          }
          maxDateMessage={getText(
            'reconciliation.speedDial.voucher.maxDate.error'
          )}
          margin="none"
          disabled={disabledDate || !updatedEditing}
        />
      </FieldsSection>

      <TableSection>
        <TransactionTable
          records={transactions}
          accounts={accountsList}
          editMode={updatedEditing}
          disabled={!!loading}
          onAddTransaction={onAddTransaction}
          onUpdateTransaction={onUpdateTransaction}
          onRemoveTransaction={onRemoveTransaction}
        />
      </TableSection>
      {voucherStatus === VoucherStatusType.FINAL &&
        !isFinalVoucherEqualCalculations && (
          <TableSection>
            <Alert type="warning">
              {getText('tax.voucher.not.matching.transactions.warning')}
            </Alert>
          </TableSection>
        )}

      <DocumentsList
        isEdit={updatedEditing}
        documents={documents}
        handleAddDocument={onAddDocument}
        handleDeleteDocument={onDeleteDocument}
      />

      <SubmitSection>
        <SubmitButtonsContainer>
          {voucherStatus !== VoucherStatusType.FINAL && (
            <Button
              label={getText('voucher.create')}
              onClick={() => createVoucher()}
              disabled={submitDisabled}
              loading={loading === 'voucher'}
            />
          )}
          {voucherStatus === VoucherStatusType.SUGGESTION && (
            <Tooltip title={getPreliminaryTooltip()}>
              <span>
                <Button
                  label={getText('tax.voucher.create.preliminary')}
                  onClick={createPreliminaryVoucher}
                  variant="outlined"
                  disabled={
                    submitDisabled ||
                    date !== financialYear.end ||
                    isPrelButtonDisabled
                  }
                  loading={loading === 'prelVoucher'}
                />
              </span>
            </Tooltip>
          )}
        </SubmitButtonsContainer>

        {voucherStatus === VoucherStatusType.PRELIMINARY && (
          <Button
            label={getText('discard.changes')}
            onClick={handleDeleteVoucher}
            variant="outlined"
            color="danger"
            loading={loading === 'deleteVoucher'}
          />
        )}

        {voucherStatus === VoucherStatusType.FINAL &&
          canBeDeleted &&
          !fortnoxActive && (
            <Button
              label={getText('voucher.delete.button')}
              onClick={handleDeleteVoucher}
              variant="outlined"
              color="danger"
              loading={loading === 'deleteVoucher'}
            />
          )}
      </SubmitSection>
    </Container>
  );
};

export default CreateVoucherView;
