import React, { useEffect, useCallback } from 'react';
import { initWebAnalytics, trackPageView } from '@fnox/web-analytics-script';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'redux/reducers';
import { useLicenseStatus } from '_payment/licenses/useLicense';
import config from '_shared/services/config';

const washIt = (url) => {
  // replace UUID with guid (Fortnox use the term guid instead of UUID)
  const uuidPattern =
    /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/gi;

  // replace financial period ex. /20180101-20181231 with /financial-period
  const fpPattern = /\/\d{8}-\d{8}/;

  return url
    .replace(uuidPattern, 'guid')
    .replace(fpPattern, '/financial-period');
};

export const translateDataLayerKey = (key: string): string | null => {
  const dimensions = {
    client_tenant_id: 'dimension1', // event
    financial_period: 'dimension2', // event
    tenant_type: 'dimension3', // session
    parent_tenant_id: 'dimension4', // session
    license_type: 'dimension5', // session
  };

  return dimensions[key] ?? null;
};

const withPiwikAnalytics = (Component) => (props) => {
  // Selectors all the data we need to track
  const location = useLocation();
  const userId = useSelector((state) => state.user['custom:userId']);
  const customerId = useSelector((state) => state.customerView.currentCustomer);
  const customer = useSelector((state) =>
    customerId ? state.customers[customerId] : undefined
  );
  const { currentFinancialYearId, currentCustomer } = useSelector(
    (state) => state.customerView
  );
  const organisation = useSelector((state) => state.organisation);
  const rawFinancialYear = useSelector(
    (state) =>
      currentCustomer &&
      state.customers[currentCustomer]?.rawFinancialYears.find(
        (year) => year.id === currentFinancialYearId
      )
  );
  const { license } = useLicenseStatus();

  const updateDataLayer = useCallback((data) => {
    if (!window.fortnoxWebAnalytics) return;

    Object.entries(data).forEach(([key, value]) => {
      if (value) {
        window.fortnoxWebAnalytics.dataLayer[key] = value;
      }
    });
  }, []);

  // Initialize Fortnox Web Analytics
  useEffect(() => {
    if (!config.piwikKey) {
      // No key configured no need to initialize
      return;
    }

    const cookieDomain =
      config.runningEnvironment === 'development' ? 'localhost' : '.agoy.se';

    initWebAnalytics({
      washViewCallback: washIt,
      applicationId: config.piwikKey,
      cookieDomain,
      piwik: {
        translateCustomDimensionKeyCallback: translateDataLayerKey,
      },
    });

    window.fortnoxWebAnalytics.userId = userId;
  }, [userId]);

  // Listen to currentFinancialYearId updates
  useEffect(() => {
    if (!rawFinancialYear) return;

    const data = {
      financial_period: `${rawFinancialYear.start} to ${rawFinancialYear.end}`,
    };

    updateDataLayer(data);
  }, [rawFinancialYear, updateDataLayer]);

  // Listen to organization updates
  useEffect(() => {
    if (!organisation) return;

    const data = {
      parent_tenant_id: organisation?.thirdPartyId?.replace(/^fn_/, ''),
      tenant_type: organisation?.fortnoxTenantType,
    };

    updateDataLayer(data);
  }, [organisation, updateDataLayer]);

  // Listen to customer updates
  useEffect(() => {
    if (!customer) return;

    const data = {
      client_tenant_id: customer.thirdPartyId,
    };

    updateDataLayer(data);
  }, [customer, updateDataLayer]);

  // Listen to license updates
  useEffect(() => {
    if (!license) return;

    const data = {
      license_type: license === 'big' ? 'bokslutskatt' : 'avstamning',
    };

    updateDataLayer(data);
  }, [license, updateDataLayer]);

  // Track page views on location change
  useEffect(() => {
    const path = `${window.location.origin}${location.pathname}`;
    trackPageView({ view: path });
  }, [location]);

  return <Component {...props} />;
};

export default withPiwikAnalytics;
