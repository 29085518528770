import React from 'react';
import {
  Table as MuiTable,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import { ccyFormat } from '@agoy/common';
import styled from '@emotion/styled';
import { LegacySpecification } from '_clients/types/types';

const Table = styled(MuiTable)`
  border: 1px solid ${({ theme }) => theme.palette.grey[200]};

  tbody > tr > td:nth-of-type(1) {
    width: 300px;
  }

  tbody > tr > td:nth-of-type(2) {
    width: 300px;
  }
  tbody > tr > td:nth-of-type(3) {
    width: 300px;
  }
  tbody > tr > td:nth-of-type(4) {
    width: 300px;
  }
`;

type SpecificationsTableProps = {
  specifications: LegacySpecification[];
};

const SpecificationsTable = ({
  specifications,
}: SpecificationsTableProps): JSX.Element => {
  const { formatMessage } = useIntl();

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>
            <b>{formatMessage({ id: 'description' })}</b>
          </TableCell>
          <TableCell>
            <b>{formatMessage({ id: 'reference' })}</b>
          </TableCell>
          <TableCell>
            <b>{formatMessage({ id: 'account' })}</b>
          </TableCell>
          <TableCell>
            <b>{formatMessage({ id: 'amount' })}</b>
          </TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {specifications &&
          specifications.map(
            ({ description, reference, account, amount, id }) => (
              <TableRow key={id}>
                <TableCell>{description}</TableCell>
                <TableCell>{reference}</TableCell>
                <TableCell>{account}</TableCell>
                <TableCell>{ccyFormat(amount)}</TableCell>
              </TableRow>
            )
          )}
      </TableBody>
    </Table>
  );
};

export default SpecificationsTable;
