import React from 'react';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from '@material-ui/core';

import { ccyFormat } from '@agoy/common';

import { InputData } from '_reconciliation/types';
import { FinancialYear, Period } from '@agoy/api-sdk-core';
import { legacySpecificationSum } from '_reconciliation/components/ReconciliationView/HiddenRow/HiddenGroupRow/utils';

const StyledTable = styled(Table)`
  border: 1px solid ${({ theme }) => theme.palette.grey[200]};

  @media print {
    page-break-inside: avoid;
  }

  .MuiTableRow-head {
    background-color: ${({ theme }) =>
      theme.palette.specificationsView.darkGrey};
  }
  .MuiTableBody-root {
    background-color: ${({ theme }) => theme.palette.specificationsView.grey};
  }
  .MuiTableCell-head {
    font-weight: bold;
    width: 102px;
    padding: 3px;
    vertical-align: bottom;

    @media print {
      page-break-inside: avoid;
      width: 90px;
    }

    &:first-of-type {
      width: auto;
      padding: 3px 3px 3px 8px;
    }
    &:last-of-type {
      padding: 3px 8px 3px 3px;
    }

    p {
      font-weight: bold;
      font-size: 0.875rem;
    }
  }
  .MuiTableCell-body {
    padding: 6px 3px;

    &:not(:first-of-type) {
      div {
        background-color: ${({ theme }) => theme.palette.common.white};
      }
    }
    &:last-of-type {
      padding: 6px 8px 6px 3px;
    }
    &:first-of-type {
      padding: 6px 3px 6px 8px;
    }
  }
`;

const BodyCell = styled.div`
  padding: 4px 0 4px 4px;
`;

const AccountCell = styled.div`
  display: flex;
  flex-direction: column;
`;

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;
type SpecificationInfoProps = {
  accountNumber: string;
  accountName: string;
  period: Period;
  financialYear: FinancialYear;
  inputData: Optional<
    Pick<InputData, 'ib' | 'ub' | 'legacySpecifications' | 'yearIB'>,
    'legacySpecifications'
  >;
  className?: string;
  yearIB: number;
};

const SpecificationInfo = ({
  accountNumber,
  accountName,
  period,
  financialYear,
  inputData,
  className,
  yearIB,
}: SpecificationInfoProps): JSX.Element | null => {
  const { formatMessage } = useIntl();

  const { ib = 0, ub = 0, legacySpecifications } = inputData;
  const totalChange = ub - yearIB;
  const psaldo = ub - ib;

  return (
    <StyledTable size="small" className={className}>
      <TableHead>
        <TableRow>
          <TableCell>
            <AccountCell>
              <Typography>
                {formatMessage({ id: 'specification.header.description' })}
              </Typography>
              <Typography>
                {accountNumber} {accountName}
              </Typography>
            </AccountCell>
          </TableCell>
          <TableCell>
            {formatMessage({ id: 'specification.header.ibYear' })}
          </TableCell>
          <TableCell>
            {formatMessage({ id: 'specification.header.ibPeriod' })}
          </TableCell>
          <TableCell>
            {formatMessage({ id: 'specification.header.ub' })}
          </TableCell>
          <TableCell>
            {formatMessage({ id: 'specification.header.changePeriod' })}
          </TableCell>
          <TableCell>
            {formatMessage({ id: 'specification.header.changeYear.part1' })}
            <br />
            {formatMessage({ id: 'specification.header.changeYear.part2' })}
          </TableCell>
          <TableCell>
            {formatMessage({ id: 'specification.header.sum' })}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>
            {formatMessage(
              { id: 'specification.body.description' },
              {
                period: `${financialYear.start}—${period.end}`,
              }
            )}
          </TableCell>
          <TableCell>
            <BodyCell>{ccyFormat(yearIB)}</BodyCell>
          </TableCell>
          <TableCell>
            <BodyCell>{ccyFormat(ib)}</BodyCell>
          </TableCell>
          <TableCell>
            <BodyCell>{ccyFormat(ub)}</BodyCell>
          </TableCell>
          <TableCell>
            <BodyCell>{ccyFormat(psaldo)}</BodyCell>
          </TableCell>
          <TableCell>
            <BodyCell>{ccyFormat(totalChange)}</BodyCell>
          </TableCell>
          <TableCell>
            <BodyCell>
              {ccyFormat(legacySpecificationSum(legacySpecifications || []))}
            </BodyCell>
          </TableCell>
        </TableRow>
      </TableBody>
    </StyledTable>
  );
};

export default SpecificationInfo;
