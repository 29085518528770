import React from 'react';
import { Tooltip, IconButton } from '@material-ui/core';
import { Print } from '@material-ui/icons';
import LoadingLogo from '../LoadingLogo';

interface PrintButtonProps {
  onClick: () => void;
  tooltip?: string;
  disabled?: boolean;
  loading?: boolean;
}

const PrintButton = ({
  onClick,
  tooltip = '',
  disabled = false,
  loading = false,
}: PrintButtonProps): JSX.Element => {
  return (
    <Tooltip title={tooltip}>
      <IconButton onClick={onClick} disabled={disabled}>
        {loading ? <LoadingLogo size="tiny" /> : <Print />}
      </IconButton>
    </Tooltip>
  );
};

export default PrintButton;
