import { programStatusMapSchema } from '@agoy/program-status';
import { asResultClass, useApiSdk } from 'api-sdk';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'redux/reducers';
import { addGlobalErrorMessage } from '_messages/redux/actions';
import { Comment } from '_shared/types';

type Program = keyof typeof programStatusMapSchema;

/**
 * Hook for fetching and adding comments
 *
 * @param clientId Client id
 * @param program Program in which comment section is being used
 * @param financialYearId Financial year id
 * @param periodId Period id
 * @param key String to distinguish different comment types
 * @returns comment list, method to create a comment, loading state
 */
const useComments = (
  clientId: string,
  program: Program,
  financialYearId: number | undefined,
  periodId?: number,
  key?: string
): {
  comments: Comment[];
  createComment: (comment: string) => void;
  loading: boolean;
  savingNewComment: boolean;
  reloadComments: () => void;
} => {
  const [loading, setLoading] = useState(false);
  const [savingNewComment, setSavingNewComment] = useState(false);
  const [comments, setComments] = useState<Comment[]>([]);

  const dispatch = useDispatch();
  const { getCommentsForClient, addComments } = useApiSdk();
  const userId = useSelector((state) => state.user['custom:userId']);

  const fetchComments = useCallback(async () => {
    const commentsResult = await asResultClass(
      getCommentsForClient({
        clientId,
        program,
        financialYearId,
        periodId,
        key,
      })
    );

    if (commentsResult.ok) {
      setComments(commentsResult.val);
    } else {
      dispatch(addGlobalErrorMessage('comments.failed.loading'));
    }
  }, [
    clientId,
    dispatch,
    financialYearId,
    getCommentsForClient,
    key,
    periodId,
    program,
  ]);

  const reloadComments = useCallback(async () => {
    setLoading(true);
    await fetchComments();
    setLoading(false);
  }, [fetchComments]);

  useEffect(() => {
    if (clientId) {
      reloadComments();
    }
  }, [clientId, financialYearId, periodId]);

  const createComment = async (comment: string) => {
    setSavingNewComment(true);
    const commentsResult = await asResultClass(
      addComments({
        clientId,
        requestBody: [
          {
            userId,
            program,
            financialYearId,
            periodId,
            key,
            comment,
          },
        ],
      })
    );

    if (commentsResult.ok) {
      await fetchComments();
    } else {
      dispatch(addGlobalErrorMessage('comments.failed.sending'));
    }
    setSavingNewComment(false);
  };

  return { comments, createComment, loading, savingNewComment, reloadComments };
};

export default useComments;
