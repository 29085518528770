import React, { useContext } from 'react';
import {
  TaxSummaryFields,
  TaxDeclarationFormPartData,
  isTaxDeclarationTaxSummary,
} from '@agoy/tax-declaration-document';
import { useSelector } from 'redux/reducers';
import PrintStateContext from '_shared/components/PrintedDocument/PrintStateContext';
import TaxSummary from './TaxSummary';

export const TaxSummaryPrint = () => {
  const { state } = useContext(PrintStateContext);
  if (!state.additionalPages) {
    return null;
  }
  const { orgName, postalCode, city } = state.additionalPages;

  const fields = state.additionalPages.document.TS.fields as TaxSummaryFields;

  return (
    <TaxSummary
      fields={fields}
      postalCode={postalCode}
      city={city}
      orgName={orgName}
    />
  );
};

type TaxSummaryWebProps = {
  document: TaxDeclarationFormPartData | undefined;
};

export const TaxSummaryWeb = ({ document }: TaxSummaryWebProps) => {
  const organisation = useSelector((state) => state.organisation);

  const { name: orgName, postalCode, city } = organisation;

  if (!document || !isTaxDeclarationTaxSummary(document)) {
    return null;
  }

  const fields = document.fields as TaxSummaryFields;

  return (
    <TaxSummary
      fields={fields}
      postalCode={postalCode}
      city={city}
      orgName={orgName ?? ''}
    />
  );
};
