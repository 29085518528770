/* eslint-disable import/no-cycle */
import {
  ADD_TAX_DECLARATION_YEAR,
  DELETE_TAX_DECLARATION_FORM,
  VIEW_TAX_DECLARATION_FORM,
  SET_TAX_DECLARATION_FORM_STATUS,
  SET_TAX_DECLARATION_FORM_DESCRIPTION,
  SET_TAX_DECLARATION_FORMS,
  SET_TAX_DECLARATION_DEFS,
  SET_TAX_DECLARATION_CONTENT_DEF,
  SET_TAX_DECLARATION_FORM_STATE,
  UPDATE_TAX_DECLARATION_FORM_FIELD,
  RESET_TAX_DECLARATION_FORM_FIELD,
  UPDATE_TAX_DECLARATION_FORM_DATA,
  ADD_TAX_DECLARATION_YEAR_STATUS,
  SET_TAX_DECLARATION_YEAR_STATUSES,
  RESET_TAX_DECLARATION_VIEW_STATE,
  UPDATE_TAX_DECLARATION_FORM_EXTERNAL_FIELD,
  SET_TAX_DECLARATION_DOCUMENTS,
  SET_TAX_DECLARATION_CONTENT_DEF_BULK,
  SET_TAX_CONNECTIONS_CHART,
  DELETE_TAX_DECLARATION_YEAR,
  SET_TAX_DECLARATION_YEAR_WITH_INFO,
  SET_TAX_DECLARATION_FORM_QUALITY_CHECK,
  ADD_TAX_DECLARATION_TABLE_ROW,
  UPDATE_TAX_DECLARATION_TABLE_ROW_CELL,
  DELETE_TAX_DECLARATION_TABLE_ROW,
  SET_TAX_OWNERS,
  RESET_TAX_DECLARATION_TABLE,
  TOGGLE_TAX_DECLARATION_TABLE_ROW_ACTIVE,
  UPDATE_TAX_DECLARATION_TABLE_ROWS,
} from 'redux/actionsTypes';
import { TaxDeclarationViewActions } from 'redux/actions/TaxDeclarationView';
import { TaxDeclarationFormData } from '@agoy/tax-declaration-document';
import { GlobalActions } from '_shared/redux/actions';
import _ from 'lodash';
import { RESET_UNSAFE_CLIENT_DATA } from '_shared/redux/action-types';
import { operations, isError } from '@agoy/document';
import updateTaxDeclarationFormField from './reducers/updateTaxDeclarationFormField';
import resetTaxDeclarationFormField from './reducers/resetTaxDeclarationFormField';
import TaxDeclarationViewState, { TaxDeclarationForm } from './types';
import updateTaxDeclarationFormExternalField from './reducers/updateTaxDeclarationFormExternalField';
import updateTaxDeclarationFormTableCell from './reducers/updateTaxDeclarationFormTableCell';
import addTaxDeclarationFormTableRow from './reducers/addTaxDeclarationFormTableRow';
import deleteTaxDeclarationFormTableRow from './reducers/deleteTaxDeclarationFormTableRow';
import toggleTaxDeclarationFormTableRowActive from './reducers/toggleTaxDeclarationFormTableRowActive';
import updateTaxDeclarationFormTableRows from './reducers/updateTaxDeclarationFormTableRows';

const initialTaxViewState: TaxDeclarationViewState = {
  viewFormId: null,
  years: {},
  formData: {},
  connectionsChart: [],
};

const updateSubforms = (
  forms: TaxDeclarationForm[],
  mainForm: TaxDeclarationForm,
  updatedSubForms: TaxDeclarationForm[] | undefined
) => [
  ...forms.filter((form) => form.id !== mainForm.id),
  { ...mainForm, subForms: updatedSubForms },
];

const reducer = (
  state: TaxDeclarationViewState = initialTaxViewState,
  action: TaxDeclarationViewActions | GlobalActions
): TaxDeclarationViewState => {
  switch (action.type) {
    case RESET_TAX_DECLARATION_VIEW_STATE:
    case RESET_UNSAFE_CLIENT_DATA:
      return initialTaxViewState;

    case ADD_TAX_DECLARATION_YEAR: {
      return {
        ...state,
        years: {
          [action.financialYear]: {
            forms: [],
            definitions: {},
          },
          ...state.years,
        },
      };
    }
    case DELETE_TAX_DECLARATION_FORM: {
      const { financialYear, id } = action;
      if (!financialYear || !id) return state;

      const yearToDeleteFrom = state.years[financialYear];
      const mainForms = yearToDeleteFrom?.forms || [];
      if (!yearToDeleteFrom || mainForms.length === 0) return state;

      const formData = { ...state.formData };
      delete formData[id];

      const isMainOrOtherForm = !!mainForms.find((form) => form.id === id);
      if (isMainOrOtherForm) {
        const formToDelete = mainForms.find((form) => form.id === id);
        let newForms: TaxDeclarationForm[] = [];
        if (formToDelete?.type !== 'SKV-2001') {
          newForms = mainForms.filter((form) => form.id !== id);
        }
        return {
          ...state,
          years: {
            ...state.years,
            [financialYear]: {
              ...yearToDeleteFrom,
              forms: newForms,
            },
          },
          formData,
        };
      }

      const { definitions } = yearToDeleteFrom;
      const mainForm = definitions
        ? mainForms.find((form) => definitions[form.type]?.category === 'main')
        : undefined;
      if (!mainForm) return state;

      const newSubForms = mainForm.subForms
        ?.filter((form) => form.id !== id)
        // the form to remove might also be a helper form which basically
        // is a subform of a subform
        .map((f) => ({
          ...f,
          subForms: f.subForms?.filter((sf) => sf.id !== id),
        }));

      return {
        ...state,
        years: {
          ...state.years,
          [financialYear]: {
            ...state.years[financialYear],
            forms: updateSubforms(mainForms, mainForm, newSubForms),
          },
        },
        formData,
      };
    }
    case VIEW_TAX_DECLARATION_FORM: {
      return {
        ...state,
        viewFormId: action.formId,
      };
    }
    case SET_TAX_DECLARATION_FORM_STATUS: {
      const { formId, financialYear, status: newStatus } = action;

      const year = state.years[financialYear];
      if (!year) return state;

      const updateFormStatus = (forms) =>
        forms.map((form) => {
          if (form.id === formId) {
            // Found the form, update its status
            return {
              ...form,
              status: newStatus,
            };
          }

          // If the form has subForms, recursively update them
          if (form.subForms && form.subForms.length > 0) {
            return {
              ...form,
              subForms: updateFormStatus(form.subForms),
            };
          }

          // Return the form unchanged
          return form;
        });

      // Update the forms for the specified year
      const updatedForms = updateFormStatus(year.forms);

      return {
        ...state,
        years: {
          ...state.years,
          [financialYear]: {
            ...year,
            forms: updatedForms,
          },
        },
      };
    }

    case SET_TAX_DECLARATION_FORM_DESCRIPTION: {
      const { formId, financialYear, description: newDescription } = action;

      const year = state.years[financialYear];
      if (!year) return state;

      const mainOrOtherForm = year.forms.find((form) => form.id === formId);
      if (mainOrOtherForm) {
        return {
          ...state,
          years: {
            ...state.years,
            [financialYear]: {
              ...state.years[financialYear],
              forms: [
                ...year.forms.filter((form) => form.id !== mainOrOtherForm.id),
                { ...mainOrOtherForm, description: newDescription },
              ],
            },
          },
        };
      }

      const { definitions } = year;
      const mainForm = definitions
        ? year.forms.find((form) => definitions[form.type]?.category === 'main')
        : undefined;
      if (!mainForm) return state;

      const subForms = mainForm.subForms ? mainForm.subForms : [];
      const formToUpdate = subForms.find((form) => form.id === formId);

      if (formToUpdate) {
        const updatedSubForms = subForms.map((form) => {
          return {
            ...form,
            description: formId === form.id ? newDescription : form.description,
          };
        });

        return {
          ...state,
          years: {
            ...state.years,
            [financialYear]: {
              ...state.years[financialYear],
              forms: updateSubforms(year.forms, mainForm, updatedSubForms),
            },
          },
        };
      }

      return state;
    }
    case SET_TAX_DECLARATION_FORMS: {
      const { forms, financialYear } = action;

      return {
        ...state,
        years: {
          ...state.years,
          [financialYear]: { ...state.years[financialYear], forms },
        },
      };
    }
    case SET_TAX_DECLARATION_DEFS: {
      const { definitions, financialYear } = action;

      const year = state.years[financialYear];
      if (!year) {
        return state;
      }

      return {
        ...state,
        years: {
          ...state.years,
          [financialYear]: {
            ...year,
            definitions: definitions.reduce(
              (defs, def) => ({ ...defs, [def.type]: def }),
              {}
            ),
          },
        },
      };
    }
    case SET_TAX_DECLARATION_CONTENT_DEF: {
      const { content, financialYear } = action;

      const year = state.years[financialYear];
      if (!year) {
        return state;
      }

      const { definitions } = year;
      if (!definitions) {
        return state;
      }

      const definition = Object.values(definitions).find(
        (def) => def?.id === content.id
      );
      if (!definition) {
        return state;
      }

      return {
        ...state,
        years: {
          ...state.years,
          [financialYear]: {
            ...year,
            definitions: {
              ...definitions,
              [definition.type]: {
                ...definition,
                parts: content.parts,
              },
            },
          },
        },
      };
    }
    case SET_TAX_DECLARATION_CONTENT_DEF_BULK: {
      const { financialYear, definitions: actionDefinitions } = action;

      const year = state.years[financialYear];
      if (!year) {
        return state;
      }

      const { definitions } = year;
      if (!definitions) {
        return state;
      }

      const newDefinitions = actionDefinitions.reduce(
        (newDefs, actionDefinition) => {
          const definition = Object.values(definitions).find(
            (def) => def?.id === actionDefinition.id
          );
          return definition
            ? {
                ...newDefs,
                [definition.type]: {
                  ...definition,
                  parts: actionDefinition.parts,
                },
              }
            : newDefs;
        },
        {}
      );

      return {
        ...state,
        years: {
          ...state.years,
          [financialYear]: {
            ...year,
            definitions: {
              ...definitions,
              ...newDefinitions,
            },
          },
        },
      };
    }
    case SET_TAX_DECLARATION_FORM_STATE: {
      const { formId, formData } = action;

      return {
        ...state,
        formData: {
          ...state.formData,
          [formId]: { ...formData },
        },
      };
    }
    case UPDATE_TAX_DECLARATION_FORM_DATA: {
      const { formId, document } = action;

      const theForm = state.formData[formId];
      if (!theForm) {
        return state;
      }

      return {
        ...state,
        formData: {
          ...state.formData,
          [formId]: { ...theForm, document },
        },
      };
    }
    case RESET_TAX_DECLARATION_TABLE: {
      const selectedFormId = state.viewFormId;
      if (!selectedFormId) return state;
      const { id, partId } = action;
      const oldFormData = _.cloneDeep(state.formData);

      _.set(oldFormData, `${selectedFormId}.document.${partId}.${id}.rows`, []);

      const formData = oldFormData[selectedFormId];
      if (!formData) {
        return state;
      }
      const result = operations.addTableRow(
        formData.definition,
        { document: formData.document, changes: formData.changes },
        `${partId}.${id}`
      );

      if (!result || isError(result)) {
        console.warn(action, result);
        return state;
      }
      _.set(result, `changes.${partId}.${id}`, null);
      _.set(result, `document.${partId}.${id}.rows.0.id`, '1');

      return {
        ...state,
        formData: {
          ...state.formData,
          [selectedFormId]: {
            ...formData,
            document: result.document as TaxDeclarationFormData,
            changes: result.changes,
          },
        },
      };
    }
    case UPDATE_TAX_DECLARATION_FORM_FIELD: {
      return updateTaxDeclarationFormField(state, action);
    }
    case RESET_TAX_DECLARATION_FORM_FIELD: {
      return resetTaxDeclarationFormField(state, action);
    }
    case UPDATE_TAX_DECLARATION_FORM_EXTERNAL_FIELD: {
      return updateTaxDeclarationFormExternalField(state, action);
    }
    case ADD_TAX_DECLARATION_YEAR_STATUS: {
      const { financialYear, status } = action;
      const year = state.years[financialYear];
      if (!year) {
        return state;
      }
      const statuses = year.status || [];
      return {
        ...state,
        years: {
          ...state.years,
          [financialYear]: {
            ...year,
            status: [status, ...statuses],
          },
        },
      };
    }
    case SET_TAX_DECLARATION_YEAR_STATUSES: {
      const { financialYear, status } = action;
      const year = state.years[financialYear];
      if (!year) {
        return state;
      }

      return {
        ...state,
        years: {
          ...state.years,
          [financialYear]: {
            ...year,
            status,
          },
        },
      };
    }

    case SET_TAX_DECLARATION_DOCUMENTS: {
      const { documents, financialYear } = action;
      const year = state.years[financialYear];
      if (!year) {
        return state;
      }

      return {
        ...state,
        years: {
          ...state.years,
          [financialYear]: { ...year, documents },
        },
      };
    }

    case SET_TAX_CONNECTIONS_CHART: {
      const { connectionsChart, financialYear } = action;
      const year = state.years[financialYear];
      if (!year) {
        return state;
      }
      return {
        ...state,
        years: {
          ...state.years,
          [financialYear]: { ...year, connectionsChart },
        },
      };
    }

    case SET_TAX_OWNERS: {
      const { owners, financialYear } = action;
      const year = state.years[financialYear];
      if (!year) {
        return state;
      }
      return {
        ...state,
        years: {
          ...state.years,
          [financialYear]: { ...year, owners },
        },
      };
    }

    case DELETE_TAX_DECLARATION_YEAR: {
      const { financialYear } = action;
      if (!financialYear) return state;

      const newState = { ...state };
      delete newState.years[action.financialYear];
      return newState;
    }

    case SET_TAX_DECLARATION_YEAR_WITH_INFO: {
      const { forms, documents, definitions, connectionsChart } = action;
      return {
        ...state,
        years: {
          [action.financialYear]: {
            forms,
            documents,
            definitions: definitions.reduce(
              (defs, def) => ({ ...defs, [def.type]: def }),
              {}
            ),
            connectionsChart,
          },
          ...state.years,
        },
      };
    }

    case SET_TAX_DECLARATION_FORM_QUALITY_CHECK: {
      const { formId, financialYear, checked: checkedValue } = action;

      const year = state.years[financialYear];
      if (!year) return state;

      const updateFormChecked = (forms) =>
        forms.map((form) => {
          if (form.id === formId) {
            return { ...form, checked: checkedValue };
          }
          if (form.subForms && form.subForms.length > 0) {
            return { ...form, subForms: updateFormChecked(form.subForms) };
          }
          return form;
        });

      const updatedForms = updateFormChecked(year.forms);

      return {
        ...state,
        years: {
          ...state.years,
          [financialYear]: {
            ...year,
            forms: updatedForms,
          },
        },
      };
    }

    case UPDATE_TAX_DECLARATION_TABLE_ROW_CELL: {
      return updateTaxDeclarationFormTableCell(state, action);
    }

    case ADD_TAX_DECLARATION_TABLE_ROW: {
      return addTaxDeclarationFormTableRow(state, action);
    }

    case DELETE_TAX_DECLARATION_TABLE_ROW: {
      return deleteTaxDeclarationFormTableRow(state, action);
    }

    case TOGGLE_TAX_DECLARATION_TABLE_ROW_ACTIVE: {
      return toggleTaxDeclarationFormTableRowActive(state, action);
    }

    case UPDATE_TAX_DECLARATION_TABLE_ROWS: {
      return updateTaxDeclarationFormTableRows(state, action);
    }

    default:
      return state;
  }
};

export default reducer;
