import React, { useContext } from 'react';
import styled from '@emotion/styled';

import { useSelector } from 'redux/reducers';
import { currentClient } from '_reconciliation/redux/accounting-view/selectors';
import { TaxSpecificationsAccountGroup } from '@agoy/api-sdk-core';
import GroupsSpecificationInfo from '_shared/components/GroupsSpecificationInfo';
import { PeriodDocument } from 'utils/usePeriodDocuments/Provider';
import {
  BalanceHistoryItemType,
  CheckedHistoryItemType,
  EnableForPrint,
} from '_shared/components/GroupsSpecificationInfo/types';
import { Comment } from '_shared/types';
import { parse } from '@agoy/dates';
import { ActualBalances } from '_reconciliation/components/ReconciliationView/types';
import { MovedAccountsByPeriods } from '_reconciliation/components/ReconciliationView/RowContext/types';

import TaxesDataContext from '../../../service/TaxesDataContext';
import { ExternCommentsRecord } from './types';

const Section = styled.section`
  @media print {
    page-break-inside: avoid;
    page-break-after: always;
    max-width: 1280px;
    margin: 0;
    border: none;
    border-radius: 0;
  }
`;

type GroupsTabProps = {
  part: string | undefined;
  groups: TaxSpecificationsAccountGroup[];
  accountDocuments: PeriodDocument[];
  balanceChangeHistory: BalanceHistoryItemType[];
  checkedChangeHistory: CheckedHistoryItemType[];
  actualBalances: ActualBalances;
  internalComments: Comment[];
  externComments: ExternCommentsRecord;
  movedAccountsByPeriods: MovedAccountsByPeriods;
  isPrint?: boolean;
  enableForPrint?: EnableForPrint;
  onSwitchVisibleForPrint?: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
};

const inInterval = (
  intervalStart: number,
  intervalEnd: number,
  account: number
) => {
  return intervalStart <= account && account <= intervalEnd;
};

const getMovedAccountGroupIdForCurrentPeriod = (
  account: number,
  periodStart: string,
  movedAccountsByPeriods: MovedAccountsByPeriods
) => {
  let groupId = '';
  Object.entries(movedAccountsByPeriods[account]).forEach(
    ([movedPeriodDate, value]) => {
      if (parse(movedPeriodDate) <= parse(periodStart)) {
        groupId = value;
      }
    }
  );
  return groupId;
};

export const getGroupDocuments = (
  group: TaxSpecificationsAccountGroup,
  periodStart: string,
  documents: PeriodDocument[],
  movedAccountsByPeriods: MovedAccountsByPeriods
) => {
  const [groupsStart, groupEnd] = group.id.split('_').map((item) => +item);

  return documents.filter((document) => {
    const referencedDocument = document?.references?.find((ref) => {
      if (movedAccountsByPeriods[+ref.key]) {
        return (
          getMovedAccountGroupIdForCurrentPeriod(
            +ref.key,
            periodStart,
            movedAccountsByPeriods
          ) === group.id
        );
      }
      return (
        inInterval(groupsStart, groupEnd, +ref.key) || ref.key === group.id
      );
    });
    return referencedDocument;
  });
};

const GroupsTab = ({
  enableForPrint,
  part,
  groups,
  accountDocuments,
  balanceChangeHistory,
  checkedChangeHistory,
  actualBalances,
  externComments,
  internalComments,
  movedAccountsByPeriods,
  isPrint = false,
  onSwitchVisibleForPrint,
}: GroupsTabProps): JSX.Element | null => {
  const { financialYearObj, financialYear, periodObj } =
    useContext(TaxesDataContext);

  const accountsWithBalances = useSelector(
    currentClient(
      (state) => state.years[financialYear]?.accountingBalances?.accounts
    )
  );

  if (!accountsWithBalances) {
    return null;
  }

  return (
    <>
      {groups.map((group) => {
        const [groupsStart, groupEnd] = group.id
          .split('_')
          .map((item) => +item);

        const accountBalanceHistory = balanceChangeHistory.filter((history) =>
          inInterval(groupsStart, groupEnd, +history.account)
        );
        const accountCheckedHistory = checkedChangeHistory.find(
          (history) =>
            inInterval(groupsStart, groupEnd, +history.account) &&
            history.checked
        );
        const isGroupCheckedHistory =
          Object.keys(actualBalances).filter(
            (accountNumber) =>
              inInterval(groupsStart, groupEnd, +accountNumber) &&
              actualBalances[accountNumber].final?.checked
          ).length === group.rows.length;

        const documents = getGroupDocuments(
          group,
          periodObj.start,
          accountDocuments,
          movedAccountsByPeriods
        );

        const externCommentsForGroup = externComments[group.id];

        return (
          <Section id={`group${group.id}`} key={`${periodObj.id}.${group.id}`}>
            <GroupsSpecificationInfo
              allInternalComments={internalComments}
              print={isPrint}
              balanceHistory={accountBalanceHistory}
              checkedHistory={
                isGroupCheckedHistory ? accountCheckedHistory : undefined
              }
              group={group}
              accounts={accountsWithBalances}
              enableForPrint={enableForPrint}
              financialYear={financialYearObj}
              period={periodObj}
              part={part}
              documents={documents}
              externalComment={
                externCommentsForGroup?.printComment
                  ? externCommentsForGroup?.comment
                  : null
              }
              onSwitch={onSwitchVisibleForPrint}
            />
          </Section>
        );
      })}
    </>
  );
};

export default GroupsTab;
