import React from 'react';
import { useIntl } from 'react-intl';
import { Box, Typography } from '@material-ui/core';

import { useSelector } from 'redux/reducers';
import { formatEndOfMonth, reformat } from '@agoy/dates';
import { Period } from '@agoy/api-sdk-core';

type HeaderProps = {
  period: Period;
  financialYear: string;
  clientId: string;
};

const Header = ({ period, financialYear, clientId }: HeaderProps) => {
  const { formatMessage } = useIntl();

  const { orgNumber, name } = useSelector((state) => state.customers[clientId]);

  const formattedFinancialYear = financialYear
    ?.split('-')
    .map((item) => reformat(item, 'yyyyMMdd', 'yyyy-MM-dd'))
    .join(' - ');

  return (
    <div>
      <Typography variant="h2">{name}</Typography>
      <Typography component="div">
        {formatMessage({
          id: 'print.periodSpecification.organisationNumber',
        })}
        <Box component="span" paddingLeft={1} fontWeight="fontWeightBold">
          {orgNumber}
        </Box>
      </Typography>
      <Typography component="div">
        {formatMessage({ id: 'print.periodSpecification.closingDate' })}
        <Box component="span" paddingLeft={1} fontWeight="fontWeightBold">
          {formatEndOfMonth(period.start, 'yyyy-MM-dd')}
        </Box>
      </Typography>
      <Typography>
        {formatMessage({ id: 'financialyear' })}
        <Box component="span" paddingLeft={1} fontWeight="fontWeightBold">
          {formattedFinancialYear}
        </Box>
      </Typography>
    </div>
  );
};

export default Header;
