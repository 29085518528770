import React, { useContext } from 'react';
import PrintStateContext from '_shared/components/PrintedDocument/PrintStateContext';
import PreviewVoucherContent from './Preview/PreviewVoucherContent';

const VoucherPrint = () => {
  const {
    state: { voucherData },
  } = useContext(PrintStateContext);

  if (!voucherData) {
    return null;
  }

  return <PreviewVoucherContent {...voucherData} />;
};

export default VoucherPrint;
