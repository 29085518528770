import React, { forwardRef, InputHTMLAttributes, useCallback } from 'react';
import {
  booleanValue,
  Cell,
  Field,
  isBooleanCell,
  isReferenceCell,
  numberValue,
  stringValue,
} from '@agoy/document';
import { Field as FormField } from '@agoy/tax-declaration-document';
import {
  Input as InputField,
  TextArea,
} from '_shared/components/Inputs/v2/Input';
import { useDispatch } from 'react-redux';
import { updateTaxDeclarationFormField } from 'redux/actions';
import EditField from '_shared/components/Inputs/EditField';
import CurrencyField from '_shared/components/Inputs/CurrencyField';
import { getClasses } from '@agoy/common';
import Checkbox from '_shared/components/Controls/Checkbox';
import When from '_shared/components/When/When';
import Typography from '_shared/components/Typography/Typography';
import AutosizeTextArea from '_shared/components/Inputs/v2/AutosizeTextarea';
import {
  TaxInputContainerStyle,
  ChangedBackdrop,
  TaxCheckboxInputContainerStyle,
  InputContainer,
} from './InputContainers';

// this fields are primarily dates
// and should be of a date type in config when implemented
const fieldsWithoutDash = [
  'accountingYearFrom',
  'accountingYearTo',
  'A_datum_overlatelsen',
  'dateOfSharesSold',
  'B_datum_bosattning',
  'C_datum_for_bosattning',
  'date',
  'datum',
  'A_fromDatum',
  'A_tomDatum',
  'C_fromDatum',
  'C_toDatum',
  'A_fr_o_m',
  'A_t_o_m',
  'C_bosatt_i_bostadsratten_fr_o_m',
  'C_bosatt_i_bostadsratten_t_o_m',
  'E_datum_for_bosattning',
  'F_datum_for_bosattning',
  'C_bosatt_from',
  'C_bosatt_tom',
  'F_datum_bosattning',
  'A_forsaljningsdatumKopekontrakt',
  'A_inkopsdatumKopekontrakt',
  'A_bostadsrattsforeningens_organisationsnummer',
  'A_lagenhetsbeteckning_hos_bostadsrattsforeningen',
  'A_forsaljningsdatum',
  'A_inkopssdatum',
  'A_inkopsdatum',
  'A_forsaljningsdatum_enligt_kopekontrakt',
  'A_inkopsdatum_enligt_kopekontrakt',
  'E_inkopsdatum_enligt_kopekontrakt',
  'F_inkopsdatum_enligt_kopekontrakt',
];

const valueWithoutDash = (
  id: string,
  value: string | number | boolean | undefined
): string | number | boolean | undefined => {
  if (typeof value === 'string' && fieldsWithoutDash.includes(id)) {
    return value.replace(/-/g, '');
  }
  return value;
};

interface FieldInputProps
  extends InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
  fieldId: string;
  field: FormField;
  label?: string;
  multiline?: boolean;
  changed?: boolean;
}

const FieldInput = forwardRef<
  HTMLInputElement | HTMLTextAreaElement,
  FieldInputProps
>(({ fieldId, field, className, changed, multiline, label, ...rest }, ref) => (
  <InputContainer>
    <When isTrue={!!label}>
      <Typography htmlFor={fieldId} variant="body2" as="label" margin="none">
        {label}
      </Typography>
    </When>
    <TaxInputContainerStyle className={className} field={field}>
      <When isTrue={!!changed}>
        <ChangedBackdrop />
      </When>
      <When isTrue={!!multiline}>
        <AutosizeTextArea
          as={TextArea}
          id={fieldId}
          ref={ref as React.Ref<HTMLTextAreaElement>}
          {...rest}
        />
      </When>
      <When isTrue={!multiline}>
        <InputField ref={ref as React.Ref<HTMLInputElement>} {...rest} />
      </When>
    </TaxInputContainerStyle>
  </InputContainer>
));

export interface InputProps {
  partId: string;
  fieldId: string;
  cell: Cell | Field;
  field?: FormField;
  label?: string;
  isTitle?: boolean;
  multiline?: boolean;
  className?: string;
}

const Input = ({
  partId,
  cell,
  field,
  fieldId,
  isTitle,
  multiline,
  label,
  className,
}: InputProps) => {
  const dispatch = useDispatch();

  const onFieldChange = useCallback(
    async (value) => {
      dispatch(
        updateTaxDeclarationFormField(
          partId,
          fieldId,
          valueWithoutDash(fieldId, value)
        )
      );

      // update connected cell only if value = true to maintain the ability to uncheck
      if (isBooleanCell(cell) && cell.oppositeCellId && value) {
        dispatch(
          updateTaxDeclarationFormField(
            partId,
            cell.oppositeCellId,
            valueWithoutDash(cell.oppositeCellId, false)
          )
        );
      }
    },
    [dispatch, partId, fieldId, cell]
  );

  const fieldType = field?.type ?? cell.type;

  if (fieldType === 'number') {
    const unitAndTypeSource = cell.original ? cell.original : cell;
    const unit =
      unitAndTypeSource.type === 'number' || unitAndTypeSource.type === 'ref'
        ? unitAndTypeSource.unit
        : undefined;

    return (
      <CurrencyField
        part={partId}
        fieldId={fieldId}
        onValueChange={onFieldChange}
        editingDecimals={2}
        Input={FieldInput}
        InputProps={{
          fieldId,
          field,
          changed: !!cell.original && cell.original.type === 'ref',
          id: `${partId}.${fieldId}`,
        }}
        value={numberValue(cell)}
        className={getClasses({
          number: true,
          reference: isReferenceCell(unitAndTypeSource),
          absolute: !!field,
          hideBackground: !!field?.hideBackground,
        })}
        unit={unit}
      />
    );
  }

  if (fieldType === 'text' || fieldType === 'string') {
    return (
      <EditField
        value={stringValue(cell)}
        onChange={onFieldChange}
        component={FieldInput}
        InputProps={{
          field,
          multiline,
          label,
          fieldId,
          id: `${partId}.${fieldId}`,
          changed: !!cell.original && cell.original.type === 'ref',
        }}
        className={`${getClasses({
          absolute: !!field,
          title: !!isTitle,
          hideBackground: !!field?.hideBackground,
        })} ${className}`}
      />
    );
  }

  return (
    <TaxCheckboxInputContainerStyle
      field={field}
      className={getClasses({ absolute: !!field })}
    >
      <Checkbox
        type="tax"
        checked={booleanValue(cell)}
        onChange={onFieldChange}
      />
    </TaxCheckboxInputContainerStyle>
  );
};

export default Input;
