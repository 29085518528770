import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useContext,
} from 'react';
import { useIntl } from 'react-intl';
import {
  Box,
  Switch,
  FormControlLabel,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import ResetIcon from '@material-ui/icons/Restore';
import PrintIcon from '@material-ui/icons/Print';
import styled from '@emotion/styled';

import { ccyFormat } from '@agoy/common';
import { useSelector } from 'redux/reducers';
import { TaxCalculationRow, TaxCalculationPart } from '@agoy/tax-document';
import ResetContentDialog from '_shared/components/ResetContent/ResetContentDialog';
import EditingIconButton from '_annual-report/components/AnnualReportView/UI/EditingIconButton';
import { currentClient } from '_reconciliation/redux/accounting-view/selectors';
import Typography from '_shared/components/Typography/Typography';
import { reformatDate } from 'utils';

import LoadingLogo from '_shared/components/LoadingLogo';
import TaxTableRow from './TaxTableRow';
import AddTableRow from './AddTableRow';
import Header from './Header';
import TaxesDataContext from '../../service/TaxesDataContext';

export interface TaxTableProps {
  part: TaxCalculationPart;
  additionalRows?: TaxCalculationRow[];
  showAllOption?: boolean;
  mainTable?: boolean;
  warning?: string;
  startContent?: React.ReactElement;
  disableEditing?: boolean;
  onReset?: () => void;
  onPrint?: () => Promise<void> | void;
  print?: boolean;
  printable?: boolean;
}

const Warning = styled.div`
  font-size: 16px;
  color: ${(props) => props.theme.palette.error.main};
  margin-left: ${(props) => props.theme.spacing(2)}px;
`;

const PrintButton = styled(IconButton)`
  &:hover {
    color: ${(props) => props.theme.palette.secondary.main};
  }
`;

const showAccountSelector = (part: TaxCalculationPart) => {
  return (
    part === 'nonTaxableIncomes' ||
    part === 'nonDeductibleExpenses' ||
    part === 'particularSalaryTax'
  );
};

const TaxTable = ({
  part,
  additionalRows,
  showAllOption = false,
  mainTable = false,
  warning,
  startContent,
  disableEditing = false,
  onReset,
  onPrint,
  print,
  printable,
}: TaxTableProps): JSX.Element => {
  const { formatMessage } = useIntl();

  const { taxYearData, service, financialYear, isLastPeriod } =
    useContext(TaxesDataContext);

  const periods = useSelector(
    currentClient(
      (state) => state.years[financialYear]?.accountingBalances?.periods
    )
  );

  const taxTable = taxYearData.state?.[part];
  const taxConfig = taxYearData.config;

  const [showAll, setShowAll] = useState(!showAllOption);
  const [editing, setEditing] = useState(false);
  const [printLoading, setPrintLoading] = useState(false);

  const toggleEditing = useCallback(() => {
    setEditing((value) => !value);
  }, [setEditing]);

  const toggleShowAll = useCallback(() => {
    setShowAll((value) => !value);
  }, [setShowAll]);

  const config = useMemo(
    () =>
      taxConfig && periods
        ? {
            salaryTax: `${ccyFormat(taxConfig.salaryTax * 100, 2)}%`,
            companyTax: `${ccyFormat(taxConfig.companyTax * 100, 2)}%`,
            templateTax: `${ccyFormat(taxConfig.templateTax * 100, 2)}%`,
            endingDate: reformatDate(
              periods[periods.length - 1].end,
              'yyyy-MM-dd',
              'yy-MM-dd'
            ),
          }
        : ({} as Record<string, string>),
    [taxConfig, periods]
  );

  const [open, setOpen] = useState(false);

  const onConfirm = () => {
    if (!onReset) return;
    onReset(); // can only be ran if its not undefined
    setOpen(false);
  };

  const deleteRow = useCallback(
    (rowId: string) => {
      service.deleteTaxTableRow(part, rowId);
    },
    [part, service]
  );

  const updateLabel = useCallback(
    (rowId: string, label: string) => {
      const labelRows = rows?.map((row) => row?.label);
      if (labelRows?.includes(label)) return;
      service.updateTaxTableRowLabel(part, rowId, label);
    },
    [part, service]
  );

  const updateValue = useCallback(
    (rowId: string, value: number) => {
      if (rowId === 'deficitDeduction') {
        service.updateTaxTableRowReference(part, rowId, 'Underskottsavdrag');
      }

      if (rowId === 'calculationAccrual') {
        service.updateTaxTableRowReference(
          part,
          rowId,
          'Uppräkning periodiseringsfond'
        );
      }
      service.updateTaxTableRowValue(part, rowId, value);
    },
    [part, service]
  );

  const handleAddRow = useCallback(() => {
    service.addEmptyTaxTableRow(part, `@${Date.now()}`);
  }, [part, service]);

  useEffect(() => {
    if (print) {
      setTimeout(() => window.print(), 100);
    }
  }, [print]);

  const tableSelectedAccounts = useMemo(() => {
    if (taxTable) {
      const selectedAccounts = taxTable.rows?.map((row) => row.label as string);
      return selectedAccounts;
    }
    return [];
  }, [taxTable]);

  if (!taxTable) {
    return <></>;
  }

  const shouldDisplayRow = (row: any) => {
    // NOTE: we show 'Uppräkning periodiseringsfond' only in the last month of the year
    const rowsToShowIfLastPeriod = [
      'calculationAccrual', // Uppräkning periodiseringsfond
    ];

    if (row.deleted) {
      return false;
    }

    if (rowsToShowIfLastPeriod.includes(row.id)) {
      return isLastPeriod;
    }

    //  NOTE: we hide 'Uppräkning periodiseringsfond' field if its value equals to zero, even in the last period;
    if (row.id === 'calculationAccrual' && row.value === 0) {
      return false;
    }
    if (row.id === 'initialDeficitDeduction' || row.id === 'isDeficitChanged') {
      return false;
    }

    return true;
  };

  const handlePrint = async () => {
    if (!onPrint) return;
    setPrintLoading(true);
    await onPrint();
    setPrintLoading(false);
  };

  const { rows, sum } = taxTable;

  return (
    <>
      <Header
        mainTable={mainTable}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={2}
      >
        <Typography variant="h3">
          {formatMessage({ id: `tax.${part}.title` })}
        </Typography>
        {!print && printable && (
          <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
            {showAllOption && !print && (
              <Box paddingRight={1}>
                <FormControlLabel
                  control={
                    <Switch
                      onClick={toggleShowAll}
                      checked={showAll}
                      color={showAll ? 'secondary' : 'primary'}
                    />
                  }
                  label={formatMessage({ id: 'tax.showAll' })}
                  labelPlacement="start"
                />
              </Box>
            )}
            {!disableEditing && !print && (
              <EditingIconButton value={editing} onClick={toggleEditing} />
            )}
            {onReset && !print && (
              <>
                <Tooltip
                  title={formatMessage({ id: 'tax.table.reset.tooltip' })}
                >
                  <IconButton
                    onClick={() => {
                      setOpen(true);
                    }}
                  >
                    <ResetIcon />
                  </IconButton>
                </Tooltip>
                <ResetContentDialog
                  what={formatMessage({ id: `tax.${part}.title` })}
                  open={open}
                  onClose={() => {
                    setOpen(false);
                  }}
                  onConfirm={onConfirm}
                />
              </>
            )}
            <PrintButton onClick={handlePrint} disabled={printLoading}>
              {printLoading ? <LoadingLogo size="tiny" /> : <PrintIcon />}
            </PrintButton>
          </Box>
        )}
      </Header>
      {startContent}
      <Box p={2}>
        {(rows as any[])?.filter(shouldDisplayRow)?.map((row) => {
          const useAccountSelector =
            showAccountSelector(part) && !row.reference && !disableEditing;
          return (
            <TaxTableRow
              key={row.id}
              row={row}
              editing={editing && !disableEditing}
              part={part}
              config={config}
              hideEmpty={!showAll}
              onDelete={deleteRow}
              onChangeLabel={!disableEditing ? updateLabel : undefined}
              onChangeValue={
                (row.reference &&
                  row.id !== 'deficitDeduction' &&
                  row.id !== 'calculationAccrual') ||
                disableEditing
                  ? undefined
                  : updateValue
              }
              selectAccount={useAccountSelector}
              tableSelectedAccounts={tableSelectedAccounts}
            />
          );
        })}
        {!disableEditing && editing && !print && (
          <AddTableRow onAddRow={handleAddRow} />
        )}
        <TaxTableRow
          row={sum}
          editing={false}
          part={part}
          config={config}
          hideEmpty={false}
        />
        {additionalRows?.map((row) => (
          <TaxTableRow
            key={row.id}
            row={row}
            editing={false}
            part={part}
            config={config}
            hideEmpty={false}
          />
        ))}
        {warning && <Warning>{warning}</Warning>}
      </Box>
    </>
  );
};

export default TaxTable;
