/**
 * Checks if the given value has a property and makes that visible
 * to typescript.
 *
 * @param obj any object
 * @param prop name of property
 * @returns the `obj`
 */
function hasOwnProperty<X extends {}, Y extends PropertyKey>(
  obj: X,
  prop: Y
): obj is X & Record<Y, unknown> {
  return obj !== null && prop in obj;
}

export default hasOwnProperty;
