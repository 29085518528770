import { Field } from '..';
import { Source } from '../types/source';
import { ResolvedReference } from '../References/types';

type BaseCell = {
  /**
   * source, indicates that the information comes from client information.
   */
  source?: Source;
  tooltip?: string;
};

export type LabelCell = BaseCell & {
  original?: Cell;
  type: 'label';
  value: string;
  align?: string;
};

export type FormatMessageCell = BaseCell & {
  original?: Cell;
  type: 'msg';
  message: string;
  value: ResolvedReference;
  align?: string;
  /**
   * parameterReferences map parameter names to references, the resolved value
   * can then be used as a parameter to the format string
   */
  parameterReferences?: Record<string, string>;
  parameterValues?: Record<string, ResolvedReference>;
};

export type ReferenceCell = BaseCell & {
  original?: Cell;
  type: 'ref';
  reference: string;
  value: ResolvedReference;
  align?: string;
  unit?: string;
  warning?: ReferenceCell;
};

export type MultiReferenceCell = BaseCell & {
  original?: Cell;
  type: 'refs';
  references: string[];
  values: ResolvedReference[];
  align?: string;
};

export type StringCell = BaseCell & {
  original?: Cell;
  type: 'string';
  value: string;
  align?: string;
  format?: StringCellFormat;
  unit?: string;
};

type StringCellFormat = SelectFormat;

export type SelectFormat = {
  type: 'select';
  options: Option[];
};

export type Option = {
  value: string;
  label: string;
  options?: Option[];
  ranges?: string;
  action?: string;
};

export type NumberCell = BaseCell & {
  original?: Cell;
  type: 'number';
  value: number | undefined;
  align?: string;
  unit?: string;
  warning?: ReferenceCell;
};

export type BooleanCell = BaseCell & {
  original?: Cell;
  type: 'boolean';
  value: boolean;
  align?: string;
  oppositeCellId?: string; // oppositeCellId is used to track the connection between two opposite cells
};

export type Cell =
  | LabelCell
  | ReferenceCell
  | MultiReferenceCell
  | StringCell
  | NumberCell
  | BooleanCell
  | FormatMessageCell;

export function isReferenceCell(cell: Cell): cell is ReferenceCell {
  return cell.type === 'ref';
}

export function isMultiReferenceCell(cell: Cell): cell is MultiReferenceCell {
  return cell.type === 'refs';
}

export function isNumberCell(cell: Cell): cell is NumberCell {
  return cell.type === 'number';
}

export function isStringCell(cell: Cell): cell is StringCell {
  return cell.type === 'string';
}

export function isSelectCell(cell: Cell): cell is StringCell {
  return cell.type === 'string' && cell.format?.type === 'select';
}

export function isFormatMessageCell(cell: Cell): cell is FormatMessageCell {
  return cell.type === 'msg';
}

export function isBooleanCell(cell: Cell): cell is BooleanCell {
  return cell.type === 'boolean';
}

export function isLabelCell(cell: Cell): cell is LabelCell {
  return cell.type === 'label';
}

export function isField(cell: Cell): cell is Field {
  return 'active' in cell;
}

export function isCellWithWarning(
  cell: Cell
): cell is Cell & { warning: ReferenceCell } {
  return 'warning' in cell && !!cell.warning;
}
