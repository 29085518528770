import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Period } from '@agoy/api-sdk-core';
import ConfirmationDialog from '_shared/components/Dialogs/ConfirmationDialog';
import { GetComponentProps } from '_shared/services/type-helpers';
import { DocumentPreview } from '_shared/components/UploadDocument';
import useYearDocuments from 'utils/useYearDocuments';

interface Document {
  category: string;
  name: string;
  url: string;
  lastModified: string;
  __typename: 'Document';
}

interface DocumentPreviewItemProps {
  id: number;
  period: Period;
  document: Document;
  onOpenPreviewModal: (id: number | string) => void;
  onDeleteComplete: () => void;
}

const DocumentPreviewItemYear = ({
  id,
  period,
  document,
  onOpenPreviewModal,
  onDeleteComplete,
}: DocumentPreviewItemProps): JSX.Element => {
  const { formatMessage } = useIntl();

  const [loading, setLoading] = useState(false);
  const [documentUploadError, setDocumentUploadError] = useState('');

  const { deleteYearDocument } = useYearDocuments();

  const [verifyDeleteModal, setVerifyDeleteModal] = useState<GetComponentProps<
    typeof ConfirmationDialog
  > | null>(null);

  const handleDeleteDocument = async () => {
    try {
      setLoading(true);

      setVerifyDeleteModal({
        text: formatMessage(
          { id: 'documents.confirm.delete' },
          { name: document.name }
        ),
        onAccept: async () => {
          await deleteYearDocument(document.name, document.category);
          onDeleteComplete();
        },
        onClose: () => {
          setVerifyDeleteModal(null);
        },
      });
      // await deleteDocument(document);
    } catch (error) {
      setDocumentUploadError(
        formatMessage({ id: 'hidden.errorDeletingImage' })
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {verifyDeleteModal && <ConfirmationDialog {...verifyDeleteModal} />}
      <DocumentPreview
        id={id}
        loading={loading}
        document={document}
        onDelete={handleDeleteDocument}
        onOpenPreviewModal={onOpenPreviewModal}
        errorMessage={documentUploadError}
        columnButtons
        editNameDisabled
      />
    </>
  );
};

export default DocumentPreviewItemYear;
