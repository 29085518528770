import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

import { parseFormat } from '@agoy/dates';
import VoucherForm from '_shared/components/VoucherView/CreateVoucherView';
import useVoucherForm from '_shared/components/VoucherView/useVoucherForm';
import {
  AccountPlanList,
  ReferencesTypes,
  Voucher,
  VoucherSeries,
  VoucherStatusType,
  VoucherTransaction,
} from 'types/Voucher';
import { useSelector } from 'redux/reducers';
import { isNonZeroValue } from 'utils';
import { addGlobalMessage } from 'redux/actions';
import { currentClient } from '_reconciliation/redux/accounting-view/selectors';
import TaxesDataContext from '_tax/service/TaxesDataContext';
import { TransactionRow } from '@agoy/tax-document';
import PageContent from './UI/PageContent';
import { getFullMonth } from './Adjustments/utils';

const DEFAULT_TRANSACTIONS: VoucherTransaction[] = [
  {
    id: 1,
    sourceInfo: 'Särskild löneskatt',
    kind: 'KS',
    typeAmount: 'credit',
    credit: 0,
    debit: 0,
    amount: 0,
    account: 7530,
  },
  {
    id: 2,
    sourceInfo: 'Särskild löneskatt',
    kind: 'KS',
    typeAmount: 'debit',
    credit: 0,
    debit: 0,
    amount: 0,
    account: 2514,
  },
];

type ParticularSalaryTaxVoucherProps = {
  title?: string;
  accountsList?: AccountPlanList[];
  seriesList?: VoucherSeries[];
  editing?: boolean;
  hideHeader?: boolean;
  print?: boolean;
  className?: string;
  onSetVoucherStatus?: (
    reference: ReferencesTypes,
    status: VoucherStatusType
  ) => void;
  onSetVoucherNumbers?: (reference: ReferencesTypes, series: string) => void;
};

const rowFilter = (row: TransactionRow) => {
  return (
    (isNonZeroValue(row.value) || !row.reference) &&
    !row.deleted &&
    (row.account === '7530' || row.account === '2514')
  );
};

const ParticularSalaryTaxVoucher = ({
  title = 'Bokslutsverifikation',
  accountsList = [],
  seriesList = [],
  editing = false,
  hideHeader = false,
  print = false,
  className,
  onSetVoucherStatus,
  onSetVoucherNumbers,
}: ParticularSalaryTaxVoucherProps) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const [localEditing, setLocalEditing] = useState(false);

  const {
    financialYear,
    taxYearData,
    financialYearObj,
    clientId,
    period,
    isLastPeriod,
  } = useContext(TaxesDataContext);

  const {
    voucherNumber,
    description,
    transactions,
    selectedSeries,
    date,
    documents,
    voucherStatus,
    nextVoucherNumber,
    minPeriodDate,
    maxPeriodDate,
    disabledDate,
    onChangeSeries,
    onChangeDescription,
    onCreateVoucher,
    onChangeDate,
    onReset,
    setTransactions,
    onAddTransaction,
    onRemoveTransaction,
    onUpdateTransaction,
    onChangeDocuments,
    onAddDocument,
    onDeleteVoucher,
  } = useVoucherForm({
    clientId,
    financialYear: financialYearObj,
    reference: ReferencesTypes.PARTICULAR_SALARY_TAX,
    period,
  });

  const integrationActive = useSelector(
    (state) => !!state.customers[clientId]?.integrations?.fortnox
  );

  const accountingPeriod = useSelector(
    currentClient((state) =>
      state.years[financialYear]?.accountingBalances?.periods.find(
        (item) => parseFormat(item.start, 'yyyyMMdd') === period
      )
    )
  );

  const adjustments = taxYearData.state?.adjustments;

  // Data will depend on selector type;
  const financialStatementVerificationData =
    taxYearData?.state?.document?.particularSalaryTax
      .finacialStatementVerification;

  const isDetailedSpecification =
    !!taxYearData?.state?.document.particularSalaryTax.isDetailedSpecification
      .value;

  const defaultDescription = `${formatMessage({
    id: formatMessage({ id: 'tax.particularSalaryTax.title' }),
  })} ${!isLastPeriod ? getFullMonth(period) : ''}`;

  const getDefaultTransactions = useCallback(() => {
    let actualTransactions: VoucherTransaction[] = [];

    if (isDetailedSpecification) {
      if (!financialStatementVerificationData?.transactions?.rows) {
        return DEFAULT_TRANSACTIONS;
      }
      const stateTransactions = [
        ...financialStatementVerificationData?.transactions?.rows,
      ];
      actualTransactions = DEFAULT_TRANSACTIONS.map((transaction, index) => {
        const transactionCellsData = stateTransactions[index].cells;
        if (transactionCellsData) {
          const transactionData = Object.fromEntries(
            Object.entries(transactionCellsData).map(([key, value]) => {
              if ('value' in value) {
                return [key, value.value as number];
              }
              return [key, 0 as number];
            })
          );
          const { accountNumber } = transactionData;
          const { credit } = transactionData;
          const { debit } = transactionData;
          if (index === 0) {
            return {
              ...transaction,
              account: accountNumber,
              credit: credit < 0 ? credit * -1 : 0,
              debit: credit < 0 ? 0 : credit,
              amount: credit < 0 ? credit * -1 : credit,
              typeAmount: credit < 0 ? 'credit' : 'debit',
            };
          }
          return {
            ...transaction,
            account: accountNumber,
            credit: debit < 0 ? 0 : debit,
            debit: debit < 0 ? debit * -1 : 0,
            amount: debit < 0 ? debit * -1 : debit,
            typeAmount: debit < 0 ? 'debit' : 'credit',
          };
        }
        return transaction;
      });
    } else {
      if (!adjustments) {
        return DEFAULT_TRANSACTIONS;
      }
      const adjustmentsRows = adjustments.rows as TransactionRow[];
      actualTransactions = adjustmentsRows.filter(rowFilter).map((row) => {
        let info = row.label || '';

        if (!info) {
          if (row.labelId !== undefined) {
            info = formatMessage({
              id: `tax.transaction.${row.labelId}.label`,
            });
          }
        }

        const rowValue = row.value || 0;

        return {
          id: row.id,
          sourceInfo: info,
          kind: 'KS',
          typeAmount: rowValue > 0 ? 'debit' : 'credit',
          amount: Math.abs(rowValue),
          account: row.account ? +row.account : 0,
          debit: rowValue > 0 ? rowValue : 0,
          credit: rowValue < 0 ? Math.abs(rowValue) : 0,
        };
      });
    }

    return actualTransactions;
  }, [
    adjustments,
    financialStatementVerificationData?.transactions?.rows,
    formatMessage,
    isDetailedSpecification,
  ]);

  useEffect(() => {
    if (!voucherStatus || !onSetVoucherStatus) return;

    onSetVoucherStatus(ReferencesTypes.PARTICULAR_SALARY_TAX, voucherStatus);
  }, [onSetVoucherStatus, voucherStatus]);

  useEffect(() => {
    if (!selectedSeries || !onSetVoucherNumbers || !voucherNumber) return;
    onSetVoucherNumbers(
      ReferencesTypes.PARTICULAR_SALARY_TAX,
      `${selectedSeries}-${voucherNumber}`
    );
  }, [onSetVoucherNumbers, selectedSeries, voucherNumber]);

  useEffect(() => {
    if (integrationActive) {
      const result = seriesList.find((item) => item.series === 'I');
      onChangeSeries(result ? result.series : 'A');
    } else {
      onChangeSeries('Agoy');
    }
  }, [integrationActive, onChangeSeries, seriesList]);

  useEffect(() => {
    onChangeDate(accountingPeriod?.end || '');
  }, [onChangeDate, accountingPeriod]);

  useEffect(() => {
    setTransactions(getDefaultTransactions());
  }, [getDefaultTransactions, setTransactions]);

  useEffect(() => {
    onChangeDescription(defaultDescription);
  }, [onChangeDescription, defaultDescription]);

  const handleReset = () => {
    onReset();
    setTransactions(getDefaultTransactions());
    onChangeDescription(defaultDescription);
    onChangeDate(accountingPeriod?.end || '');
  };

  const handleChangeEditing = () => {
    setLocalEditing((currentValue) => !currentValue);
  };

  const handleDeleteDocument = (id: number) => {
    const updatedDocuments = documents.filter((item) => item.id !== id);
    onChangeDocuments(updatedDocuments);
  };

  const handleSave = async (voucher: Voucher): Promise<void> => {
    const result = await onCreateVoucher(voucher);

    if (result.ok) {
      const message = integrationActive
        ? 'bokslutsVerification.table.saveToFortnox.success'
        : 'bokslutsVerification.table.saveToAgoy.success';
      dispatch(
        addGlobalMessage(
          'success',
          voucher.preliminary
            ? 'tax.voucher.fortnox.created.preliminary'
            : message
        )
      );
      // Here will be (or not) save into specification function
    }
  };

  const particularSalaryTaxToBeBooked =
    Number(
      taxYearData?.state?.document.particularSalaryTax.summarize.taxToBeBooked
        .value
    ) || 0;

  const particularSalaryTaxToBook =
    taxYearData?.state?.particularSalaryTax?.particularSalaryTaxToBook?.value ||
    0;

  return (
    <PageContent>
      <VoucherForm
        isFinalVoucherEqualCalculations={
          Math.abs(
            isDetailedSpecification
              ? particularSalaryTaxToBeBooked
              : particularSalaryTaxToBook
          ) < 1
        }
        nextVoucherNumber={nextVoucherNumber}
        voucherStatus={voucherStatus}
        clientId={clientId}
        financialYear={financialYearObj}
        editing={editing || localEditing}
        titleText={title}
        documents={documents}
        transactions={transactions}
        description={description}
        series={selectedSeries}
        date={date}
        seriesList={seriesList}
        source={ReferencesTypes.PARTICULAR_SALARY_TAX}
        voucherNumber={voucherNumber}
        accountsList={accountsList}
        fortnoxActive={integrationActive}
        showHeader={!hideHeader}
        print={print}
        className={className}
        minDate={minPeriodDate}
        maxDate={maxPeriodDate}
        disabledDate={disabledDate}
        onAddTransaction={onAddTransaction}
        onRemoveTransaction={onRemoveTransaction}
        onUpdateTransaction={onUpdateTransaction}
        onChangeDate={onChangeDate}
        onChangeDescription={onChangeDescription}
        onReset={handleReset}
        onChangeSeries={onChangeSeries}
        onAddDocument={onAddDocument}
        onDeleteDocument={handleDeleteDocument}
        onChangeEditing={handleChangeEditing}
        onSave={handleSave}
        onDeleteVoucher={onDeleteVoucher}
      />
    </PageContent>
  );
};

export default ParticularSalaryTaxVoucher;
