import React, { useContext } from 'react';
import PrintStateContext from '_shared/components/PrintedDocument/PrintStateContext';
import TaxSpecificationsContentTab from './TaxSpecificationsContentTab';

const TaxSpecificationsPrint = () => {
  const {
    state: { taxSpecificationsData },
  } = useContext(PrintStateContext);

  if (!taxSpecificationsData) {
    return null;
  }

  return (
    <TaxSpecificationsContentTab
      {...taxSpecificationsData}
      isPrint
      onSwitchVisibleForPrint={() => {}}
    />
  );
};

export default TaxSpecificationsPrint;
