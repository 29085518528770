import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import MoreVert from '@material-ui/icons/MoreVertOutlined';
import Edit from '@material-ui/icons/EditOutlined';
import { Popover } from '@material-ui/core';
import Typography from '_shared/components/Typography/Typography';
import { ReactComponent as Pinned } from 'assets/pinned.svg';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const PopoverContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 0;
`;

const MenuItem = styled(Row)`
  column-gap: 8px;
  padding: 8px 16px;

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.palette.grey[100]};
  }
`;

const IconButton = styled.button`
  all: unset;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 16px;

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.palette.grey[100]};
    border-radius: 12px;
  }
`;

const PinnedIcon = styled(Pinned)`
  height: ${({ theme }) => theme.spacing(3)}px;
  width: ${({ theme }) => theme.spacing(3)}px;
`;

const OptionsIcon = styled(MoreVert)`
  height: ${({ theme }) => theme.spacing(3)}px;
  width: ${({ theme }) => theme.spacing(3)}px;
`;

type OptionsProps = {
  onEdit: () => void;
  onPin: () => void;
};

const Options = ({ onEdit, onPin }: OptionsProps) => {
  const { formatMessage } = useIntl();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleEdit = () => {
    onEdit();
    setAnchorEl(null);
  };

  const handlePin = () => {
    onPin();
    setAnchorEl(null);
  };

  return (
    <>
      <Popover
        anchorEl={anchorEl}
        open={!!anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        onClose={() => setAnchorEl(null)}
      >
        <PopoverContainer>
          <MenuItem onClick={handlePin}>
            <PinnedIcon />
            <Typography margin="none">
              {formatMessage({
                id: 'reconciliation.hidden.specifications.pin',
              })}
            </Typography>
          </MenuItem>
          <MenuItem onClick={handleEdit}>
            <Edit />
            <Typography margin="none">
              {formatMessage({
                id: 'reconciliation.hidden.specifications.editColumn',
              })}
            </Typography>
          </MenuItem>
        </PopoverContainer>
      </Popover>

      <IconButton onClick={handleClick}>
        <OptionsIcon />
      </IconButton>
    </>
  );
};

export default Options;
