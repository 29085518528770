import { parseFormat } from '@agoy/dates';
import { TransactionRow } from '@agoy/tax-document';
import { isNonZeroValue } from 'utils';

export const rowFilter = (row: TransactionRow) => {
  const primaryFilterCheck =
    (isNonZeroValue(row.value) || !row.reference) && !row.deleted;
  const secondaryFilterCheck = row.account !== '7530' && row.account !== '2514';

  return primaryFilterCheck && secondaryFilterCheck;
};

export const getFullMonth = (period: string) => {
  const formattedMonth = parseFormat(period, 'MMMM');
  return (
    formattedMonth.slice(0, 1).toLocaleUpperCase() + formattedMonth.slice(1)
  );
};
