import React, { useState } from 'react';
import styled from '@emotion/styled';
import Typography from '_shared/components/Typography/Typography';
import { getClasses } from '@agoy/common';
import { ReactComponent as Expand } from 'assets/expand-bold.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid black;
  padding: ${({ theme }) => theme.spacing(1)}px 0;
  margin-left: 24px;
`;

const HeaderWrapper = styled.div`
  cursor: pointer;
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.2s ease-out;

  &.expanded {
    grid-template-rows: 1fr;
  }
`;

const Content = styled.div`
  overflow-y: hidden;
  overflow-x: visible;
`;

const IconButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: ${({ theme }) => theme.spacing(1)}px;

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.palette.grey[100]};
    border-radius: 12px;
  }
`;

const ExpandedIcon = styled(Expand)`
  height: ${({ theme }) => theme.spacing(3)}px;
  width: ${({ theme }) => theme.spacing(3)}px;

  &.expanded {
    rotate: 180deg;
  }
`;

type ExpandableProps = {
  title: string;
  defaultExpanded?: boolean;
  children: React.ReactNode;
};

const Expandable = ({
  title,
  defaultExpanded = false,
  children,
}: ExpandableProps) => {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const classes = getClasses({ expanded: isExpanded });

  return (
    <Container>
      <Header>
        <HeaderWrapper onClick={toggleExpand}>
          <Typography variant="h3" margin="none">
            {title}
          </Typography>
        </HeaderWrapper>
        <IconButton>
          <ExpandedIcon onClick={toggleExpand} className={classes} />
        </IconButton>
      </Header>
      <Wrapper className={classes}>
        <Content>{children}</Content>
      </Wrapper>
    </Container>
  );
};

export default Expandable;
