import { value } from '@agoy/document';
import { PersonMetaData, TaxDeclarationFormDefinition } from '../../types';
import { mapFormId, toInitial, toStructure } from '../forms-util';

const configTaxSummary = (
  form: TaxDeclarationFormDefinition,
  customer: PersonMetaData,
  financialYearStart: string,
  financialYearEnd: string
) => {
  const TaxSummary = mapFormId(form, 'TS');
  const initial = {
    TS: {
      partType: 'taxSummary' as const,
      id: TaxSummary,
      fields: {
        name: value(''),
        personalNumber: value(''),
        taxYear: value(''),
      },
      sru: {},
    },
  };

  return {
    initial: toInitial(initial, form),
    definition: toStructure(initial, form),
  };
};

export default configTaxSummary;
