import React from 'react';
import styled from '@emotion/styled';
import { TaxSummaryFields } from '@agoy/tax-declaration-document';

import Spacer from '@agoy/annual-report-view/src/UI/helpers/Spacer';
import Typography from '@agoy/annual-report-view/src/UI/Typography/Typography';

import { LogoOrName } from '../CoverLetter/Components';
import { Input } from '../CoverLetter/Input';

const Paper = styled.div`
  padding: ${({ theme }) => theme.spacing(4)}px
    ${({ theme }) => theme.spacing(6)}px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3),
    0px 1px 3px 1px rgba(0, 0, 0, 0.15);
  width: 900px;
  aspect-ratio: 1 / 1.41; /* A4 aspect ratio */

  @media print {
    box-shadow: none;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  gap: 80px;

  > div {
    flex: 1;
  }
`;

const TitleSection = styled.div``;

const InputGroup = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)}px;
`;

type TaxSummaryProps = {
  fields: TaxSummaryFields;
  postalCode: string | null | undefined;
  city: string | null | undefined;
  orgName: string | undefined;
};

const TaxSummary = ({ fields, postalCode, city, orgName }: TaxSummaryProps) => {
  return (
    <Paper>
      <HeaderContainer>
        <TitleSection>
          <Typography variant="h1" margin="none">
            Sammanställning skatt
          </Typography>
          <Typography variant="h2" margin="none">
            Privatperson
          </Typography>

          <Spacer size={2} />

          <Input field={fields.name} label="Namn" keyValue="name" />
          <Spacer size={1} />
          <InputGroup>
            <Input
              field={fields.personalNumber}
              label="Person-/Organisationsnummer"
              keyValue="personalNumber"
            />
            <Input
              field={fields.taxYear}
              label="Beskattningsår"
              keyValue="taxYear"
            />
          </InputGroup>
        </TitleSection>
        <LogoOrName orgName={orgName} postalCode={postalCode} city={city} />
      </HeaderContainer>
    </Paper>
  );
};

export default TaxSummary;
