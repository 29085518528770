import { ClientCompanyType, ClosingPeriod } from '_clients/types/types';
import logoOriginalUrl from 'utils/Logo/logo-helper';
import { Routines, UserInput, UserInputDocuments } from '_reconciliation/types';
import config from '_shared/services/config';
import { parseFormat } from '@agoy/dates';

/**
 * Remaps the object accounts of periods to periods of accounts to be used in the table
 * @param userInput object from backend:
 * {
 *  2017-2017-09: {
 *                account1910: {saldo, comment, visited},
 *                accountXXXX :{...}
 *               },
 *  2017-routine-2017-09: {
 *                account1910: {routine},
 *                accountXXXX :{...}
 *               },
 *  2017-2018-02: {
 *                account1910: {...},
 *                accountXXXX :{...}
 *               },
 * }
 * @returns object from backend:
 * {
 *  account1910: {
 *                2017-09: {saldo, comment, visited, routine},
 *                2018-02 :{...}
 *               },
 *  accountXXXX: {...},
 * }
 *
 */
export const mapUserInputData = (userInput: {}): {
  userInput: UserInput;
  userInputDocuments: UserInputDocuments;
} => {
  const listOfObjectsToObject = (array) => {
    return array.reduce((resultObj, currentObject) => {
      const key = Object.keys(currentObject)[0];
      const existingData = resultObj[key] || {};
      resultObj[key] = { ...existingData, ...currentObject[key] };
      return resultObj;
    }, {});
  };

  const listDocumentsToObject = (array) => {
    return array.reduce((resultObj, currentObject) => {
      const key = Object.keys(currentObject)[0];
      resultObj[key] = currentObject[key];
      return resultObj;
    }, {});
  };

  const userInputAccounts: string[] = [];
  const userInputDocuments: string[] = [];

  Object.keys(userInput).forEach((period) =>
    Object.keys(userInput[period]).forEach((val) => {
      if (val.includes('documents')) {
        userInputDocuments.push(val);
      } else if (val !== 'partitionKey' && val !== 'sortKey') {
        userInputAccounts.push(val);
      }
    })
  );

  const uniqueAccounts = new Set(userInputAccounts);
  const uniqueDocuments = new Set(userInputDocuments);

  const accountUserinput = listOfObjectsToObject(
    [...Array.from(uniqueAccounts)].map((account) => ({
      [account]: listOfObjectsToObject(
        Object.keys(userInput).map((yearAndPeriod) => {
          const userInputForAccount = userInput[yearAndPeriod][account] || {};
          return {
            [yearAndPeriod]: userInputForAccount,
          };
        })
      ),
    }))
  );

  const documentsUserInput = listOfObjectsToObject(
    [...Array.from(uniqueDocuments)].map((account) => ({
      [account]: listDocumentsToObject(
        Object.keys(userInput).map((yearAndPeriod) => {
          const key = yearAndPeriod.slice(-7).replace('-', '');
          const userInputForAccount = userInput[yearAndPeriod][account] ?? 0;
          return {
            [key]: userInputForAccount,
          };
        })
      ),
    }))
  );

  return {
    userInput: accountUserinput,
    userInputDocuments: documentsUserInput,
  };
};

/**
 * Remaps the object accounts of periods to periods of accounts to be used in the table
 * @param routinesData object from backend:
 * {
 *  account1910: {
 *                2017-09 : "Routine A",
 *                2018-02 : "Routine B"
 *               },
 * }
 * @returns object:
 * {
 *  account1910: {
 *                2017-09 : "Routine A",
 *                2018-02 : "Routine B"
 *               },
 *  accountXXXX: {...},
 * }
 *
 */
export const mapRoutinesData = (routinesData: Routines): Routines => {
  return routinesData;
};

const closingPeriods: ClosingPeriod[] = ['month', 'quarter', 'year'];

export const mapClosingPeriod = (value: string | null): ClosingPeriod | null =>
  value ? closingPeriods.find((item) => item === value) ?? 'month' : null;

export const mapClient = (clientResponse: any): ClientCompanyType => {
  const client =
    'client' in clientResponse ? clientResponse.client : clientResponse;

  const {
    active,
    closingMonth,
    closingPeriod,
    financialYears,
    id,
    lamps,
    managerId,
    thirdPartyId,
    name,
    period,
    type,
    vatPeriod,
    contact,
    integrations,
    logo,
    logoOriginal,
    number,
    orgNumber,
    sortKey,
    address,
    email,
    startDate,
    programStatus,
    taxOutgoingBalanceDate,
    taxOutgoingBalanceAmount,
    tags,
    assignees,
    stockTotals,
    checkLists,
    isFortnoxCustomer,
  } = client;
  const newFinancialYears = financialYears
    ? financialYears
        .map((item) => {
          const { start, end } = item;
          return `${parseFormat(start, 'yyyyMMdd')}-${parseFormat(
            end,
            'yyyyMMdd'
          )}`;
        })
        .sort()
    : [];
  const newFinancialYearIds = financialYears
    ? financialYears.reduce((map, item) => {
        const { start, end, id } = item;
        const financialYear = `${parseFormat(start, 'yyyyMMdd')}-${parseFormat(
          end,
          'yyyyMMdd'
        )}`;
        map[financialYear] = id;
        return map;
      }, {})
    : null;
  return {
    active,
    closingMonth,
    closingPeriod: mapClosingPeriod(closingPeriod),
    financialYears:
      config.runningEnvironment === 'development'
        ? Object.freeze(newFinancialYears)
        : newFinancialYears,
    latestFinancialYear: newFinancialYears.reduce(
      (latest, year) => (latest > year ? latest : year),
      ''
    ),
    rawFinancialYears: financialYears,
    id,
    lamps,
    managerId,
    thirdPartyId,
    name,
    period,
    type,
    vatPeriod,
    contact,
    integrations,
    logo,
    logoOriginal: logoOriginal || (logo && logoOriginalUrl(logo)),
    number,
    orgNumber,
    sortKey,
    address,
    email,
    financialYearIds: newFinancialYearIds,
    startDate,
    programStatus: programStatus || [],
    taxOutgoingBalanceDate,
    taxOutgoingBalanceAmount,
    tags: tags || [],
    assignees: assignees || [],
    stockTotals,
    checklists: checkLists || [],
    isFortnoxCustomer: isFortnoxCustomer ?? false,
  };
};
