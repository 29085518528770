import React, { useContext, useState } from 'react';
import styled from '@emotion/styled';

import { formatPeriodToMonthAndYear } from 'utils';
import { InputData } from '_reconciliation/types';
import PeriodSummary from '_shared/components/PeriodSummary';
import EditIcon from '@material-ui/icons/Edit';
import { useIntl } from 'react-intl';

import Button from '_shared/components/Buttons/Button';
import Drawer from '_shared/components/Drawer';
import PeriodDataContext from '../PeriodDataContext';
import ColumnSelector from './ColumnSelector/ColumnSelector';
import SpecificationTable from './SpecificationTable';

const Container = styled.div`
  display: flex;
  width: 100%;
  margin-top: ${(props) => props.theme.spacing(2)}px;
`;

const PeriodSummaryWrapper = styled.div`
  min-width: 230px;
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-right: ${({ theme }) => theme.spacing(3)}px;
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 8px;
  margin: 1rem 0;
`;

interface Props {
  userData: InputData;
  accountNumber: string;
  accountName: string;
}

const SpecificationView = ({ userData, accountNumber }: Props): JSX.Element => {
  const { ib, ub, psaldo, saldo: userDataSaldo, yearIB } = userData || {};
  const saldo = userDataSaldo ? parseInt(userDataSaldo, 10) : 0;
  const { period } = useContext(PeriodDataContext);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const { formatMessage } = useIntl();

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <Container>
      <Drawer
        open={drawerOpen}
        onClose={toggleDrawer}
        width="40vh"
        headerTitle={formatMessage({ id: 'hidden.specification.editTable' })}
      >
        <ColumnSelector onClose={toggleDrawer} />
      </Drawer>
      <PeriodSummaryWrapper>
        <PeriodSummary
          date={formatPeriodToMonthAndYear(period)}
          periodIb={ib}
          periodSaldo={psaldo}
          periodUb={ub}
          saldo={saldo}
          yearIB={yearIB}
        />
      </PeriodSummaryWrapper>
      <TableWrapper>
        <ActionButtons>
          <Button
            startIcon={<EditIcon />}
            size="small"
            variant="outlined"
            label={formatMessage({ id: 'hidden.specification.editTable' })}
            onClick={toggleDrawer}
          />
          <Button
            size="small"
            variant="outlined"
            label={formatMessage({ id: 'hidden.specification.print' })}
          />
        </ActionButtons>
        <SpecificationTable accountNumber={accountNumber} />
      </TableWrapper>
    </Container>
  );
};

export default SpecificationView;
