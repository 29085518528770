import {
  TaxDeclarationFormDefinition,
  TaxDeclarationFormDataConfig,
  PersonMetaData,
} from '../../types';
import {
  div,
  id,
  ifOrElse,
  isGreaterThan,
  limitMin,
  max,
  min,
  multiply,
  ref,
  sum,
  sumAllowNull,
  value,
} from '@agoy/document';
import { mapFormId, toInitial, toStructure } from '../forms-util';
import { getFinancialYearEnd } from '@agoy/dates';
import { getNePersonHelperConfig } from '@agoy/common';

const configNEPersonHelper = (
  form: TaxDeclarationFormDefinition,
  person: PersonMetaData,
  financialYearStart: string,
  financialYearEnd: string
): TaxDeclarationFormDataConfig => {
  const FIELD_KEY = `NE_PERSON_HELPER.fields`;

  const finYearEnd = getFinancialYearEnd(
    `${financialYearStart}-${financialYearEnd}`
  );

  const finYearEndNumber = parseInt(finYearEnd, 10);

  const nePersonHelperConfig = getNePersonHelperConfig(finYearEndNumber);

  // Get the values that can change based on the financial year end
  const {
    A8_percentage,
    A13a_percentage,
    A13b_percentage,
    B8b_percentage,
    B10b_percentage,
    maximalAmount,
  } = nePersonHelperConfig;

  const NE_PERSON_HELPER = mapFormId(form, 'NE_PERSON_HELPER');
  const initial = {
    NE_PERSON_HELPER: {
      partType: 'form' as const,
      id: NE_PERSON_HELPER,
      fields: {
        taxYear: value(''),
        A1_assets: ref(id('NE_PREVIOUS_YEAR.NE.fields.B10')),
        A2_unutilizedDeficit: ref(id('NE.NE.fields.R24_neg')),
        A3_transitionPost: value(undefined),
        A4_specialPost: value(0),
        A5_remainingSaved: ref(id('NE_PREVIOUS_YEAR.NE.fields.otherInfo_10')),
        A6_nonPermanent: value(undefined),
        A7_totalAccrualFunds: value(undefined),
        A8_expansionFundPercentage: ref(
          multiply(
            A8_percentage,
            id(
              'NE_PERSON_HELPER_PREVIOUS_YEAR.NE_PERSON_HELPER.fields.B_expansionFundYearEnd'
            )
          )
        ),
        A8_sum: ref(
          sumAllowNull(
            id(`${FIELD_KEY}.A1_assets`),
            id(`${FIELD_KEY}.A2_unutilizedDeficit`),
            id(`${FIELD_KEY}.A3_transitionPost`),
            id(`${FIELD_KEY}.A4_specialPost`),
            id(`${FIELD_KEY}.A5_remainingSaved`),
            multiply(-1, id(`${FIELD_KEY}.A6_nonPermanent`)),
            multiply(-1, id(`${FIELD_KEY}.A7_totalAccrualFunds`)),
            multiply(-1, id(`${FIELD_KEY}.A8_expansionFundPercentage`))
          )
        ),
        A9a_bookedValue: ref(id('NE.NE.fields.B2')),
        A9b_taxValue: ref(
          sumAllowNull(
            max(
              id(`${FIELD_KEY}.C3_F1_basis`),
              id(`${FIELD_KEY}.C14_F1_interestDistributionBasis`)
            ),
            max(
              id(`${FIELD_KEY}.C3_F2_basis`),
              id(`${FIELD_KEY}.C14_F2_interestDistributionBasis`)
            ),
            max(
              id(`${FIELD_KEY}.C3_F3_basis`),
              id(`${FIELD_KEY}.C14_F3_interestDistributionBasis`)
            )
          )
        ),
        A9_sum: ref(
          sum(
            multiply(-1, id(`${FIELD_KEY}.A9a_bookedValue`)),
            id(`${FIELD_KEY}.A9b_taxValue`)
          )
        ),
        A9_2nd_sum: ref(id(`${FIELD_KEY}.A9_sum`)),
        A10a_bookedValue: value(undefined),
        A10b_halfOfMeans: ref(
          multiply(0.5, id(`${FIELD_KEY}.A10a_bookedValue`))
        ),
        A10_sum: ref(
          sum(
            multiply(-1, id(`${FIELD_KEY}.A10a_bookedValue`)),
            id(`${FIELD_KEY}.A10b_halfOfMeans`)
          )
        ),
        A10_2nd_sum: ref(id(`${FIELD_KEY}.A10_sum`)),
        A11a_negativeAdjustment: value(undefined),
        A11b_positiveAdjustment: value(undefined),
        A12a_excess: ref(
          limitMin(
            sumAllowNull(
              id(`${FIELD_KEY}.A8_sum`),
              id(`${FIELD_KEY}.A9_2nd_sum`),
              id(`${FIELD_KEY}.A10_2nd_sum`),
              multiply(-1, id(`${FIELD_KEY}.A11a_negativeAdjustment`)),
              id(`${FIELD_KEY}.A11b_positiveAdjustment`)
            ),
            '0'
          )
        ),
        A12b_deficit: ref(
          limitMin(
            multiply(
              -1,
              sumAllowNull(
                id(`${FIELD_KEY}.A8_sum`),
                id(`${FIELD_KEY}.A9_2nd_sum`),
                id(`${FIELD_KEY}.A10_2nd_sum`),
                multiply(-1, id(`${FIELD_KEY}.A11a_negativeAdjustment`)),
                id(`${FIELD_KEY}.A11b_positiveAdjustment`)
              )
            ),
            '0'
          )
        ),
        A13a_amountFrom12a: ref(
          ifOrElse(
            isGreaterThan(id(`${FIELD_KEY}.A12a_excess`), maximalAmount),
            multiply(A13a_percentage, id(`${FIELD_KEY}.A12a_excess`)),
            '0'
          )
        ),
        A13b_amountFrom12b: ref(
          ifOrElse(
            isGreaterThan(id(`${FIELD_KEY}.A12b_deficit`), maximalAmount),
            multiply(A13b_percentage, id(`${FIELD_KEY}.A12b_deficit`)),
            '0'
          )
        ),
        A14_savedDistributionAmount: ref(id(`${FIELD_KEY}.A5_remainingSaved`)),
        A15a_positiveDistributionAmount: ref(
          limitMin(
            sumAllowNull(
              id(`${FIELD_KEY}.A13a_amountFrom12a`),
              multiply(-1, id(`${FIELD_KEY}.A13b_amountFrom12b`)),
              id(`${FIELD_KEY}.A14_savedDistributionAmount`)
            ),
            '0'
          )
        ),
        A15b_negativeDistributionAmount: ref(
          limitMin(
            multiply(
              -1,
              sumAllowNull(
                id(`${FIELD_KEY}.A13a_amountFrom12a`),
                multiply(-1, id(`${FIELD_KEY}.A13b_amountFrom12b`)),
                id(`${FIELD_KEY}.A14_savedDistributionAmount`)
              )
            ),
            '0'
          )
        ),
        A16_positivelyUtilized: ref(
          id(`${FIELD_KEY}.A15a_positiveDistributionAmount`)
        ),
        A17_savedDistributionAmount: ref(
          sumAllowNull(
            id(`${FIELD_KEY}.A15a_positiveDistributionAmount`),
            multiply(-1, id(`${FIELD_KEY}.A16_positivelyUtilized`))
          )
        ),
        B1_assetsMinusLiabilities: ref(id('NE.NE.fields.B10')),
        B2_unutilizedDeficit: ref(id('NE.NE.fields.R24_neg')),
        B3_transitionalItem: value(undefined),
        B4_specialMail: value(undefined),
        B5_nonPermanentCapital: value(undefined),
        B6_accrualFundsSum: value(undefined),
        B6_subTotal: ref(
          sumAllowNull(
            id(`${FIELD_KEY}.B1_assetsMinusLiabilities`),
            id(`${FIELD_KEY}.B2_unutilizedDeficit`),
            id(`${FIELD_KEY}.B3_transitionalItem`),
            id(`${FIELD_KEY}.B4_specialMail`),
            multiply(-1, id(`${FIELD_KEY}.B5_nonPermanentCapital`)),
            multiply(-1, id(`${FIELD_KEY}.B6_accrualFundsSum`))
          )
        ),
        B7a_valueOfProperties: ref(id('NE.NE.fields.B2')),
        B7b_taxValueRealEstate: ref(
          sumAllowNull(
            max(
              id(`${FIELD_KEY}.C7_F1_basis`),
              id(`${FIELD_KEY}.C18_F1_expansionFundBasis`)
            ),
            max(
              id(`${FIELD_KEY}.C7_F2_basis`),
              id(`${FIELD_KEY}.C18_F2_expansionFundBasis`)
            ),
            max(
              id(`${FIELD_KEY}.C7_F3_basis`),
              id(`${FIELD_KEY}.C18_F3_expansionFundBasis`)
            )
          )
        ),
        B7_sum: ref(
          sumAllowNull(
            multiply(-1, id(`${FIELD_KEY}.B7a_valueOfProperties`)),
            id(`${FIELD_KEY}.B7b_taxValueRealEstate`)
          )
        ),
        B7_2nd_sum: ref(id(`${FIELD_KEY}.B7_sum`)),
        B8a_valueAuthorAccount: value(undefined),
        B8b_halfFundsAuthorAccount: ref(
          multiply(B8b_percentage, id(`${FIELD_KEY}.B8a_valueAuthorAccount`))
        ),
        B8_sum: ref(
          sumAllowNull(
            multiply(-1, id(`${FIELD_KEY}.B8a_valueAuthorAccount`)),
            id(`${FIELD_KEY}.B8b_halfFundsAuthorAccount`)
          )
        ),
        B8_2nd_sum: ref(id(`${FIELD_KEY}.B8_sum`)),
        B9a_negativeAdjustments: value(undefined),
        B9b_positiveAdjustments: value(undefined),
        B10a_capitalBasis: ref(
          limitMin(
            sumAllowNull(
              id(`${FIELD_KEY}.B6_subTotal`),
              id(`${FIELD_KEY}.B7_2nd_sum`),
              id(`${FIELD_KEY}.B8_2nd_sum`),
              id(`${FIELD_KEY}.B9a_negativeAdjustments`),
              id(`${FIELD_KEY}.B9b_positiveAdjustments`)
            ),
            '0'
          )
        ),
        B10b_maxExpansionFund: ref(
          multiply(B10b_percentage, id(`${FIELD_KEY}.B10a_capitalBasis`))
        ),
        B_calculationMaxExpansionFondAmount: ref(
          id(`${FIELD_KEY}.B10b_maxExpansionFund`)
        ),
        B_calculationExpansionFondYearStart: ref(
          id(
            'NE_PERSON_HELPER_PREVIOUS_YEAR.NE_PERSON_HELPER.fields.B_expansionFundYearEnd'
          )
        ),
        B_calculationPositiveAmount: ref(
          limitMin(
            sumAllowNull(
              id(`${FIELD_KEY}.B_calculationMaxExpansionFondAmount`),
              multiply(
                -1,
                id(`${FIELD_KEY}.B_calculationExpansionFondYearStart`)
              )
            ),
            '0'
          )
        ),
        B_calculationNegativeAmount: ref(
          limitMin(
            sumAllowNull(
              multiply(
                -1,
                id(`${FIELD_KEY}.B_calculationMaxExpansionFondAmount`)
              ),
              id(`${FIELD_KEY}.B_calculationExpansionFondYearStart`)
            ),
            '0'
          )
        ),
        B_expansionFundYearStart: ref(
          id(`${FIELD_KEY}.B_calculationExpansionFondYearStart`)
        ),
        B_expansionFundIncrease: ref(
          id(`${FIELD_KEY}.B_calculationPositiveAmount`)
        ),
        B_expansionFundReduction: ref(
          id(`${FIELD_KEY}.B_calculationNegativeAmount`)
        ),
        B_expansionFundYearEnd: ref(
          min(
            limitMin(
              sumAllowNull(
                id(`${FIELD_KEY}.B_expansionFundYearStart`),
                id(`${FIELD_KEY}.B_expansionFundIncrease`),
                multiply(-1, id(`${FIELD_KEY}.B_expansionFundReduction`))
              ),
              '0'
            ),
            id(`${FIELD_KEY}.B10b_maxExpansionFund`)
          )
        ),
        C1_F1_acquisitionValue: value(undefined),
        C1_F2_acquisitionValue: value(undefined),
        C1_F3_acquisitionValue: value(undefined),
        C2_F1_depreciationDeduction: value(undefined),
        C2_F2_depreciationDeduction: value(undefined),
        C2_F3_depreciationDeduction: value(undefined),
        C3_F1_basis: ref(
          sumAllowNull(
            id(`${FIELD_KEY}.C1_F1_acquisitionValue`),
            multiply(-1, id(`${FIELD_KEY}.C2_F1_depreciationDeduction`))
          )
        ),
        C3_F2_basis: ref(
          sumAllowNull(
            id(`${FIELD_KEY}.C1_F2_acquisitionValue`),
            multiply(-1, id(`${FIELD_KEY}.C2_F2_depreciationDeduction`))
          )
        ),
        C3_F3_basis: ref(
          sumAllowNull(
            id(`${FIELD_KEY}.C1_F3_acquisitionValue`),
            multiply(-1, id(`${FIELD_KEY}.C2_F3_depreciationDeduction`))
          )
        ),
        C4_F1_acquisitions: value(undefined),
        C4_F2_acquisitions: value(undefined),
        C4_F3_acquisitions: value(undefined),
        C5_F1_depreciation: value(undefined),
        C5_F2_depreciation: value(undefined),
        C5_F3_depreciation: value(undefined),
        C6_F1_correction: value(undefined),
        C6_F2_correction: value(undefined),
        C6_F3_correction: value(undefined),
        C7_F1_basis: ref(
          sumAllowNull(
            id(`${FIELD_KEY}.C3_F1_basis`),
            id(`${FIELD_KEY}.C4_F1_acquisitions`),
            multiply(-1, id(`${FIELD_KEY}.C5_F1_depreciation`)),
            multiply(-1, id(`${FIELD_KEY}.C6_F1_correction`))
          )
        ),
        C7_F2_basis: ref(
          sumAllowNull(
            id(`${FIELD_KEY}.C3_F2_basis`),
            id(`${FIELD_KEY}.C4_F2_acquisitions`),
            multiply(-1, id(`${FIELD_KEY}.C5_F2_depreciation`)),
            multiply(-1, id(`${FIELD_KEY}.C6_F2_correction`))
          )
        ),
        C7_F3_basis: ref(
          sumAllowNull(
            id(`${FIELD_KEY}.C3_F3_basis`),
            id(`${FIELD_KEY}.C4_F3_acquisitions`),
            multiply(-1, id(`${FIELD_KEY}.C5_F3_depreciation`)),
            multiply(-1, id(`${FIELD_KEY}.C6_F3_correction`))
          )
        ),
        C8_F1_appraisalValue: value(undefined),
        C8_F2_appraisalValue: value(undefined),
        C8_F3_appraisalValue: value(undefined),
        C9_F1_percentage: value(undefined),
        C9_F2_percentage: value(undefined),
        C9_F3_percentage: value(undefined),
        C10_F1_partOfAssessedValue: ref(
          multiply(
            id(`${FIELD_KEY}.C8_F1_appraisalValue`),
            div(id(`${FIELD_KEY}.C9_F1_percentage`), 100)
          )
        ),
        C10_F2_partOfAssessedValue: ref(
          multiply(
            id(`${FIELD_KEY}.C8_F2_appraisalValue`),
            div(id(`${FIELD_KEY}.C9_F2_percentage`), 100)
          )
        ),
        C10_F3_partOfAssessedValue: ref(
          multiply(
            id(`${FIELD_KEY}.C8_F3_appraisalValue`),
            div(id(`${FIELD_KEY}.C9_F3_percentage`), 100)
          )
        ),
        C11_F1_depreciation: value(undefined),
        C11_F2_depreciation: value(undefined),
        C11_F3_depreciation: value(undefined),
        C12_F1_acquisitionValue: value(undefined),
        C12_F2_acquisitionValue: value(undefined),
        C12_F3_acquisitionValue: value(undefined),
        C13_F1_depreciation: value(undefined),
        C13_F2_depreciation: value(undefined),
        C13_F3_depreciation: value(undefined),
        C14_F1_interestDistributionBasis: ref(
          sumAllowNull(
            id(`${FIELD_KEY}.C10_F1_partOfAssessedValue`),
            multiply(-1, id(`${FIELD_KEY}.C11_F1_depreciation`)),
            id(`${FIELD_KEY}.C12_F1_acquisitionValue`),
            multiply(-1, id(`${FIELD_KEY}.C13_F1_depreciation`))
          )
        ),
        C14_F2_interestDistributionBasis: ref(
          sumAllowNull(
            id(`${FIELD_KEY}.C10_F2_partOfAssessedValue`),
            multiply(-1, id(`${FIELD_KEY}.C11_F2_depreciation`)),
            id(`${FIELD_KEY}.C12_F2_acquisitionValue`),
            multiply(-1, id(`${FIELD_KEY}.C13_F2_depreciation`))
          )
        ),
        C14_F3_interestDistributionBasis: ref(
          sumAllowNull(
            id(`${FIELD_KEY}.C10_F3_partOfAssessedValue`),
            multiply(-1, id(`${FIELD_KEY}.C11_F3_depreciation`)),
            id(`${FIELD_KEY}.C12_F3_acquisitionValue`),
            multiply(-1, id(`${FIELD_KEY}.C13_F3_depreciation`))
          )
        ),
        C15_F1_acquisitions: value(undefined),
        C15_F2_acquisitions: value(undefined),
        C15_F3_acquisitions: value(undefined),
        C16_F1_depreciation: value(undefined),
        C16_F2_depreciation: value(undefined),
        C16_F3_depreciation: value(undefined),
        C17_F1_correction: value(undefined),
        C17_F2_correction: value(undefined),
        C17_F3_correction: value(undefined),
        C18_F1_expansionFundBasis: ref(
          sumAllowNull(
            id(`${FIELD_KEY}.C14_F1_interestDistributionBasis`),
            id(`${FIELD_KEY}.C15_F1_acquisitions`),
            multiply(-1, id(`${FIELD_KEY}.C16_F1_depreciation`)),
            multiply(-1, id(`${FIELD_KEY}.C17_F1_correction`))
          )
        ),
        C18_F2_expansionFundBasis: ref(
          sumAllowNull(
            id(`${FIELD_KEY}.C14_F2_interestDistributionBasis`),
            id(`${FIELD_KEY}.C15_F2_acquisitions`),
            multiply(-1, id(`${FIELD_KEY}.C16_F2_depreciation`)),
            multiply(-1, id(`${FIELD_KEY}.C17_F2_correction`))
          )
        ),
        C18_F3_expansionFundBasis: ref(
          sumAllowNull(
            id(`${FIELD_KEY}.C14_F3_interestDistributionBasis`),
            id(`${FIELD_KEY}.C15_F3_acquisitions`),
            multiply(-1, id(`${FIELD_KEY}.C16_F3_depreciation`)),
            multiply(-1, id(`${FIELD_KEY}.C17_F3_correction`))
          )
        ),
      },
      // Leaving SRU empty for now. I don't think we need it in this case
      sru: {},
    },
  };

  return {
    initial: toInitial(initial, form),
    definition: toStructure(initial, form),
  };
};

export default configNEPersonHelper;
