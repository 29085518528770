import React, {
  useCallback,
  useMemo,
  useState,
  useContext,
  useEffect,
} from 'react';
import { Paper, Box, Switch, Typography, Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';

import { TaxCalculationRow } from '@agoy/tax-document';
import { useSelector } from 'redux/reducers';
import { currentPeriod } from '_clients/redux/customer-view/selectors';
import { PeriodDataContextProvider } from '_reconciliation/components/ReconciliationView/HiddenRow/Rows/PeriodDataContext';
import { DocumentClippyYear } from '_clients/components/DocumentClippyYear';
import { RESET_TAX_PARTICULAR_SALARY } from '_tax/redux/tax-view/action-types';
import { AccountPlanList, VoucherSeries } from 'types/Voucher';
import { asResultClass, useApiSdk } from 'api-sdk';
import { printDocument } from '_shared/components/PrintedDocument/utils';
import usePrintState from '_tax/hooks/usePrintState';
import { addGlobalErrorMessage } from 'redux/actions';

import TaxTable from './TaxTable';
import PageContent from './UI/PageContent';
import TaxesDataContext from '../../service/TaxesDataContext';
import ForaCalculationsPanel from './ParticularSalaryPanels/ForaCalculationPanel';
import ViewSummaryComponent from './UI/ViewSummaryComponent/ViewSummaryComponent';
import ViewPensionCommitmentsComponent from './UI/ViewPensionCommitmentsComponent/ViewPensionCommitmentsComponent';
import ParticularSalaryTaxVoucher from './ParticularSalaryTaxVoucher';
import ITPCalculationsPanel from './ParticularSalaryPanels/ITPPremiumsPanel';

export const StyledSwitch = styled(Switch)`
  align-self: center;
  margin-right: 6px;
`;

const InfoIcon = styled(InfoOutlinedIcon)`
  margin-left: 10px;
  color: #00313c;
`;

const ImageColumn = styled.div`
  min-height: 340px;
  width: auto;
  margin-right: 40px;
  overflow: auto;
`;

const ListOfCalculations = styled.div`
  & > * {
    margin-bottom: ${({ theme }) => theme.spacing(4)}px;
  }
`;

const Group = styled.div`
  display: flex;
  padding-top: 16px;
`;

type ParticularSalaryTaxTogglerProps = {
  toggle: boolean;
  onToggle: (boolean) => void;
};

const ParticularSalaryTaxToggler = ({
  toggle,
  onToggle,
}: ParticularSalaryTaxTogglerProps) => {
  const { formatMessage } = useIntl();

  return (
    <Group>
      <StyledSwitch
        size="small"
        checked={toggle}
        name="toggleSpecificationToggle"
        onChange={() => onToggle(!toggle)}
      />
      <Typography>
        {formatMessage({
          id: 'tax.warning.particularSalaryTax.detailedSpecification',
        })}
      </Typography>
      <Tooltip title="Den detaljerade specifikationen ger en utökad vy med stöd för beräkning av särskild löneskatt på avgifter från Fora.">
        <InfoIcon />
      </Tooltip>
    </Group>
  );
};

type ParticularSalaryTaxProps = {
  disableEditing?: boolean;
  isPrint?: boolean;
};

export const ParticularSalaryTaxContent = ({
  disableEditing = false,
  isPrint = false,
}: ParticularSalaryTaxProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const printState = usePrintState();
  const dispatch = useDispatch();

  const {
    clientId,
    financialYear,
    taxYearData: { state },
    service,
  } = useContext(TaxesDataContext);

  const taxTable = state?.particularSalaryTax;

  const handleParticularSalaryTax = useCallback(() => {
    service.resetGenericTax(RESET_TAX_PARTICULAR_SALARY);
  }, [service]);

  const handlePrint = useCallback(async () => {
    try {
      await printDocument(
        clientId,
        financialYear,
        'taxes',
        formatMessage({ id: 'tax.particularSalaryTax.title' }),
        ['particularSalaryTax'],
        printState
      );
    } catch (e) {
      dispatch(addGlobalErrorMessage('error'));
    }
  }, [clientId, dispatch, financialYear, formatMessage, printState]);

  const additionalRows = useMemo(() => {
    if (!taxTable) return [];
    const {
      particularSalaryTax,
      particularSalaryTaxFromSum,
      alreadyBookedParticularSalaryTax,
      particularSalaryTaxToBook,
    } = taxTable;

    return [
      particularSalaryTax,
      particularSalaryTaxFromSum,
      alreadyBookedParticularSalaryTax,
      particularSalaryTaxToBook,
    ].filter((row) => row) as TaxCalculationRow[];
  }, [taxTable]);

  // eslint-disable-next-line no-nested-ternary
  const warning = taxTable?.warningHighAmount
    ? formatMessage({ id: 'tax.warning.particularSalaryTax.highAmount' })
    : taxTable?.warningLowAmount
    ? formatMessage({ id: 'tax.warning.particularSalaryTax.lowAmount' })
    : undefined;

  return (
    <PageContent>
      <Box maxWidth={650}>
        <Paper>
          <TaxTable
            part="particularSalaryTax"
            additionalRows={additionalRows}
            warning={warning}
            disableEditing={disableEditing}
            onPrint={handlePrint}
            onReset={handleParticularSalaryTax}
            print={isPrint}
            printable
          />
        </Paper>
      </Box>
    </PageContent>
  );
};

const ParticularSalaryTax = (): JSX.Element | null => {
  const sdk = useApiSdk();

  const {
    period,
    taxYearData: { state: taxYearDataState },
    service,
    clientId,
  } = useContext(TaxesDataContext);

  const [accountsList, setAccountsList] = useState<AccountPlanList[]>([]);
  const [seriesList, setSeriesList] = useState<VoucherSeries[]>([]);

  const selectedPeriod = useSelector(currentPeriod);
  const integrationActive = useSelector(
    (state) => !!state.customers[clientId]?.integrations?.fortnox
  );

  useEffect(() => {
    const getVoucherSeries = async () => {
      const result = await asResultClass(
        sdk.getVoucherSeries({
          clientid: clientId,
          period,
        })
      );
      if (result.ok) {
        const series = result.val.map((item) => {
          return {
            series: item.series,
            label: `${item.series} - ${item.description}`,
          };
        });
        setSeriesList(series);
      }
    };
    getVoucherSeries();
  }, [period, clientId, sdk]);

  useEffect(() => {
    const getAccountsList = async () => {
      const source = integrationActive ? 'fortnox' : 'agoy';
      const result = await asResultClass(
        sdk.getAccountList({
          clientid: clientId,
          source,
        })
      );

      if (result.ok) {
        const accounts = result.val.map((item) => {
          return {
            value: item.number,
            label: item.name,
            noActive: !item.active,
          };
        });

        setAccountsList([
          { value: 0, label: ' ', noActive: false },
          ...accounts,
        ]);
      }
    };
    getAccountsList();
  }, [clientId, integrationActive, sdk]);

  const isDetailedSpecification =
    !!taxYearDataState?.document.particularSalaryTax.isDetailedSpecification
      .value;

  const onToggleDetailedSpecification = () => {
    if (!taxYearDataState) return;
    service.updateTaxViewDocumentField(
      clientId,
      'particularSalaryTax.isDetailedSpecification',
      !isDetailedSpecification
    );
  };

  if (!selectedPeriod) {
    return null;
  }

  return (
    <PeriodDataContextProvider
      periodType="period"
      period={selectedPeriod}
      clientId={clientId}
    >
      <ListOfCalculations>
        <ParticularSalaryTaxToggler
          toggle={isDetailedSpecification}
          onToggle={onToggleDetailedSpecification}
        />

        {!isDetailedSpecification && <ParticularSalaryTaxContent />}

        {isDetailedSpecification && (
          <>
            <ForaCalculationsPanel />
            <ITPCalculationsPanel />
            <ViewPensionCommitmentsComponent />
            <ViewSummaryComponent />
          </>
        )}

        <ParticularSalaryTaxVoucher
          accountsList={accountsList}
          seriesList={seriesList}
        />
      </ListOfCalculations>
      <ImageColumn>
        <DocumentClippyYear period={selectedPeriod} category="tax-year" />
      </ImageColumn>
    </PeriodDataContextProvider>
  );
};

export default ParticularSalaryTax;
