import React, { useContext, useState } from 'react';
import styled from '@emotion/styled';
import { Print as PrintIcon, Close as CloseIcon } from '@material-ui/icons';
import {
  Dialog as MuiDialog,
  DialogContent,
  IconButton,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { addGlobalErrorMessage } from 'redux/actions';
import { useSelector } from 'redux/reducers';
import { InputData } from '_reconciliation/types';
import { parseFormat } from '@agoy/dates';
import { printDocument } from '_shared/components/PrintedDocument/utils';

import LoadingLogo from '_shared/components/LoadingLogo';
import PeriodDataContext from '../PeriodDataContext';
import SpecificationPrint from './SpecificationPrint';
import { usePrintState } from '../../utils';

const Container = styled.section`
  @media print {
    page-break-inside: avoid;
  }
`;

const Dialog = styled(MuiDialog)`
  @media print {
    .MuiDialog-paper {
      margin: 0;
      border: none;
      border-radius: 0;
    }
  }
`;

const TopBarActions = styled.div`
  display: flex;
  justify-content: space-between;
  @media print {
    display: none;
  }
`;

const PrintButton = styled(IconButton)`
  @media print {
    display: none;
  }
  background-color: white;
`;

const CloseButton = styled(IconButton)`
  @media print {
    display: none;
  }
  background-color: white;
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

type SpecificationPrintModalProps = {
  specification: Client.LegacySpecification[];
  account: string;
  accountName: string;
  open: boolean;
  userData: InputData;
  handleShow: (value: boolean) => void;
};

const SpecificationPrintModal = ({
  specification,
  account,
  accountName,
  open,
  userData,
  handleShow,
}: SpecificationPrintModalProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const printState = usePrintState();
  const dispatch = useDispatch();

  const { clientId, financialYear, period } = useContext(PeriodDataContext);

  const { name } = useSelector((state) => state.customers[clientId]);

  const [isPrintLoading, setIsPrintLoading] = useState(false);

  const handlePrint = async () => {
    setIsPrintLoading(true);

    const finYearInterval = `${parseFormat(
      financialYear.start,
      'yyyyMM'
    )}-${parseFormat(financialYear.end, 'yyyyMM')}`;

    try {
      await printDocument(
        clientId,
        finYearInterval,
        'accounting',
        `${formatMessage({
          id: 'hidden.specification.view',
        })} ${name} ${period.end}.pdf`,
        ['specificationTable'],
        {
          ...printState,
          reconciliationSpecificationsData: {
            specification,
            account,
            accountName,
            yearIB: userData.yearIB,
            userData,
          },
        }
      );
    } catch {
      dispatch(addGlobalErrorMessage('error'));
    } finally {
      setIsPrintLoading(false);
    }
  };

  return (
    <Container>
      <Dialog open={open} onClose={() => handleShow(false)} maxWidth="lg">
        <DialogContent>
          <TopBarActions>
            <LoadingContainer>
              <PrintButton
                aria-label="print"
                disabled={isPrintLoading}
                onClick={handlePrint}
              >
                {isPrintLoading ? <LoadingLogo size="tiny" /> : <PrintIcon />}
              </PrintButton>
            </LoadingContainer>
            <CloseButton aria-label="close" onClick={() => handleShow(false)}>
              <CloseIcon />
            </CloseButton>
          </TopBarActions>
          <SpecificationPrint
            userData={userData}
            accountName={accountName}
            account={account}
            specification={specification}
          />
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default SpecificationPrintModal;
