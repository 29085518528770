import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';
import { Box } from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import AdapterDateFns from '@date-io/date-fns';
import { Table } from '_shared/components/CommonTable';
import {
  AgoyTableColumn,
  AgoyTableRow,
  Cell,
  Cell as CellType,
  stringValue,
} from '@agoy/document';
import AccountSelector from '_shared/components/AccountSelector';
import { clearAccountListDrawerSelection } from '_shared/redux/account-list-drawer/actions';
import {
  updateTaxTableRowLabel,
  updateTaxTableRowValue,
} from '_tax/redux/tax-view/actions';
import { useDispatch } from 'react-redux';
import { StyledTableCell } from '_shared/components/CommonTable/TableRow';
import Typography from '_shared/components/Typography/Typography';
import { checkDuplicatedAccrualFundAccount } from 'utils/TaxCalculation/utils';
import { addGlobalErrorMessage } from 'redux/actions';
import TaxesDataContext from '../../../service/TaxesDataContext';

const Dates = styled.div`
  display: flex;
  align-items: baseline;
  gap: ${({ theme }) => theme.spacing(1)}px;
`;

const DATE_DELIMITER = '–';

type DateCellProps = {
  id: string;
  start: string;
  end: string;
  handleDateChange: (id: string, start: string, end: string) => void;
};

const DateCell = ({
  id,
  start,
  end,
  handleDateChange,
}: DateCellProps): JSX.Element => {
  const [startDate, setStartDate] = useState(start);
  const [endDate, setEndDate] = useState(end);
  const [timer, setTimer] = useState(setTimeout(() => {}, 0));

  const handleChange = (date: string | null | undefined, handler) => {
    clearTimeout(timer);
    handler(date);
  };

  useEffect(() => {
    if (startDate !== start || endDate !== end) {
      setTimer(
        setTimeout(() => {
          handleDateChange(`accrualFund-${id}`, startDate, endDate);
        }, 1000)
      );
    }
  }, [end, endDate, handleDateChange, id, start, startDate]);

  return (
    <MuiPickersUtilsProvider utils={AdapterDateFns}>
      <Dates>
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          margin="dense"
          format="yyyy-MM-dd"
          autoOk
          inputVariant="outlined"
          value={null}
          inputValue={startDate}
          required
          onChange={(_, date) => handleChange(date, setStartDate)}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
        <span>{DATE_DELIMITER}</span>
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          margin="dense"
          format="yyyy-MM-dd"
          autoOk
          inputVariant="outlined"
          value={null}
          inputValue={endDate}
          required
          onChange={(_, date) => handleChange(date, setEndDate)}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </Dates>
    </MuiPickersUtilsProvider>
  );
};

const AccrualFundDeposition = ({
  print = false,
}: {
  print?: boolean;
}): JSX.Element | null => {
  const { clientId, taxYearData, financialYearObj, financialYear, period } =
    useContext(TaxesDataContext);

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const depositionOfAccrualFund =
    taxYearData.state?.document.accrualFunds.depositionOfAccrualFund;

  const selectAccount = useCallback(
    (selectedAccount: string | undefined, accountId: string) => {
      dispatch(
        updateTaxTableRowValue(
          financialYear,
          period,
          'accrualFundsAccounts',
          accountId,
          selectedAccount ? parseInt(selectedAccount, 10) : 0
        )
      );
      dispatch(clearAccountListDrawerSelection());
    },
    [dispatch, financialYear, period]
  );

  const updateRowDateRange = useCallback(
    (accountId: string, start: string, end: string) => {
      dispatch(
        updateTaxTableRowLabel(
          financialYear,
          period,
          'accrualFundsAccounts',
          `${accountId}.account`,
          `${start} ${DATE_DELIMITER} ${end}`
        )
      );

      dispatch(
        updateTaxTableRowLabel(
          financialYear,
          period,
          'accrualFunds',
          accountId,
          `${start} ${DATE_DELIMITER} ${end}`
        )
      );
      dispatch(clearAccountListDrawerSelection());
    },
    [dispatch, financialYear, period]
  );

  const handleAccountSelected = useCallback(
    (selectedAccount: string | undefined, name, accountId, close) => {
      if (
        depositionOfAccrualFund &&
        selectedAccount &&
        !checkDuplicatedAccrualFundAccount(
          selectedAccount,
          depositionOfAccrualFund.rows
        )
      ) {
        selectAccount(selectedAccount, accountId);
      } else if (!selectedAccount) {
        selectAccount(selectedAccount, accountId);
      } else {
        dispatch(addGlobalErrorMessage('hidden.accrualfunds.duplicates'));
      }

      if (close) {
        close();
      }
    },
    [depositionOfAccrualFund, selectAccount, dispatch]
  );

  const handleDateChange = useCallback(
    (id: string, startValue: string, endValue: string) => {
      if (startValue && endValue) {
        updateRowDateRange(id, startValue, endValue);
      }
    },
    [updateRowDateRange]
  );

  const renderDateCell = useCallback(
    (id: string, cell: Cell) => {
      const value = stringValue(cell) || '';
      const isDate = value.includes(DATE_DELIMITER);

      const [start, end] = `${value}`.split(` ${DATE_DELIMITER} `);

      return (
        <StyledTableCell className="left" key={id}>
          {!print && isDate ? (
            <DateCell
              id={id}
              start={start}
              end={end}
              handleDateChange={handleDateChange}
            />
          ) : (
            <Typography>{value}</Typography>
          )}
        </StyledTableCell>
      );
    },
    [handleDateChange, print]
  );

  const renderAccountCell = (id: string, cell: Cell): React.ReactElement => {
    if (cell.type === 'ref') {
      return (
        <StyledTableCell className="right" key={id}>
          <AccountSelector
            clientId={clientId}
            financialYear={financialYearObj}
            rowId={`accrualFund-${id}.account`}
            account={`${!cell.value ? '' : cell.value}`}
            value={typeof cell.value === 'object' ? 'Error' : cell.value}
            onAccountSelected={handleAccountSelected}
            variant={cell.value ? 'text' : 'outlined'}
          />
        </StyledTableCell>
      );
    }

    return <div> </div>;
  };

  const renderCell = (values: {
    cell: CellType;
    column: AgoyTableColumn;
    row: AgoyTableRow;
    baseId: string;
    isDragging: boolean;
  }) => {
    const { cell, column, row } = values;
    if (cell && column.id === 'accountNumber') {
      return renderAccountCell(row.id, cell);
    }
    if (cell && column.id === 'date') {
      return renderDateCell(row.id, cell);
    }
    return null;
  };

  return depositionOfAccrualFund ? (
    <Box id="deposition">
      <Typography variant="h2">
        {formatMessage({ id: 'hidden.deposition.accrualfund' })}
      </Typography>

      <Table
        baseId="financialReport"
        tableId="accrualFund"
        rows={depositionOfAccrualFund.rows}
        columns={depositionOfAccrualFund.columns}
        print={print}
        renderCell={renderCell}
      />
    </Box>
  ) : null;
};

export default AccrualFundDeposition;
