import React, { useState, ChangeEvent } from 'react';
import {
  SpecificationColumnType,
  SpecificationRowType,
} from '_clients/types/types';
import styled from '@emotion/styled';
import Typography from '_shared/components/Typography/Typography';
import Button from '_shared/components/Buttons/Button';
import { Input } from '_shared/components/Inputs/v2/Input';
import { useIntl } from 'react-intl';
import { debounce } from 'lodash';
import DraggableCell, { Td } from './DraggableCell';
import DraggableHeader from './DraggableHeader';

const StyledTable = styled.table`
  display: inline-block;
  width: 75vw;
  height: auto;
  max-height: 280px;
  overflow: scroll;
  border-spacing: 0;
  margin-bottom: 15px;
  border-radius: 10px;
  border: 1px solid #ccc;
`;

const StickyHeader = styled.thead`
  position: sticky;
  top: 0;
  background: #fff;
`;

const SelectHeadCell = styled.th`
  text-align: start;
  padding: ${(props) => props.theme.spacing(2)}px
    ${(props) => props.theme.spacing(2)}px;
  border-bottom: 1px solid #ccc;
  white-space: nowrap;
  min-width: 50px;
`;

const SelectRowCell = styled.td`
  text-align: start;
  padding: ${(props) => props.theme.spacing(2)}px
    ${(props) => props.theme.spacing(2)}px;
  border-bottom: 1px solid #ccc;
  white-space: nowrap;
  min-width: 50px;
`;

const BottomWrapper = styled.div`
  display: flex;
  gap: 15px;
`;

const StyledTd = styled.td`
  text-align: start;
  padding: ${(props) => props.theme.spacing(2)}px
    ${(props) => props.theme.spacing(2)}px;
  border-bottom: 1px solid #ccc;
  border-left: 1px solid #ccc;
  width: 200px;
`;

const ActionColumnCell = styled.th`
  padding: ${(props) => props.theme.spacing(2)}px
    ${(props) => props.theme.spacing(2)}px;
  border-bottom: 1px solid #ccc;
  border-left: 1px solid #ccc;
  min-width: 64px;
`;

const ActionCell = styled.td`
  padding: ${(props) => props.theme.spacing(2)}px
    ${(props) => props.theme.spacing(2)}px;
  border-bottom: 1px solid #ccc;
  border-left: 1px solid #ccc;
  min-width: 64px;
`;

type DraggableTableProps = {
  columns: SpecificationColumnType[];
  rows: SpecificationRowType[];
  onUpdateCell: (
    rowId: number,
    cellId: number,
    value: string,
    rowIndex: number
  ) => any;
  onMoveRow: (updatedRows: string[][]) => void;
  onMoveColumn: (updatedColumns: string[]) => void;
  onNewRow: () => void;
};

const DraggableTable = ({
  columns,
  rows,
  onUpdateCell,
  onMoveRow,
  onMoveColumn,
  onNewRow,
}: DraggableTableProps) => {
  const { formatMessage } = useIntl();
  const [selectedRows, setSelectedRows] = useState([]);

  const moveRow = (fromIndex: number, toIndex: number, columnIndex: number) => {
    const updatedRows = [...rows];
    const [movedRow] = updatedRows.splice(fromIndex, 1);
    updatedRows.splice(toIndex, 0, movedRow);
    // onMoveRow(updatedRows); // Notify parent of row change
  };

  const moveColumn = (fromColumnIndex: number, toColumnIndex: number) => {
    const updatedColumns = [...columns];
    const [movedColumn] = updatedColumns.splice(fromColumnIndex, 1);
    updatedColumns.splice(toColumnIndex, 0, movedColumn);
    onMoveColumn(['Column Change']); // Notify parent of column change
  };

  const handleNewRowChange = (
    e: ChangeEvent<HTMLInputElement>,
    columnIndex: number
  ) => {
    const { value } = e.target;
    console.log(value, columnIndex);
  };

  const handleAddNewRow = () => {
    onNewRow(); // Notify parent about the new row added
  };

  const handleCellUpdate = (e, rowId, cellId, rowIndex) => {
    e.preventDefault();
    onUpdateCell(e.target.value, rowId, cellId, rowIndex);
  };

  return (
    <>
      <StyledTable>
        <StickyHeader>
          <tr>
            <SelectHeadCell>
              <input type="checkbox" />
            </SelectHeadCell>
            {columns.map((column, columnIndex) => (
              <DraggableHeader
                key={columnIndex}
                columnIndex={columnIndex}
                moveColumn={moveColumn}
              >
                <Typography textStyle="bold" margin="none">
                  {column.name}
                </Typography>
              </DraggableHeader>
            ))}
            {/* Action column at the end */}
            <ActionColumnCell />
          </tr>
        </StickyHeader>
        <tbody>
          {rows.map((row, rowIndex) => (
            <tr key={row.id}>
              <SelectRowCell>
                <input type="checkbox" />
              </SelectRowCell>
              {columns.map((col, columnIndex) => (
                <DraggableCell
                  key={`${row.id}-${col.id}`}
                  rowIndex={rowIndex}
                  columnIndex={columnIndex}
                  moveRow={moveRow}
                  moveColumn={moveColumn}
                >
                  <Input
                    defaultValue={
                      row.cells.find((c) => c.columnId === col.id)?.value
                    }
                    onChange={debounce(
                      (e) => handleCellUpdate(e, row.id, col.id, rowIndex),
                      600
                    )}
                  />
                </DraggableCell>
              ))}
              {/* Action row cell at the end */}
              <ActionCell />
            </tr>
          ))}
          {/* Empty row at the bottom */}
          <tr>
            <SelectRowCell>
              <input type="checkbox" />
            </SelectRowCell>
            {columns.map((cell, columnIndex) => (
              <Td key={columnIndex}>
                <Input
                  defaultValue=""
                  onChange={(e) => handleNewRowChange(e, columnIndex)}
                  onClick={handleAddNewRow}
                />
              </Td>
            ))}
            <ActionCell />
          </tr>
          {/* Table footer */}
          <tr>
            <SelectRowCell />
            <StyledTd colSpan={columns.length}>
              <Typography textStyle="bold" margin="none">
                {formatMessage({
                  id: 'table.draggable.summery',
                })}
              </Typography>
            </StyledTd>
            <ActionCell />
          </tr>
        </tbody>
      </StyledTable>
      <BottomWrapper>
        <Typography>
          {selectedRows.length}{' '}
          {formatMessage({
            id: 'table.draggable.selected',
          })}
        </Typography>
        <Button
          size="small"
          variant="outlined"
          label={formatMessage({
            id: 'table.draggable.deleteRows',
          })}
          disabled
        />
      </BottomWrapper>
    </>
  );
};

export default DraggableTable;
