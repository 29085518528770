import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';

import { ReferencesTypes } from 'types/Voucher';
import TaxesDataContext from '_tax/service/TaxesDataContext';
import When from '_shared/components/When/When';
import PrintStateContext from '_shared/components/PrintedDocument/PrintStateContext';
import { useSelector } from 'redux/reducers';

import VoucherPrint from '../VoucherPrint';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 12px;
  row-gap: 24px;
`;

const Title = styled(Typography)`
  font-size: 19px;
  font-weight: 700;
`;

export const ADJUSTMENTS_VOUCHERS = [
  ReferencesTypes.PARTICULAR_SALARY_TAX,
  ReferencesTypes.PROPERTY_TAX,
  ReferencesTypes.PROPERTY_TAX_PART_2,
  ReferencesTypes.ACCRUAL_FUNDS,
  ReferencesTypes.INTANGIBLE_ASSETS,
  ReferencesTypes.BUILDINGS_AND_GROUND_FACILITIES,
  ReferencesTypes.MACHINES_AND_INVENTORY,
  ReferencesTypes.GROUP_CONTRIBUTION,
  ReferencesTypes.OTHER_ADJUSTMENTS,
  ReferencesTypes.YEAR_TAX,
  ReferencesTypes.YEAR_RESULT,
];

const AdjustmentsPrint = () => {
  const { formatMessage } = useIntl();

  const {
    overDeprecationSections = {},
    clientId,
    financialYear,
  } = useContext(TaxesDataContext);
  const {
    state: { adjustmentsData },
  } = useContext(PrintStateContext);

  const periods = useSelector(
    ({ accountingView }) =>
      accountingView.clients[clientId].years[financialYear]?.accountingBalances
        ?.periods
  );

  const startYearPeriods =
    periods?.filter((item) => item.start.slice(5, 10) === '01-01') || [];

  if (!adjustmentsData) {
    return null;
  }

  const {
    intangibleAssets,
    buildingsAndGroundFacilities,
    machinesAndInventory,
  } = overDeprecationSections;

  return (
    <Container>
      <Title>{formatMessage({ id: 'tax.adjustments.title' })}</Title>

      <VoucherPrint
        reference={ReferencesTypes.PARTICULAR_SALARY_TAX}
        title={formatMessage({ id: 'tax.overdepreciation.voucher.title' })}
      />
      {startYearPeriods.map((item, index) => (
        <VoucherPrint
          key={item.id}
          reference={
            index === 0
              ? ReferencesTypes.PROPERTY_TAX
              : ReferencesTypes.PROPERTY_TAX_PART_2
          }
          title={formatMessage({ id: 'tax.propertytax' })}
        />
      ))}
      <VoucherPrint
        reference={ReferencesTypes.ACCRUAL_FUNDS}
        title={formatMessage({ id: 'tax.accruals' })}
      />
      <When isTrue={!!intangibleAssets?.active}>
        <VoucherPrint
          reference={ReferencesTypes.INTANGIBLE_ASSETS}
          title={formatMessage({ id: 'tax.overdepreciation.voucher.title' })}
        />
      </When>
      <When isTrue={!!buildingsAndGroundFacilities?.active}>
        <VoucherPrint
          reference={ReferencesTypes.BUILDINGS_AND_GROUND_FACILITIES}
          title={formatMessage({ id: 'tax.overdepreciation.voucher.title' })}
        />
      </When>
      <When isTrue={!!machinesAndInventory?.active}>
        <VoucherPrint
          reference={ReferencesTypes.MACHINES_AND_INVENTORY}
          title={formatMessage({ id: 'tax.overdepreciation.voucher.title' })}
        />
      </When>
      <VoucherPrint
        reference={ReferencesTypes.GROUP_CONTRIBUTION}
        title={formatMessage({ id: 'tax.groupContributionVoucher.title' })}
      />
      <VoucherPrint
        reference={ReferencesTypes.OTHER_ADJUSTMENTS}
        title={formatMessage({ id: 'tax.otherAdjustmentsVoucher.title' })}
      />
      <VoucherPrint
        reference={ReferencesTypes.YEAR_TAX}
        title={formatMessage({ id: 'tax.transaction.taxToBook.label' })}
      />
      <VoucherPrint
        reference={ReferencesTypes.YEAR_RESULT}
        title={formatMessage({ id: 'tax.transaction.resultToBook.label' })}
      />
    </Container>
  );
};

export default AdjustmentsPrint;
