import React, { useContext } from 'react';
import VoucherForm from '_shared/components/VoucherView/CreateVoucherView';
import { ReferencesTypes } from 'types/Voucher';
import TaxesDataContext from '_tax/service/TaxesDataContext';
import PrintStateContext from '_shared/components/PrintedDocument/PrintStateContext';
import PageContent from './UI/PageContent';

type VoucherPrintProps = {
  reference: ReferencesTypes;
  title: string;
};

const VoucherPrint = ({ reference, title }: VoucherPrintProps) => {
  const { financialYearObj, clientId } = useContext(TaxesDataContext);
  const {
    state: { adjustmentsData },
  } = useContext(PrintStateContext);

  const voucherData = adjustmentsData?.[reference];

  if (!voucherData) {
    return null;
  }

  const {
    selectedSeries,
    selectedDate,
    voucherNumber,
    voucherDescription,
    status,
    transactions,
    documents,
  } = voucherData;

  return (
    <PageContent>
      <VoucherForm
        voucherStatus={status}
        clientId={clientId}
        financialYear={financialYearObj}
        titleText={title}
        documents={documents}
        transactions={transactions}
        description={voucherDescription}
        series={selectedSeries}
        date={selectedDate}
        source={ReferencesTypes.PARTICULAR_SALARY_TAX}
        voucherNumber={voucherNumber}
        accountsList={[]}
        seriesList={[]}
        print
      />
    </PageContent>
  );
};

export default VoucherPrint;
